<script lang="ts">
    import DuCountryDialog from './DuCountryDialog.svelte';
    import DuInputInterface from './DuInputInterface.svelte';
    import IconsWorker from '../IconsWorker.svelte';
    import { onMount } from 'svelte';

    export let label:string = '';
    export let value:string = '';
    export let placeHolder:string = '';
    export let isValid:boolean = null;
    export let errorMessage:string = '';
    export let showErrorMessage:boolean = false;

    export let isRequired:boolean = false;
    export let isRequiredMessage:string = 'required';
    export let typeErrorMessage:string = 'invalid phone number';
    export let minErrorMessage:string = 'invalid phone number';

    export let countryCode:string = 'sa';
    export let phoneCode:string = '';
    export let magnifier:number = 0;

    let lang = 'ar';
    let isDialogOpen:boolean = false;
    let isLoading:boolean = true;
    let isFocusedOut:boolean = false;

    const openSelectCountryDialog = () => {
        isDialogOpen = true;
    }

    const onKeyUp = () => {
        validate();
    }

    const onFocusOut = () => {

    }

    export const validate = () => {
        let _isValid = true;

        if(isRequired) {
            if(value.length < 1) {
                _isValid = false;
                errorMessage = isRequiredMessage;
            }
        }

        if(_isValid && value.length > 0) {
            if (!/^\d+$/.test(value)) {
                _isValid = false;
                errorMessage = typeErrorMessage;
            }
        }

        if(_isValid && value.length < 5) {
            _isValid = false;
            errorMessage = minErrorMessage;
        }

        isValid = _isValid;

        if (!isValid) {
            showErrorMessage = true;
        } else {
            showErrorMessage = false;
            errorMessage = '';
        }
    }


</script>


<DuInputInterface errorMessage={showErrorMessage ? errorMessage : ''} {...$$props}>
    <div class="phoneAndCode" slot="input">
        <input class="phoneInput" bind:value placeholder="{placeHolder}" type="tel" on:keyup={onKeyUp}/>
        <div class="phoneCode">{phoneCode}</div>
        <img class="countryIcon" src="/assets/countrysFlags/{countryCode.toLowerCase()}.png" on:click={openSelectCountryDialog}/>
        <div class="rowDown" on:click={openSelectCountryDialog}>
            <IconsWorker icon="arrowDownF" color="#555" width="17px"/>
        </div>
    </div>
</DuInputInterface>

<DuCountryDialog bind:isDialogOpen bind:isLoading bind:countryCode bind:phoneCode/>


<style>
    .phoneAndCode{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }
    .phoneAndCode .phoneInput{
        flex: 1;
        width: inherit;
        background: transparent;
        border: 0;
        place-self: center;
        padding: 0;
        margin: 0;
        font-size: 15px;
    }
    .phoneAndCode .phoneInput:focus {
        outline: none ! important;
    }
    .phoneAndCode .countryIcon{
        height: 22px;
        margin: 0 10px;
    }
    .phoneAndCode .rowDown{
        width: 17px;
    }
    .phoneAndCode .phoneCode{
        margin-inline-end: 10px;
        direction: ltr;
        font-size: 15px;
        color: #666;
    }
</style>