<script lang="ts">

import IconsWorker from "../Components/IconsWorker.svelte";

const onOptionMenuClick = () => {

}
</script>

<div class="dashboardUserFragment">
    <div class="userThumb"></div>
    <div class="infoSide">

    </div>
    <div class="optionButton brightnessGlobalHover" on:click={onOptionMenuClick}>
        <IconsWorker icon="yDotMenuF" width="20px" color="#fff"/>
    </div>
</div>

<style>
    .dashboardUserFragment{
        width: available;
        display: flex;
        flex-direction: row;
        margin-bottom: 2px;
        background-color: #fff;
        box-shadow: 0 0 5px  #ccc;
        overflow: hidden;
        padding: 15px;
    }
    .dashboardUserFragment:first-child{
        border-radius: 10px 10px 0 0;
    }
    .dashboardUserFragment:last-child{
        border-radius: 0 0 10px 10px;
    }
    .dashboardUserFragment:only-child{
        border-radius: 10px;
    }

    .userThumb{
        width: 60px;
        height: 60px;
        border-radius: 100px;
        background: #eee;
    }

    .infoSide{
        margin-inline-start: 10px;
        display: flex;
        flex-direction: column;
    }

    .optionButton{
        width: 35px;
        height: 35px;
        background: #555;
        margin-inline-end: 15px;
        place-self: center;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        border-radius: 100px;
        box-shadow: 0 0 5px #ccc;
    }
</style>