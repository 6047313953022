<script lang="ts">
import MainHeader from "../Components/MainHeader.svelte";
import DuInput, {DuInputType} from '../Components/DuInput/DuInput.svelte';
import StepperProgressLine, {StepperProgressOption} from "../Components/StepperProgressLine.svelte";
import {onMount} from "svelte";
import ConnectionWorker from "../Utils/ConnectionWorker";
import IconsWorker from "../Components/IconsWorker.svelte";
import DuButton from "../Components/DuButton.svelte";
import DuFilesUploader, {DuFileUploaderFile} from "../Components/DuPhotosUploader/DuFilesUploader.svelte";
import DuInputNumber from "../Components/DuInput/DuInputNumber.svelte";
import DuMapLocationPicker from "../Components/DuMapLocationPicker.svelte";

let focusedInput:string = '';
let step:number = 0;
let isLoadingTags:boolean = true;
let isFailLoadingTags:boolean = false;
let tagsData;

let tagValue:number;
let descriptionValue = '';
let priceValue = 3;
let titleValue = '';
let imagesArray = [];
let imagesAsString = '';
let isMapReady:boolean = false;
let locationVal = {
    longitude: 46.6450527,
    latitude: 24.8190973,
};

let isDescriptionValid = false;
let isPriceValid = false;
let isTitleValid = false;

let isSendingData:boolean = false;

onMount(() => {
    getTags();
});

const getTags = () => {
    isLoadingTags = true;
    new ConnectionWorker().request(<any>{
        url: '/hashtag/get',
        onLoad: (response) => {
            isLoadingTags = false;
            if(response.status) {
                tagsData = response.data.data;
            } else {
                // todo: show error notification if there is any error
            }
        },
        onFail: (code) => {
            isLoadingTags = false;
            // todo: add notification when there is no internet
            isFailLoadingTags = true;
        }
    });
}

const selectTag = (tag) => {
    tagValue = tag.id;
    step = 1;
}

$: isAllowedToMoveNext = () => {
    if(step === 0) return true;
    if(step === 1) return isTitleValid && isDescriptionValid;
    if(step === 2) return imagesArray.length > 0;
    if(step === 3) return isMapReady;
}

const isImagesValid = () => {

    if(imagesArray.length === 0)
        return false;

    let _isAllImagesUploaded = true;
    imagesArray.forEach((image) => {
        if(image.serial !== undefined && _isAllImagesUploaded) {
            _isAllImagesUploaded = true;
        } else {
            _isAllImagesUploaded = false;
        }
    });

    return _isAllImagesUploaded;
}


const goNext = () => {
    if(step === 1 && isAllowedToMoveNext()) {
        step++;
    } if(step === 2 && isAllowedToMoveNext()) {
       step++;
    } if(step === 3 && isAllowedToMoveNext()) {
        sendData();
    }
}

const sendData = () => {
    isSendingData = true;
    imagesAsString = '';

    if(isDescriptionValid && isTitleValid && isImagesValid()) {
        imagesArray.forEach((file) => {
            imagesAsString = imagesAsString + ',' + file.serial;
        });
        imagesAsString = imagesAsString.replace(/(.+),$/, '$1');
        const params = {
            'tag': tagValue,
            'title': titleValue,
            'description': descriptionValue,
            'price': priceValue,
            'images': imagesAsString,
            'longitude': locationVal.longitude,
            'latitude': locationVal.latitude,
        };

        console.log(imagesAsString);
        console.log(JSON.stringify(params));

        new ConnectionWorker().request(<any>{
            url: '/services/add',
            postParams: params,
            onLoad: (response) => {
                console.log(response);
            },
            onFail: (code) => {
                // todo: show error message
            }
        });
    }
}

</script>

<MainHeader />
<div class="mainPage mainContainer">
    <div class="addNewService">

        <div class="stepper">
            <StepperProgressLine options={[
                {label: 'التصنيف', icon: 'hamburgerMenu'},
                {label: 'البيانات', icon: 'info'},
                {label: 'الصور', icon: 'camera'},
                {label: 'الموقع', icon: 'location'},
            ]} step={step} background="#000"/>
        </div>

        <div class="pageTitle"></div>

        <div class="inputsHolderAndTips">
            <div class="tipsArea" style="visibility: {step > 0 && focusedInput === '' ? 'hidden': 'visible'}">
                {#if step === 0}

                {:else}
                    {#if focusedInput === 'title'}
                        يظهر العنوان الخاص بالخدمة للمشتري لذا قم بإختيار عنوان واضح
                        , مثال : ١٠٠ كرسي بلاستيكي
                        تجنب كتابة السعر في العنوان واستعمل خانة السعر لكتابة التكلفة
                    {:else if focusedInput === 'description'}
                        بإمكانك كتابة المزيد من التفاصيل عن الخدمة هنا كذكر المميزات و العيوب و شروط الإستأجار ... إلخ
                    {/if}
                {/if}
            </div>
            <div class="inputsHolder">
                {#if step === 0}
                    {#if isLoadingTags}

                    {:else}
                        <div class="tagsStepTitle">قم بإختيار القسم المناسب لخدمتك</div>
                        <div class="tags">
                            {#each tagsData as tag}
                                <div class="cell" on:click={(e) => selectTag(tag)}>
                                    <IconsWorker icon="arrowLeftF" width="15px" color="#777"/>
                                    <div class="title">{tag.title}</div>
                                </div>
                            {/each}
                        </div>
                    {/if}
                {:else if step === 1}
                    <DuInput
                            placeHolder="عنوان الخدمة"
                            label="مثال : ١٠٠ كرسي"
                            isRequired={true}
                            isRequiredMessage="يبدو أنك قد نسيت إدخال عنوان الخدمة"
                            magnifier={3}
                            backgroundColor="#fff"
                            bind:value={titleValue}
                            bind:isValid={isTitleValid}
                            on:focus={(x) => {
                            focusedInput = 'title';
                        }}
                            on:focusout={(x) => {
                            focusedInput = '';
                        }}
                    />
                    <div class="splitter"></div>
                    <DuInput
                            inputType={DuInputType.textArea}
                            placeHolder="مثال : يتوفر لونين"
                            label="وصف الخدمة"
                            isRequired={true}
                            isRequiredMessage="قم بكتابة الوصف"
                            magnifier={3}
                            bind:isValid={isDescriptionValid}
                            backgroundColor="#fff"
                            bind:value={descriptionValue}
                            on:focus={(x) => {
                            focusedInput = 'description';
                        }}
                            on:focusout={(x) => {
                            focusedInput = '';
                        }}
                    />
                    <div class="splitter"></div>
                    <DuInputNumber
                            inputType={DuInputType.number}
                            placeHolder="مثال : يتوفر لونين"
                            label="وصف الخدمة"
                            isRequired={true}
                            isRequiredMessage="قم بكتابة الوصف"
                            magnifier={3}
                            bind:isValid={isPriceValid}
                            backgroundColor="#fff"
                            bind:value={priceValue}
                            stepFactory="{2}"
                            minValue="{1}"
                            minValueMessage="يجب أن السعر المبلغ أعلى من ١ ريال"
                            maxValue="{10}"
                            maxValueMessage="أعلى سعر للخدمة ١٠٠٠ ريال لليوم"
                            on:focus={(x) => {
                            focusedInput = 'price';
                        }}
                            on:focusout={(x) => {
                            focusedInput = '';
                        }}
                    />
                {:else if step === 2}
                    <DuFilesUploader
                        emptyPlaceHolderText="أنقر هنا لإضافة صور"
                        emptyPlaceHolderIcon="camera"
                        minCount={1}
                        maxCount={10}
                        bind:files="{imagesArray}"
                    />
                {:else if step === 3}
                    <div class="locationPicker">
                        <DuMapLocationPicker
                            bind:location={locationVal}
                            onMapReady="{() => {
                                isMapReady = true;
                            }}"
                        />
                    </div>
                {/if}

                {#if [1, 2, 3].includes(step)}
                    <div class="buttonsArea">
                        {#if step !== 0}
                            <DuButton text="السابق" fontSize="20"  onTap="{() => {step--;}}" background="#333"/>
                        {/if}
                        <div class="emptyExpand"></div>
                        <DuButton text="التالي" isDisabled={!isAllowedToMoveNext()} fontSize="20"  onTap="{() => {goNext();}}"/>
                    </div>
                {/if}
            </div>
        </div>


    </div>
</div>

<style>
.addNewService{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 50%;
    place-self: center;
}

.stepper{
    width: 100%;
    place-self: center;
    margin-bottom: 20px;
}

.inputsHolderAndTips{
    width: available;
    display: flex;
    flex-direction: row;
}

.inputsHolder{
    width: available;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.tipsArea{
    width: 200px;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    position: absolute;
    left: 13%;
}

.splitter{
    width: 20px;
    height: 20px;
}

.tagsStepTitle{
    font-size: 19px;
    font-weight: bold;
    color: #888;
    margin-bottom: 20px;
}

.tags{
    width: 100%;
    display: flex;
    flex-direction: column;
    place-self: center;
    align-self: center;
    border-radius: 12px;
    overflow: hidden;
}

.tags .cell{
    width: available;
    display: flex;
    flex-direction: row;
    background: #fff;
    padding: 20px;
    margin-bottom: 1px;
    align-items: center;
    color: #555;
    cursor: pointer;
}

.tags .cell:hover{
    background: #F09035;
    color: #fff !important;
}

.tags .cell:active{
    opacity: 0.8;
}

.tags .cell .title{
    margin-inline-start: 5px;
    flex: 1;
    font-size: 17px;
    font-weight: bold;
}

.buttonsArea{
    width: available;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #999;
}

.buttonsArea .emptyExpand{
    margin-top: 10px;
    width: available;
    flex: 1;
}

.locationPicker{
    width: 100%;
    height: 350px;
}
</style>