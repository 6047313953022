import DuConfirmDialogComponent from "./DuConfirmDialogComponent.svelte";
export default class DuConfirmDialog {
    constructor() {
        this.create = (params) => {
            new DuConfirmDialogComponent({
                target: document.body,
                props: {
                    options: params,
                },
            });
        };
    }
}
/*
 example:

        new DuConfirmDialog().create({
            title: 'هل تريد حذف هذا الإعلان ؟',
            subTitle: 'هل تريد حذف هذا الإعلان ؟',
            options: [
                { title: 'نعم', onSelect: () => { alert('yah'); }, color: 'red', isBold: true },
                { title: 'تراجع', onSelect: () => { alert('no'); }},
                { title: 'إغلاق', onSelect: () => { alert('no'); }},
            ],
        });

* */ 
