<script lang='ts'>
import { onMount } from "svelte";
import IconsWorker from "../Components/IconsWorker.svelte";
import ConnectionWorker from "../Utils/ConnectionWorker";
import DuButton from "../Components/DuButton.svelte";
import DuOptionMenu, {DuOptionMenuItem} from "../Components/DuOptionsMenu/DuOptionsMenu";
import GoogleMapsViewer from "../Components/GoogleMapsViewer.svelte";
import MyAccountStore from "../stores/MyAccountStore";
import DuConfirmDialog from "../Components/DuConfirmDialog/DuConfirmDialog";
import viewport from "../Utils/useViewportAction";
import {GlobalEventor} from "../Utils/GlobalEventor";


    export let data;
    export let isNeedToSend:boolean = false;
    export let file:File;
    let filePreview;


    const myAccountState = MyAccountStore.state;
    let isWindowHasFocus = true;
    let messageElement;



    const getFistImage = () => {
        return ConnectionWorker.rootUrl + '/file/' + data['order_info']['item_info'].images.split(',')[0];
    }

    $: getTime = () => {
        const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const date =  new Date(Date.parse(data['created_at']));

        let diff = Math.floor(new Date().getTime() - date.getTime());

        let day = 1000 * 60 * 60 * 24;

        let days = Math.floor(diff/day);
        let months = Math.floor(days/31);
        let years = Math.floor(months/12);

        let dateAsString = date.getHours() + ":" + date.getMinutes();


        if(days > 0) {
            dateAsString = `${dateAsString} ${date.getDate()} ${month_names_short[date.getMonth() + 1]}`;
        }
        return dateAsString;
    }

    const openMap = (platform:string) => {
        let url = '';
        if(platform === 'google') {
            url = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&destination='+ data['order_info']['item_info']['latitude'] +',' + data['order_info']['item_info']['longitude'];
        } else {
            url = 'http://maps.apple.com/?saddr=&daddr='+ data['order_info']['item_info']['latitude'] + ',' + data['order_info']['item_info']['longitude'];
        }
        window.open(url, '_blank').focus();
    }


    const showLocationOnMap = (event) => {
        new DuOptionMenu().create({
            event: event,
            options: [
                {title: ' فتح في خرائط قوقل', icon: 'googleMaps', onSelect: () => { openMap('google') } },
                {title: 'فتح في خرائط أبل', icon: 'appleMaps', onSelect: () => { openMap('apple') } },
            ]
        });
    }

    const dismissOrder = () => {
        new DuConfirmDialog().create({
            title: 'هل تريد إلغاء هذا الطلب ؟',
            subTitle: 'لن تستطيع إكمال المحادثة في حال إلغاء هذا الطلب',
            titleColor: 'red',
            options: [
                {title: 'إلفاء', color: 'red', onSelect: () => { sendCancelRequest() }},
                {title: 'تراجع', onSelect: () => {}},
            ],
        });
    }

    const sendCancelRequest = () => {
        new ConnectionWorker().request(<any>{
            url: '/order/cancel',
            postParams: {
                id: data['order_info']['id'],
            },
            onLoad: (response) => {
                console.log(response);
                if(response['status']) {
                    data['order_info']['status'] = 'canceled';
                } else {
                    //todo: handle this
                }
            },
            onFail: (code) => {
                //todo: handle this
            }
        });
    }

    const acceptOrder = () => {
        new ConnectionWorker().request(<any>{
            url: '/order/accept',
            postParams: {
                id: data['order_info']['id'],
            },
            onLoad: (response) => {
                console.log(response);
                if(response['status']) {
                    data['order_info']['status'] = 'accepted';
                } else {
                    //todo: handle this
                }
            },
            onFail: (code) => {
                //todo: handle this
            }
        });
    }

    $:getReturnDate = () => {
        const _date = new Date(data['order_info']['start_date']+ 'Z');
        return _date.getHours() + ':' + _date.getMinutes().toString().padStart(2, '0') + ' ' + _date.getDate() + '/' + (_date.getMonth() + 1) + '/' + _date.getFullYear();
    }

    let isSending = false;
    let isSend = false;

    const sendMessage = () => {
        if(!isWindowHasFocus) {
            setTimeout(() => sendMessage(), 3000);
            return;
        }
        isSending = true;
        new ConnectionWorker().request(<any>{
            url: '/messages/send',
            postParams: {
                interfaceId: data['interface'],
                type: data['type'],
                text: data['text'],
            },
            onLoad: (response) => {
                if(response['status']) {
                    data = response['data'];
                    isSending = false;
                } else {
                    setTimeout(() => {
                        sendMessage();
                    }, 3000);
                }
            },
            onFail: (code) => {
                setTimeout(() => {
                    sendMessage();
                }, 3000);
            }
        });
    }


    const sendSeenReport = () => {
        if((data['from'] !== $myAccountState.data.id) && data.is_read === 0) {
            new ConnectionWorker().request(<any>{
                url: '/messages/markAsSeen',
                postParams: {
                    interfaceId: data['interface'],
                    messageId: data['id'],
                },
                onLoad: (response) => {
                    console.log(response);
                    if (response['status']) {
                        data.is_read = true;
                    } else {
                        setTimeout(() => {
                            sendSeenReport();
                        }, 3000);
                    }
                },
                onFail: (code) => {
                    setTimeout(() => {
                        sendSeenReport();
                    }, 3000);
                }
            });
        }
    }


    const startUploadFile = () => {
        isSending = true;
        new ConnectionWorker().request(<any>{
            url: '/upload',
            filesParams: {
                'file': file,
            },
            onLoad: (response) => {
                if(response['status']) {
                    data.text = response['serial'];
                    sendMessage();
                } else {
                    // todo: handle this noticiation will not be enough
                }
            },
            onFail: (code) => {
                setTimeout(() => {
                    startUploadFile();
                }, 3000);
            }
        });
    }

    onMount(() => {
        if(isNeedToSend) {

            if(data.type === 1) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    filePreview = e.target.result;
                    startUploadFile();
                    setTimeout(() => {
                        messageElement.scrollIntoView({ behavior: 'smooth'});
                    }, 100);
                };
            } else {
                sendMessage();
                messageElement.scrollIntoView({ behavior: 'smooth'});
            }


        }

        GlobalEventor.listen((message) => {
            if(message.type === 'messageSeen' && message.data.id.toString() === data['id'].toString()) {
                data['is_read'] = 1;
            }
        });

        document.onfocus = function(){
            isWindowHasFocus = true;
        }

        document.onblur = function(){
            isWindowHasFocus = false;
        }

    });

    const enterViewport = () => {
        sendSeenReport();
    }
</script>

<div bind:this={messageElement} class="message { data['from'] === $myAccountState.data.id ? 'myMessageChangeAlign' : '' }" use:viewport on:enterViewport={enterViewport} id="message_fragment_{data.id}">
{#if data}
    <div class="messageTime">
        <IconsWorker icon="time" width="13px" color="#000"/>
        <span>{getTime()}</span>
    </div>
{/if}

{#if Number(data['type']) === 0}
    <div class="messageCell" style="background: rgba(83, 131, 236, 0.2)">
        <div class="textMessage">{data.text}</div>
    </div>
{:else if data['type'] === 1}
    <div class="messageCell" style="background: rgba(83, 131, 236, 0.2)">
        {#if filePreview}
            <img class="imageMessage" src="{filePreview}" alt="" />
        {:else if (data.text !== '')}
            <img class="imageMessage" src="{ConnectionWorker.rootUrl + '/file/' + data.text}" alt="" />
        {/if}
        <div class="textMessage"></div>
    </div>
{:else if data['type'] === 4}
    <div class="messageCell">
        <div class="order">
            <div class="orderImage" style="background-image: url({getFistImage()})"></div>
            <div class="orderInfoSide">
                <div class="orderLabel">تم الدفع</div>
                <div class="orderTitle">{data['order_info']['item_info']['title']}</div>
                <div class="orderDetailCell">
                    <IconsWorker icon="truck" width="15px" />
                    <span class="key">رقم الطلب:</span>
                    <span class="value">{data['order_info']['id']}</span>
                </div>
                <div class="orderDetailCell">
                    <IconsWorker icon="today" width="15px"/>
                    <span class="key"> عدد أيام الحجز:</span>
                    <span class="value">{data['order_info']['total_price']} ر.س </span>
                </div>
                <div class="orderDetailCell">
                    <IconsWorker icon="wallet2" width="15px"/>
                    <span class="key"> المدفوع الإجمالي مع الضريبة:</span>
                    <span class="value">{data['order_info']['total_price']} ر.س </span>
                </div>
                <div class="orderDetailCell">
                    <IconsWorker icon="location" width="15px"/>
                    <span class="key">موقع الاستلام:</span>
                    <span class="value"></span>
                </div>
                <div class="itemMapGeoLocation">
                    <GoogleMapsViewer latitude="{data['order_info']['item_info']['latitude']}" longitude="{data['order_info']['item_info']['longitude']}" zoomLevel="{12}"/>
                </div>
            </div>
        </div>
    </div>
    <div class="messageOptionsButtons">
        {#if data['from'] === $myAccountState.data.id}
            <div class="button" on:click="{ showLocationOnMap }">
                <DuButton text="عرض موقع الاستلام في الخرائط" icon="mapF" background="#65C75D" />
            </div>
            <div class="button">
                <DuButton text="تحميل الفاتورة" icon="mapF" background="#ccc" textColor="#555"/>
            </div>
            {#if data['order_info']['status'] === 'init'}
                <div class="button" on:click="{ dismissOrder }">
                    <DuButton text="إلغاء الطلب" icon="closeF" background="#FC5A70" />
                </div>
            {/if}
        {:else}
            {#if data['order_info']['status'] === 'init'}
                <div class="button" on:click="{ acceptOrder }">
                    <DuButton text="قبول الطلب" icon="checkF" background="#65C75D" />
                </div>
                <div class="button" on:click="{ dismissOrder }">
                    <DuButton text="رفض" icon="closeF" background="#FC5A70" />
                </div>
            {/if}
        {/if}
    </div>

{:else if data['type'] === 7}
    <div class="messageCell" style="background: rgba(66, 124, 229, 0.2)">
        <div class="order">
            <div class="orderLabel" style="border-color: #5383EC;">طلب مقبول</div>
        </div>
        <div class="canceledOrderLabel" style="color: #5383EC;">
            {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                تم قبول هذا الطلب بواسطتك
            {:else}
                تم قبول هذا الطلب بواسطة المؤجر
            {/if}
        </div>
        <div class="canceledOrderReason">
            <div class="reasonHeader"><IconsWorker icon="truck" width="15px" /><span>
                {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                    سيتوجه المستأجر إليك للإستلام
                {:else}
                    تستطيع استلام طلبك الأن
                {/if}
            </span></div>
            <span class="reasonBody">
            {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                قم بتسليم المستأجر عندما يصل إليك
            {:else}
                توجه لموقع التسليم
            {/if}
            </span>
        </div>

        {#if data['order_info']['item_info']['user_id'] !== $myAccountState.data.id}
            <div class="itemMapGeoLocation">
                <GoogleMapsViewer latitude="{data['order_info']['item_info']['latitude']}" longitude="{data['order_info']['item_info']['longitude']}" zoomLevel="{12}" id="mapView2"/>
            </div>
        {/if}
    </div>
    {#if data['order_info']['item_info']['user_id'] !== $myAccountState.data.id}
        <div class="messageOptionsButtons">
            <div class="button" on:click="{ showLocationOnMap }">
                <DuButton text="عرض موقع الاستلام في الخرائط" icon="mapF" background="#65C75D" />
            </div>
        </div>
    {/if}

{:else if data['type'] === 5 || data['type'] === 6}
    <div class="messageCell" style="background: rgba(233, 101, 116, 0.2)">
        <div class="order">
            <div class="orderLabel" style="border-color: red;">
                {#if data['type'] === 5}
                    تم رفض الطلب
                {:else}
                    تم إلغاء الطلب
                {/if}
            </div>
        </div>
        <div class="canceledOrderLabel" style="color: red;">
            {#if data['type'] === 5}
                {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                    تم رفض هذا الطلب بواسطتك
                {:else}
                    تم رفض الطلب بواسطة المؤجر
                {/if}
            {:else}
                {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                    تم إلغاء هذا الطلب بواسطتك
                {:else}
                    تم إلغاء الطلب بواسطة المؤجر
                {/if}
            {/if}
        </div>
        <div class="canceledOrderReason">
            <div class="reasonHeader"><IconsWorker icon="info" width="15px" /><span>
                {#if data['type'] === 5}
                    سبب الرفض:
                {:else}
                    سبب الإلغاء:
                {/if}
            </span></div>
            <span class="reasonBody">fkdlfkdkfl</span>
        </div>
        {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}

        {/if}
    </div>

{:else if data['type'] === 8}
    <div class="messageCell" style="background: rgba(83, 131, 236, 0.2)">
        <div class="order">
            <div class="orderLabel" style="border-color: blue;">تم إضافة توثيق</div>
        </div>
        <div class="canceledOrderLabel" style="color: #555">
            {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                تم توثيق حالة الطلب بواسطة المستأجر
                , قم بالنقر على زر بدأ الحجز إذا كانت الصور صحيحة لبدأ الحجز
            {:else}
                تم توثيق حالة الطلب بواسطتك
                , أطلب من المؤجر النقر على زر البدأ لبدأ الطلب
            {/if}
        </div>
        <div class="messageImagesGrid">
            {#each data['text'].split(',') as image}
                <div class="cell" style="background-image: url({ConnectionWorker.rootUrl + '/file/' + image})"></div>
            {/each}
        </div>
    </div>
    <div class="messageOptionsButtons">
        <div class="button" on:click="{ showLocationOnMap }">
            <DuButton text="عرض موقع الاستلام في الخرائط" icon="mapF" background="#65C75D" />
        </div>
    </div>

{:else if data['type'] === 9}
    <div class="messageCell" style="background: rgba(126, 196, 106, 0.2)">
        <div class="order">
            <div class="orderLabel" style="border-color: green;">تم استلام الطلب</div>
        </div>
        <div class="canceledOrderLabel" style="color: #555">
            {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                تم استلام الطلب بواسطة المستأجر
            {:else}
                تم استلام الطلب بواسطتك
            {/if}
        </div>
        <div class="canceledOrderReason">
            <div class="reasonHeader"><IconsWorker icon="info" width="15px" /><span>تاريخ الإرجاع المتوقع:</span></div>
            <span class="reasonBody">{ getReturnDate() }</span>
        </div>
    </div>

{:else if data['type'] === 11}

    <div class="messageCell" style="background: rgba(83, 131, 236, 0.2)">
        <div class="order">
            <div class="orderLabel" style="border-color: blue;">تم إكمال الطلب</div>
        </div>
        <div class="canceledOrderLabel" style="color: #555">
            تم اكمال الطلب و استرجاع الغرض
        </div>
        <div class="canceledOrderReason">
            <div class="reasonHeader"><IconsWorker icon="info" width="15px" /><span></span></div>
            <span class="reasonBody">
                {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                    تم إيداع
                    <b>١٢ ر.س</b>
                    في رصيدك
                {:else}
                    تستطيع إضافة تقييم للمؤجر الأن , أنقر على زر إضافة تقييم لكتابة تقييم للمستخدم
                {/if}
            </span>
        </div>
    </div>

{:else if data['type'] === 10}
    <div class="messageCell" style="background: rgba(83, 131, 236, 0.2)">
        <div class="order">
            <div class="orderLabel" style="border-color: blue;">تم إرسال طلب إرجاع</div>
        </div>
        <div class="canceledOrderLabel" style="color: #555">
            {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                تم إرسال طلب إرجاع من قبل المستأجر
            {:else}
                تم إرسال طلب إرجاع من قبلك
            {/if}
        </div>
        <div class="canceledOrderReason">
            <div class="reasonHeader"><IconsWorker icon="info" width="15px" /><span>تنبيه</span></div>
            <span class="reasonBody">
                {#if data['order_info']['item_info']['user_id'] === $myAccountState.data.id}
                    قم بأستلام الطلب من المستأجر واضغط على زر تأكيد الاستلام بالأعلى لتوثيق الاستلام
                {:else}
                    تم إرسال طلب إرجاع من قبلك , أطلب من المؤجر تأكيد الاستلام بعد تسليمه الغرض , لا تترك موقع التسليم إلا بعد تأكيد الإرجاع من قبل المالك
                {/if}
            </span>
        </div>
    </div>
{:else}
    fkkdfj
{/if}


    {#if data['from'] === $myAccountState.data.id}
        {#if (isSending)}
            <div class="messageSendAndSeenStatus"><IconsWorker icon="reChangeF" width="15px;" color="#fff"/></div>
        {:else}
            {#if (data.is_read)}
                <div class="messageSendAndSeenStatus" style="color: #7EC46A;"><IconsWorker icon="checkDoubleF" width="15px;" color="#fff"/></div>
            {:else if (data.is_received)}
                <div class="messageSendAndSeenStatus" style="color: #000;"><IconsWorker icon="checkDoubleF" width="15px;" color="#fff"/></div>
            {:else}
                <div class="messageSendAndSeenStatus" style="color: #7EC46A;"><IconsWorker icon="checkF" width="15px;" color="#fff"/></div>
            {/if}
        {/if}
    {/if}

</div>

<style>
.message{
    display: flex;
    flex-direction: column;
    margin: 20px;
    align-items: end;
    position: relative;
}

.myMessageChangeAlign{
    align-items: start;
}

.messageTime{
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    opacity: 0.5;
}

.messageTime span{
    margin-inline-start: 5px;
}

.messageCell{
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    background: rgba(126, 196, 106, 0.2);
    width: fit-content;
    max-width: 60%;
    position: relative;
}

.order{
    display: flex;
    flex-direction: row;
}

.order .orderImage{
    width: 90px;
    height: 90px;
    background: #000;
    border-radius: 12px;
    background-position: center;
    background-size: cover;
}
.order .orderInfoSide{
    display: flex;
    flex-direction: column;
    margin-inline-start: 15px;
    justify-content: center;
}
.order .orderLabel{
    padding: 6px  15px;
    border: 2px solid blue;
    border-radius: 100px;
    width: fit-content;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}
.order .orderInfoSide .orderTitle{
    font-size: 15px;
}
.order .orderInfoSide .orderDetailCell{
    font-size: 15px;
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    align-items: center;
}
.order .orderInfoSide .orderDetailCell .key{
    margin-inline-start: 4px;
    opacity: 0.6;
}
.order .orderInfoSide .orderDetailCell .value{
    margin-inline-start: 5px;
    opacity: 0.8;
}

.messageImagesGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 10px;
    width: fit-content;
}

.messageImagesGrid .cell{
    height: 90px;
    width: 90px;
    border-radius: 12px;
    cursor: pointer;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    margin: 5px;
}

.itemMapGeoLocation{
    width: 240px;
    height: 150px;
    background: #555;
    margin-top: 20px;
    border-radius: 12px;
    box-shadow: 0 0 10px #777;
    overflow: hidden;
}

.canceledOrderLabel {
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
}

.canceledOrderReason {
    display: flex;
    flex-direction: column;
    font-size: 17px;
    margin-top: 5px;
}

.canceledOrderReason .reasonHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    margin-top: 5px;
}

.canceledOrderReason .reasonHeader span{
    margin-inline-start: 5px;
}
.canceledOrderReason .reasonBody{
    margin-top: 5px;
    margin-inline-start: 20px;
}

.messageOptionsButtons{
    display: none;
    /* please recomment this when you find any issue
    display: flex;
     */
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}
.messageOptionsButtons .button{
    margin-inline-end: 10px;
}

.textMessage{
    width: fit-content;
    font-size: 16px;
}

.messageSendAndSeenStatus{
    position: absolute;
    width: 25px;
    height: 25px;
    background: #F09035;
    bottom: -12px;
    border-radius: 100px;
    border: 2px solid #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>