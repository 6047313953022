<script lang="ts">
import DuListView from "../../Components/DuListView.svelte";
import DashboardUsers from "../../Fragments/DashboardUsers.svelte";

let scrollContainer;
scrollContainer = document.body;

</script>

<DuListView class="list" fragmentName="dashboardUser" fragment="{DashboardUsers}" scrollContainer={scrollContainer}/>

<style>
.list{
    display: flex;
    flex-direction: column;
}
</style>