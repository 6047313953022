<script lang="ts">
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import {afterUpdate, onMount} from "svelte";

    export let keyWord:string = '';
    export let fetchedTags:[] = [];
    export let onSelect:(string) => string;
    export let container:Element;
    export let tagInput:Element;
    let focusItem:number = 0;
    let isLoading:boolean = false;


    const getData = () => {
        isLoading = true;
        new ConnectionWorker().request(<any>{
            url: '/hashtag/get',
            getParams: {
                q: keyWord,
            },
            onLoad: (response) => {
                if(response.status) {
                    fetchedTags = response.data.data;
                    focusItem = 0;
                }
                isLoading = false;
            }
        });
    }

    $: {
        if(keyWord !== '') {
            getData();
        }
    }

    const selectTag = (tag) => {
        if(onSelect) {
            const keyWordTrim = keyWord.replace(/\s+$/g, '');
            if(keyWordTrim.length > 0) {
                keyWord = keyWordTrim;
                onSelect(tag);
                keyWord = '';
            }
        }
    }

    onMount(() => {
        setTimeout(() => {
        tagInput.addEventListener('keydown', function (e) {
            if (e.which === 38) { //up
                if(focusItem > 0) {
                    focusItem = focusItem - 1;
                }
            } else if (e.which === 40) { // down
                if(focusItem < fetchedTags.length-1) {
                    focusItem = focusItem + 1;
                }
            } else if (e.which === 13) { // enter
                if(fetchedTags.length > 0) {
                    selectTag(fetchedTags[focusItem]);
                } else {
                    selectTag({title: keyWord});
                }
            }
        });

        tagInput.addEventListener('focusout', (e) => {
            if(fetchedTags.length === 0) {
                selectTag({title: keyWord});
            }
        });
        },200);
    });
</script>

<div class="tags">
    <div class="tagsSuggestion" bind:this={container}>
        {#if keyWord !== ''}
            {#if fetchedTags.length > 0}
                {#each fetchedTags as tag, index}
                    <div class="cell {focusItem === index ? 'hovered' : ''}" on:click={() => selectTag(tag)}><span>{tag.title}</span></div>
                {/each}
            {/if}
        {/if}
    </div>
</div>

<style>

    .tags{
        width: available;
        display: flex;
        position: relative;
    }

    .tags .tagsSuggestion{
        width: 200px;
        display: flex;
        flex-direction: column;
        position: absolute;
        background: #fff;
        top: 0;
        right:0;
        z-index: 201;
        border-radius: 12px;
        box-shadow: 0 0 10px #ccc;
        overflow: hidden;
    }

    .tags .tagsSuggestion .cell{
        width: available;
        padding: 15px;
        border-bottom: 1px solid #ccc;
        font-size: 14px;
        color: #F09035;
        font-weight: bold;
        cursor: pointer;
    }

    .hovered{
        background: #F09035;
        color: #fff !important;
    }
</style>