<script lang="ts">
import DuListView from "../../Components/DuListView.svelte";
import DashboardTagFragment from "../../Fragments/DashboardTagFragment.svelte";
let scrollContainer;
scrollContainer = document.body;
</script>

<div class="tags">
    <DuListView class="list" fragmentName="dashboardTag" fragment="{DashboardTagFragment}" scrollContainer={scrollContainer}/>
</div>

<style>
    .tags{
        width: available;
        height: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
</style>