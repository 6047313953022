<script lang="ts">
    import {onMount} from "svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import IconsWorker from "../IconsWorker.svelte";

    export let data = [];
    export let currentPlayingTagId;

    let hasAudioDescription = false;
    let audioDescriptionData = [];
    let status = 'init';

    onMount(() => {
        data.forEach((item) => {
            if(item['audioDescription'] !== '' && item['audioDescription'] !== undefined) {
                hasAudioDescription = true;
                audioDescriptionData.push({
                    "serial": item['audioDescription'],
                    "tagId": item['id'],
                    "audioDescriptionRange": item['audioDescriptionRange'],
                });
                // last to is the full duration
                fullDuration = parseInt(item['audioDescriptionRange']['to']);
            }
        });

        let _index = 0;
        audioDescriptionData.forEach((item) => {
            let _percent = ((item['audioDescriptionRange']['duration'] * 100) / fullDuration);
            audioDescriptionData[_index]['percent'] = _percent;
            _index++;
        });
    });

    let fullDuration = 0;
    let currentPosition = 0;
    const getFullAudioLink = (item) => {
        return ConnectionWorker.rootUrl + "/file/" + item['serial'].toString();
    }


    let currentPlayingIndex = 0;
    const setCurrentAudioIndex = (e) => {
        const _serial = e.target.getAttribute("data-serial");
        let _index = 0;

        let isCountBeforePositions = false;
        let _currentPosition = 0;

        audioDescriptionData.forEach((item) => {
            if(item.serial.toString() === _serial.toString()) {
                currentPlayingIndex = _index;
                currentPlayingTagId = item['tagId'];
                isCountBeforePositions = true;
                _currentPosition = _currentPosition + e.target.currentTime;
            } else {
                if(!isCountBeforePositions) {
                    _currentPosition = _currentPosition + item['audioDescriptionRange']['duration'];
                }
            }
            _index++;
        });

        if(_currentPosition !== currentPosition) {
            document.getElementById('contentBlockSection' + currentPlayingTagId.toString()).scrollIntoView({
                behavior: 'smooth'
            });
        }
        currentPosition = _currentPosition;
    }

    const play = () => {
        status = 'playing';
        document.getElementsByClassName('audioDescriptionPlayer')[currentPlayingIndex].play();
    }

    const onTimeUpdate = (e) => {
        setCurrentAudioIndex(e);
        if(e.target.currentTime === e.target.duration) {
            if(currentPlayingIndex < (audioDescriptionData.length - 1)) {
                document.getElementsByClassName('audioDescriptionPlayer')[currentPlayingIndex + 1].play();
            }
        }
    }

    $:getCurrentTimeAsPercent = () => {
        return (currentPosition * 100) / fullDuration;
    }

    let _statusBeforeSeek = '';
    const onPlayingProgressBarStartToChange = (e) => {
        _statusBeforeSeek = status;
        const audio = document.getElementsByClassName('audioDescriptionPlayer')[currentPlayingIndex].pause();
        status = 'paused';
        audio.pause();
    }

    const onPlayingProgressBarEndChange = (e) => {
        const audio = document.getElementsByClassName('audioDescriptionPlayer')[currentPlayingIndex].pause();
        let _progress = e.target.value;
        let currentPosition = ((_progress / 100) * fullDuration);
        let selectedItem;
        let _index = 0;
        audioDescriptionData.forEach((item) => {
            if(currentPosition >= item['audioDescriptionRange']['from'] && currentPosition <= item['audioDescriptionRange']['to']) {
                selectedItem = item;
                currentPlayingIndex = _index;
            }
            _index++;
        });

        document.getElementsByClassName('audioDescriptionPlayer')[currentPlayingIndex].currentTime =  Math.abs(currentPosition - selectedItem['audioDescriptionRange']['from']);
        if(_statusBeforeSeek === 'playing') {
            document.getElementsByClassName('audioDescriptionPlayer')[currentPlayingIndex].play();
            status = 'playing';
        }
    }


    const pause = () => {
        status = 'paused';
        document.getElementsByClassName('audioDescriptionPlayer')[currentPlayingIndex].pause();
    }

    function formatMSS(s){
        s = parseInt(s);
        return(s-(s%=60))/60+(9<s?':':':0')+s
    }

</script>

{#if hasAudioDescription}
    {#each audioDescriptionData as item}
        <audio
                src="{getFullAudioLink(item)}"
                class="audioDescriptionPlayer"
                on:timeupdate={onTimeUpdate}
                data-serial="{item.serial}"
                controls
        />
    {/each}
    <div class="audioPlayer">
        {#if status === 'paused' || status === 'init'}
            <button on:click={play} class="playButton">
                <IconsWorker icon="playF" width="15px" color="#fff"/>
            </button>
        {:else if status === 'playing'}
            <button on:click={pause} class="playButton">
                <IconsWorker icon="pauseF" width="15px" color="#fff"/>
            </button>
        {/if}
        <div class="gapper"></div>
        <div class="timer">{formatMSS(fullDuration)} / {formatMSS(currentPosition)}</div>
        <div class="gapper"></div>
        <div class="durationProgressArea">
            <div class="stepsBar">
                {#each audioDescriptionData as item}
                    <div class="stepCell" style="width: {item['percent']}%;"></div>
                {/each}
            </div>
            <input type="range" on:mousedown={onPlayingProgressBarStartToChange} on:mouseup={onPlayingProgressBarEndChange} value="{getCurrentTimeAsPercent()}" max="{100}" class="durationProgress"  />
        </div>
    </div>
{/if}

<style>
    .audioPlayer{
        display: flex;
        flex-direction: row;
        width: 70%;
        padding: 15px 15%;
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 5px #ccc;
        z-index: 100;
        align-items: center;
    }

    .gapper{
        width: 10px;
        height: 10px;
    }

    .durationProgressArea{
        flex: 1;
        position: relative;
        height: 12px;
    }

    .durationProgress {
        -webkit-appearance: none;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.4);
        outline: none;
        -webkit-transition: .2s;
        transition: background-color .2s;
        border-radius: 100px;
        padding: 0;
        margin: 0;
        z-index: 10;
    }

    .durationProgress:hover {
        background-color: rgba(0, 0,0 , 0.3);
    }

    .durationProgress::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 17px;
        height: 17px;
        border: 2px solid #fff;
        background: #555;
        cursor: pointer;
        border-radius: 100px;
        padding: 0;
        margin: -5px;
        opacity: 1;
        z-index: 12;
    }

    .durationProgress::-moz-range-thumb {
        width: 17px;
        height: 17px;
        border: 2px solid #fff;
        background: #F09035;
        opacity: 1;
        z-index: 12;
        cursor: pointer;
        border-radius: 100px;
        padding: 0;
        margin: 0;
    }

    .durationProgress::-webkit-slider-container {
        background: rgba(0, 0, 0, 0.2);
    }

    .durationProgress::-webkit-slider-runnable-track {
        background: rgba(0,0,0, 0.2);
        height: 100%;
        -webkit-appearance: none;
    }
    .durationProgress::-moz-range-progress {
        background: rgba(0,0,0, 0.2);
        height: 100%;
    }

    .stepsBar{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        z-index: -1;
    }

    .stepsBar .stepCell{
        height: 8px;
        border-radius: 100px;
        background: #000;
        margin: 5px 2px;
    }

    .playButton{
        padding: 0;
        margin: 0;
        width: 35px;
        height: 35px;
        border-radius: 100px;
        background-color: #F09035;
        border: 0;
        display: flex;
        place-items: center;
        place-content: center;
        cursor: pointer;
    }

    .playButton:active{
        opacity: 1;
    }
    .playButton:hover{
        opacity: 0.8;
    }

    .audioDescriptionPlayer{
        display: none;
    }

    @media only screen and (max-width: 600px) {
        .audioPlayer{
            width: 92%;
            padding: 5px 4%;
        }
    }
</style>