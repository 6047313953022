<script lang="ts" context="module">
    export const getIconAsStyle = (icon) => {
        if(icon !== 'loading') {
            return `/assets/icons/${icon}.svg`;
        }
        return "assets/images/loading.gif";
    }
</script>

<script lang="ts">
    export let width:(String) = '20px';
    export let color:String = '#000';
    export let icon:String = 'close';
</script>

    {#if icon !== 'loading'}
        <div {...$$props} style="width:{width}; height:{width};
        mask: {getIconAsStyle(icon)};
        background-color: {color};
        -webkit-mask-image: url({getIconAsStyle(icon)});
        mask-image: url({getIconAsStyle(icon)});"></div>
    {:else}
        <img {...$$props} style="width:{width}; height:{width}; opacity: 0.8;" alt="loading" src={getIconAsStyle(icon)}/>
    {/if}
<style>
    .IconsWorker{

    }
</style>