<script lang="ts">
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import IconsWorker from "../Components/IconsWorker.svelte";
    import {Link, useNavigate} from "svelte-navigator";
    export let data;
    export let isDeleted = false;
    const navigate = useNavigate();

    const getContentImageStyle = () => {
        if(data.image) {
            return `background-image: url('${ConnectionWorker.rootUrl + '/file/'}${data.image}?w=130')`;
        }
        return '';
    }

    const getLink = () => {
        return '/content/' + data.serial + '/' + data.title;
    }
</script>

{#if (!isDeleted)}
<div class="MainContentFragment">
    <Link to='{getLink()}'>
        <div class="contentImage" style="{getContentImageStyle()}">
            {#if !data.image}
                <IconsWorker icon="cameraF" width="30px" color="#555"/>
            {/if}
        </div>
    </Link>
    <div class="infoSide">
        <div class="title"><Link to='{getLink()}'>{data.title ? data.title : '(بدون عنوان)'}</Link></div>
        <div class="subTitle"><Link to='{getLink()}'>{data.sub_title ? data.sub_title : ''}</Link></div>
    </div>
</div>
{/if}

<style>
    .MainContentFragment{
        width: available;
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        background-color: #fff;
        box-shadow: 0 0 5px  #ccc;
        overflow: hidden;
    }
    .MainContentFragment:first-child{
        border-radius: 10px 10px 0 0;
    }
    .MainContentFragment:last-child{
        border-radius: 0 0 10px 10px;
    }

    .contentImage{
        width: 130px;
        height: 130px;
        background-color: #ccc;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        place-items: center;
        place-content: center;
    }

    .infoSide{
        display: flex;
        flex-direction: column;
        flex: 1;
        width: available;
        margin-inline-start: 10px;
        place-content: center;
    }
    .infoSide .title{
        font-size: 17px;
        color: #555;
    }
    .infoSide .subTitle{
        font-size: 15px;
        color: #888;
        margin-top: 5px;
    }

    .optionButton{
        width: 35px;
        height: 35px;
        background: #555;
        margin-inline-end: 15px;
        place-self: center;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        border-radius: 100px;
        box-shadow: 0 0 5px #ccc;
    }
</style>