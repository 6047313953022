<script lang="ts">
    import {onMount} from "svelte";
    import IconsWorker from "../IconsWorker.svelte";
    export let options:Object;
    let xPosition = 0;
    let yPosition = 0;
    let defaultTextColor = '#222';
    let nodeRef;

    const open = () => {
        //document.getElementById("app").classList.add("globalBlur");
        const e:Event = options['event'];
        xPosition = e.clientX + window.pageXOffset;
        yPosition = e.clientY + window.pageYOffset;
    }

    const close = () => {
        nodeRef.parentNode.removeChild(nodeRef);
    }

    const cancel = () => {
        //document.getElementById("app").classList.remove("globalBlur");
        if(options.onCancel !== undefined) {
            options.onCancel();
        }
        close();
    }

    const onSelect = (event, option) => {
        //document.getElementById("app").classList.remove("globalBlur");
        if(option.onSelect !== undefined) {
            option.onSelect();
        }
        close();
    }

    onMount(() => {
        open();
    });

    $: getStyle = () => {
        return `
            top: ${yPosition}px;
            left: ${xPosition}px;
        `;
    }

</script>

    <div class="DuOptionsMenuComponent" bind:this={nodeRef}>
        <div class="menu" style="{getStyle()}">
            {#each options.options as option}
                <div class="cell" on:click={(e) => onSelect(e, option) }>
                    <div class="title" style="color: {option.color !== undefined ? option.color : defaultTextColor}">{option.title}</div>
                    {#if (option.icon !== undefined)}
                        <div class="icon"><IconsWorker icon="{option.icon}" width="18px" color="{option.color !== undefined ? option.color : defaultTextColor}"/></div>
                    {/if}
                </div>
            {/each}
        </div>
        <div class="dismissArea" on:click={cancel}></div>
    </div>


<style>
.DuOptionsMenuComponent{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
}

.DuOptionsMenuComponent .dismissArea{
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    position: fixed;
    z-index: -1;
}

.menu{
    width: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.menu .cell{
    min-width: 150px;
    width: auto;
    display: flex;
    flex-direction: row;
    padding: 15px 18px;
    margin-bottom: 0.5px;
    background-color: rgba(255, 255, 255 ,0.90);
}
.menu .cell:last-child{
    margin-bottom: 0;
}
.menu .cell:hover{
    background-color: rgba(255, 255, 255 ,1);
}

.menu .cell .title{
    flex: 1;
}

.menu .cell .title{
    font-size: 14px;
}

.menu .cell .icon{
    margin-inline-start: 10px;
    display: flex;
    place-content: center;
    place-items: center;
}
</style>