<script lang="ts">
	import {Route, Router} from 'svelte-navigator'
	import MyAccountStore from './lib/stores/MyAccountStore'
	import About from "./lib/Views/About.svelte";
	import Home from "./lib/Views/Home.svelte";
	import LoginOrJoinUsingPhone from "./lib/Views/LoginOrJoinUsingPhone.svelte";
	import AddNewPost from "./lib/Views/AddNewPost.svelte";
	import {onMount} from "svelte";
	export let url = "";
	import ConnectionWorker from "./lib/Utils/ConnectionWorker.ts";
	import MyAccount from "./lib/Views/MyAccount/MyAccount.svelte";
	import Dashboard from "./lib/Views/Dashboard/Dashboard.svelte";
	import Content from "./lib/Views/Content.svelte";
	import AddNewService from "./lib/Views/AddNewService.svelte";
	import Service from "./lib/Views/Service.svelte";
	import Messages from "./lib/Views/Messages.svelte";
	import Message from "./lib/Views/Message.svelte";
	import Profile from "./lib/Views/Profile/Profile.svelte";
	import SupportCenter from "./lib/Views/SupportCenter/SupportCenter.svelte";

	let counterRandId = 0;
	let previousUrl = '';

	onMount(() => {
		new MyAccountStore().init();
		document.title = "إنكيدو | " + "عن التصميم و البرمجة نتحدث!";
		let observer = new MutationObserver(function(mutations) {
			if (location.href !== previousUrl) {
				previousUrl = location.href;
				setTimeout(() => {
					counterRandId = Math.floor(Math.random() * 1000000);
				}, 3000);
			}
		});

		const config = {subtree: true, childList: true};
		observer.observe(document, config);

	});

</script>

	<Router url="{url}">
		<Route path="/" component="{Home}" />
		<Route path="/home" component="{Home}" />
		<Route path="/tag/:id/:title" component="{Home}" />

		<Route path="/supportCenter/*" component="{SupportCenter}" primary="{false}"/>
		<Route path="/privacyPolices" component="{About}" primary="{false}"/>
		<Route path="/termsOfUsage" component="{About}" primary="{false}"/>

		<Route path="/login" component="{LoginOrJoinUsingPhone}" primary="{false}"/>
		<Route path="/content/:serial/*" component="{Content}" primary="{false}"/>
		<Route path="/addNewContent" component="{AddNewPost}" primary="{false}"/>
		<Route path="/addNewService" component="{AddNewService}" primary="{false}"/>
		<Route path="/updateContent/:id" component="{AddNewPost}" primary="{false}"/>
		<Route path="/myAccount/*" component="{MyAccount}" primary="{false}"/>
		<Route path="/dashboard/*" component="{Dashboard}" primary="{false}"/>
		<Route path="/service/:serial/:title" component="{Service}" primary="{false}"/>

		<Route path="/orders" component="{Messages}" primary="{false}"/>
		<Route path="/orders/:status" component="{Messages}" primary="{false}"/>
		<Route path="/purchases" component="{Messages}" primary="{false}"/>
		<Route path="/purchases/:status" component="{Messages}" primary="{false}"/>

		<Route path="/messenger/:interfaceId" component="{Message}" primary="{false}"/>
		<Route path="/profile/:serial" component="{Profile}" primary="{false}"/>
		<Route path="/profile/:serial/:page" component="{Profile}" primary="{false}"/>
	</Router>



	<svelte:head>
		{#key counterRandId }
			{#if previousUrl !== '' && (!window.location.hostname.includes("localhost") && !window.location.hostname.includes("127.0.0.1")) }
				<script type="text/javascript">var _Hasync= _Hasync|| [];
				_Hasync.push(['Histats.start', '1,4715652,4,0,0,0,00010000']);
				_Hasync.push(['Histats.fasi', '1']);
				_Hasync.push(['Histats.track_hits', '']);
				(function() {
					var hs = document.createElement('script'); hs.type = 'text/javascript'; hs.async = true;
					hs.src = ('//s10.histats.com/js15_as.js');
					(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(hs);
				})();</script>
				<noscript><a href="/" target="_blank"><img  src="//sstatic1.histats.com/0.gif?4715652&101" alt="counter" border="0" width="0px" height="0px"/></a></noscript>
			{/if}
		{/key}
	</svelte:head>

<style>
	@font-face {
		font-family: sukar;
		src: url(/assets/fonts/sukar/regular.ttf) format('truetype');
		font-display: swap;
	}


	:global(.mainContainer){
		width: 70%;
		display: flex;
		padding-right: 15%;
		padding-left: 15%;
	}

	:global(html){
		overflow: hidden;
	}


	:global(body){
		padding: 0;
		margin: 0;
		background-color: #eee;
		direction: rtl;
		overflow: auto;
		font-family: sukar,serif;
	}

	:global(a){
		text-decoration: none !important;
		color: inherit;
	}
	:global(a:visited){
		text-decoration: none !important;
		color: inherit;
	}
	:global(*:focus) {
		outline: none;
	}

	:global(.brightnessGlobalHover):hover{
		filter: brightness(93%);
		cursor: pointer;
	}

	:global(.brightnessGlobalHover):active{
		filter: brightness(70%);
	}

	:global(.mainPage){
		margin-top: 50px;
		display: flex;
		flex-direction: column;
	}

	:global(.globalBlur){
		-webkit-filter: blur(5px);
		-moz-filter: blur(5px);
		-o-filter: blur(5px);
		-ms-filter: blur(5px);
		filter: blur(5px);
	}

	:global(.noTextSelect){
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}

	:global(.mainContent) {}
	:global(.mainContent h1) { font-size: 30px; font-weight: bold; color: #111; }
	:global(.mainContent h2) { font-size: 25px; font-weight: bold; color: #333; }
	:global(.mainContent p) { font-size: 20px; color: #444; }
	:global(.mainContent q) {
		background-color: #fbefd7;
		padding: 20px;
	}

	@media only screen and (max-width: 600px) {
		:global(.mainContainer){
			width: 96%;
			display: flex;
			padding-right: 2%;
			padding-left: 2%;
		}
	}
</style>