<script lang="ts" context="module">
    export const enum DuInputType{
        any = 0,
        text = 1,
        email = 2,
        number = 3,
        username = 4,
        password = 5,
        textArea = 6,
        name = 7,
    }
</script>

<script lang="ts">
    import DuInputInterface from './DuInputInterface.svelte';
    import {forwardEventsBuilder} from '../../Utils/forwardEvents';
    import {current_component} from 'svelte/internal';

    export let label:string = '';
    export let value:string = '';
    export let placeHolder:string = '';
    export let inputType:DuInputType = DuInputType.text;
    export let inputTypeError:string = 'invalid';
    export let isValid:boolean = null;
    export let errorMessage:string = '';
    export let showErrorMessage:boolean = false;

    export let isRequired:boolean = false;
    export let isRequiredMessage:string = 'required';

    export let minLength:number = null;
    export let minLengthMessage:string = 'minimum than ' + minLength;

    export let maxLength:number = null;
    export let maxLengthMessage:string = 'maximum than ' + maxLength;

    export let magnifier:number = 0;
    export let backgroundColor:string = 'rgba(234, 56, 108, 0.1);';

    export let onFocus:Function;

    const forwardEvents = forwardEventsBuilder(current_component);

    const onKeyDown = (data) => {
        validate();
    }


    export const validate = () => {
        let _isValid:boolean = true;
        if(isRequired) {
            if(value === '') {
                _isValid = false;
                errorMessage = isRequiredMessage;
            }
        }

        if(_isValid && inputType == DuInputType.number) {
            _isValid = /^\d+$/.test(value);
            if(!_isValid) {
                errorMessage = inputTypeError;
            }
        } else if(_isValid && inputType == DuInputType.email) {
            _isValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
            if(!_isValid) {
                errorMessage = inputTypeError;
            }
        } else if(_isValid && inputType == DuInputType.username) {
            _isValid = /^[a-zA-Z0-9_]+$/.test(value);
            if(!_isValid) {
                errorMessage = inputTypeError;
            }
        } else if(_isValid && inputType == DuInputType.name) {
            _isValid = !/[`!@#$%^&*٪()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
            if(!_isValid) {
                errorMessage = inputTypeError;
            }
        }

        if(_isValid && minLength != null) {
            if(value.length < minLength) {
                errorMessage = minLengthMessage;
                _isValid = false;
            }
        }

        if(_isValid && maxLength != null) {
            if(value.length > maxLength) {
                errorMessage = maxLengthMessage;
                _isValid = false;
            }
        }

        isValid = _isValid;
        if(!isValid) {
            showErrorMessage = true;
        } else {
            showErrorMessage = false;
        }
    }


    const typeAction = (node) => {
        let _type = 'text';
        if(inputType === DuInputType.email)
            _type = 'email';
        if(inputType === DuInputType.number)
            _type = 'number';
        if(inputType === DuInputType.password)
            _type = 'password';
        if(inputType !== DuInputType.textArea)
            node.type = _type;
    }
</script>

<DuInputInterface errorMessage={showErrorMessage ? errorMessage : ''} {...$$props}>
    <svelte:fragment slot="input">
        {#if inputType === DuInputType.textArea}
            <textarea class="inputField"  bind:value placeholder="{placeHolder}" on:keyup={onKeyDown} use:typeAction use:forwardEvents></textarea>
        {:else}
            <input class="inputField"  bind:value placeholder="{placeHolder}" on:keyup={onKeyDown} use:typeAction use:forwardEvents/>
        {/if}
    </svelte:fragment>
</DuInputInterface>


<style>
    .inputField{
        flex: 1;
        background: transparent;
        border: 0;
        place-self: center;
        padding: 0;
        margin: 0;
    }

    .inputField:focus {
        outline: none ! important;
    }
</style>