<script lang="ts">
    import { Loader } from '@googlemaps/js-api-loader';
    import {onMount} from "svelte";
    import IconsWorker from "./IconsWorker.svelte";
    import MapOptions = google.maps.MapOptions;
    import googleMap = google.maps.Map;
    export let onLocationChange:Function;
    export let onMapReady:Function;
    export let location:Object = {
        latitude: 0,
        longitude: 0,
    };


    const loader = new Loader({
        apiKey: "AIzaSyCgc8-Oj1F95ZwsRc0ZME7bDOw_QdDX3t0",
        version: "weekly",
        libraries: ["places"]
    });

    let map:googleMap;
    let mapOptions:MapOptions = {
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        center: {
            lat: 0,
            lng: 0
        },
        zoom: 14,
    };

    onMount(() => {
        mapOptions['center']['lat'] = location['latitude'];
        mapOptions['center']['lng'] = location['longitude'];
        setTimeout(() => {
            loader
                .load()
                .then((google) => {
                    map = new google.maps.Map(document.getElementById("mapView") as HTMLElement, mapOptions);
                    startMapListener();
                    if(onMapReady !== undefined)
                        onMapReady();
                })
                .catch(e => {
                    // do something
                });
        }, 500);
    });

    const startMapListener = () => {
        map.addListener("center_changed", (e) => {
            location['latitude'] = map.getCenter().lat();
            location['longitude'] = map.getCenter().lng();
        });
    }
</script>

<div class="mapLocationPicker">
    <div class="mapView" id="mapView"></div>
    <div class="pickerOverlay">
        <IconsWorker icon="locationF" width="50px" color="#000"/>
    </div>
</div>

<style>
    .mapLocationPicker{
        width: 100%;
        min-height: 200px;
        height: 100%;
        position: relative;
    }
    .mapLocationPicker .mapView{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top:0;
        background: #ccc;
    }
    .mapLocationPicker .pickerOverlay{
        pointer-events: none;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top:0;
        display: flex;
        place-content: center;
        place-items: center;
    }
</style>