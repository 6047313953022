<script lang="ts">
    import MainHeader from "../Components/MainHeader.svelte";
    import DashboardSideBar from "../Components/DashboardSideBar.svelte";
    import {onMount} from "svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import DuListView from "../Components/DuListView.svelte";
    import MessageInterfaceFragment from "../Fragments/MessageInterfaceFragment.svelte";
    import IconsWorker from "../Components/IconsWorker.svelte";
    import {link, useParams} from 'svelte-navigator';
    import MyAccountStore from "../stores/MyAccountStore";

    $:getSideBarLinks = () => {
        if(getSelectedTap() === 'orders')
            return [
                {title: 'جميع الطلبات', link: '/orders', icon: 'truck' },
                    {title: 'بإنتظار الموافقة', link: '/orders/init', icon: 'truckWaiting' },
                    {title: 'المفتوحة', link: '/orders/inProgress', icon: 'truckInProgress' },
                    {title: 'المكتملة', link: '/orders/ended', icon: 'truckInDone' },
                    {title: 'الملغية', link: '/orders/canceled', icon: 'truckPan' },
                ];

        return [
            {title: 'جميع الطلبات', link: '/purchases', icon: 'purchase' },
            {title: 'بإنتظار الموافقة', link: '/purchases/init', icon: 'purchaseWaiting' },
            {title: 'المفتوحة', link: '/purchases/inProgress', icon: 'purchaseIn' },
            {title: 'المكتملة', link: '/purchases/ended', icon: 'purchaseOut' },
            {title: 'الملغية', link: '/purchases/canceled', icon: 'purchaseCanceled' },
        ];
    };

    let scrollContainer;
    scrollContainer = document.body;
    const myAccountState = MyAccountStore.state;
    const _useParams = useParams();

    $:getGetParams = () => {
        const params = { direction: 'outbox'};

        if(window.location.href.includes('orders'))
            params.direction = 'inbox';

        if($_useParams.status && params['status'] !== 'all') {
            params['status'] = $_useParams.status;
        }

        return params;
    }

    $:getSelectedTap = () => {
        if(window.location.href.includes('orders')) {
            return 'orders';
        }
        return 'purchases';
    }

</script>

<MainHeader />

{#if $myAccountState.isLogin && $myAccountState.data !== null}
    <div class="mainPage mainContainer">
        <div class="messages">
            <DashboardSideBar sideBarLinks="{getSideBarLinks()}"/>
            <div class="contentSide">
                <div class="topHeader">
                    <a href="/orders" class="cell {getSelectedTap() === 'orders' ? 'selectedTab' : ''}" use:link><IconsWorker color="#555" width="22px" icon="truck"/>
                        <span>المبيعات</span>
                        {#if $myAccountState.notifications['messages']['data']['outbox'] > 0}
                            <div class="budg">{$myAccountState.notifications['messages']['data']['outbox']}</div>
                        {/if}
                    </a>
                    <a href="/purchases" class="cell {getSelectedTap() === 'purchases' ? 'selectedTab' : ''}" use:link><IconsWorker color="#555" width="22px" icon="aliexpress"/>
                        <span>المشتريات</span>
                        {#if $myAccountState.notifications['messages']['data']['inbox'] > 0}
                            <div class="budg">{$myAccountState.notifications['messages']['data']['inbox']}</div>
                        {/if}
                    </a>
                </div>
                <div class="content">
                    {#key getGetParams()}
                        <DuListView class="list" getParams="{getGetParams()}" fragmentName="messagesInterface" fragment="{MessageInterfaceFragment}" scrollContainer={scrollContainer}/>
                    {/key}
                </div>
            </div>
        </div>
    </div>
{/if}

<style>
.messages{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.contentSide{
    width: available;
    flex: 1;
    margin-inline-start: 1%;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 7px #ccc;
}

.contentSide .topHeader{
    width: available;
    background: #fff;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    box-shadow: 0 0 7px #ccc;
    display: flex;
    flex-direction: row;
    margin-bottom: 1px;
    height: fit-content;
}

.contentSide .topHeader .cell{
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    background: rgba(240, 144, 53, 0.05);
    border-bottom: 2px solid transparent;
}

.contentSide .topHeader .selectedTab{
    border-bottom: 2px solid rgba(240, 144, 53, 0.8);
}
.contentSide .topHeader .cell span{
    margin-inline-start: 8px;
    margin-top: 2px;
    font-size: 18px;
}
.contentSide .topHeader .cell .budg{
    margin-inline-start: 5px;
    font-size: 12px;
    font-weight: bold;
    background: red;
    color: #fff;
    border-radius: 100px;
    width: 23px;
    height: 23px;
    line-height: 24px;
    text-align: center;
}

.contentSide .content{
    width: available;
    flex: 1;
    background: #fff;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    box-shadow: 0 0 5px #ccc;
    display: flex;
    flex-direction: column;
}

.list{
    display: flex;
    flex-direction: column;
}
</style>