<script lang="ts">
    import IconsWorker from "../IconsWorker.svelte";
    import {onMount} from "svelte";
    import RecordRTC from "recordrtc";
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import {textBlocks} from "./DuTextEditor.svelte";

    let media = [];
    let mediaRecorder = null;
    let blob = null;
    let audioAsFile = null;
    let status = 'init';

    let mp3Data = [];
    let mp3encoder;

    let audioId = Math.floor(Math.random() * 100000).toString() + "recorder";
    let recorderTimer;
    let recorderTimerValue = 0;

    let playingProgress = 0;

    function formatMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}

    onMount(async () => {
        if(serial !== undefined && serial !== '') {
            uploadStatus = 'uploaded';
            status = 'stopped';
        }
        /*
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder = new MediaRecorder(stream);
        let x = RecordRTC(mediaRecorder, {});
        mediaRecorder.ondataavailable = async (e) => {
            media.push(e.data);

            mp3encoder = new lamejs.Mp3Encoder(2, 44100, 128);
            console.log(' data available')
            const leftsamples = new Int16Array(e.data);   //for stereo
            const rightsamples = new Int16Array(e.data);  //for stereo
            let mp3Tmp = mp3encoder.encodeBuffer(leftsamples, rightsamples);
            let fmp3Tmp = mp3encoder.flush();
            let mp3Ct= fmp3Tmp.length;
            if(mp3Ct>0) {
                mp3Data.push(fmp3Tmp);
            }

            if (fmp3Tmp.length > 0) {
                mp3Data.push(new Int8Array(fmp3Tmp));
            }

            let mp3Blob = new Blob(mp3Data, {type: 'audio/mp3'});
            console.log(mp3Blob);

            var link = document.createElement("a"); // Or maybe get it from the current document
            link.href = await window.URL.createObjectURL(mp3Blob);
            link.download = "aDefaultFileName.mp3";
            link.innerHTML = "Click here to download the file";
            document.body.appendChild(link);

        }

        mediaRecorder.onstop = async function(){
            const audio = document.getElementById(audioId);
            blob = new Blob(media, {'type' : 'audio/ogg; codecs=opus' });
            media = [];
            audioAsFile = window.URL.createObjectURL(blob)
            audio.src = window.URL.createObjectURL(blob);
        }

        const audioElement = document.getElementById(audioId);
        audioElement.onended = (e) => {
            status = 'stopped';
        }
        audioElement.ontimeupdate = (e) => {
            playingProgress = audioElement.currentTime / audioElement.duration * 100;
        }*/
    });
    const startRecording = () => {
        recorderTimerValue = 0;
        recorderTimer = setInterval(() => {
            recorderTimerValue++;
        }, 1000);
        status = 'recording';
        mediaRecorder.start();
    }



    const stopRecording = async () => {
        clearInterval(recorderTimer);
        mediaRecorder.stop();
        status = 'stopped';

        setTimeout(async () => {


        }, 1000);

    }

    const deleteRecord = () => {
        stopPlaying();
        status = 'init';
        blob = null;
        serial = '';
    }

    const playRecording = () => {
        status = 'playing';
        const audio = document.getElementById(audioId);
        audio.play();
    }

    const pausePlaying = () => {
        status = 'paused';
        const audio = document.getElementById(audioId);
        audio.pause();
    }
    const stopPlaying = () => {
        status = 'stopped';
        const audio = document.getElementById(audioId);
        audio.pause();
        audio.currentTime = 0;
        playingProgress = 0;
    }

    const onPlayingProgressBarEndChange = (e) => {
        const audio = document.getElementById(audioId);
        playingProgress = e.target.value;
        status = 'playing';
        audio.currentTime =  ((playingProgress / 100) * audio.duration);
        audio.play();
    }

    const onPlayingProgressBarStartToChange = (e) => {
        const audio = document.getElementById(audioId);
        status = 'paused';
        audio.pause();
    }

    let fileInput;
    let file;
    export let serial = '';
    let uploadStatus = 'init';

    const onSelectFile = (e) => {
        file = e.target.files[0];
        uploadFile();
    }

    const uploadFile = async () => {
        uploadStatus = 'uploading';
        await new ConnectionWorker().request(<any>{
            url: '/upload',
            filesParams: {
                'file': file,
            },
            onLoad: (response) => {
                if(response['status']) {
                    uploadStatus = 'uploaded';
                    serial = response['serial'];
                    status = 'stopped';
                } else {
                    uploadStatus = 'failed';
                }
            },
            onFail: (errorCode) => {
                setTimeout(() => {
                    uploadFile();
                }, 3000);
            }
        });
    }

    $:getAudioLink = () => {
        if(serial !== '') {
            return ConnectionWorker.rootUrl + '/file/' + serial.toString();
        }
        return '';
    }
</script>

<svelte:head>
    <!--<script src='/lame.min.js' />-->
</svelte:head>

<input style="display:none" type="file" accept=".mp3" on:change={(e)=>onSelectFile(e)} bind:this={fileInput} />
<div class="contentSoundRecorder" style="opacity: {uploadStatus === 'uploading' ? 0.5 : 1}; pointer-events: {uploadStatus === 'uploading' ? 'none' :  'auto'}">
    <audio class="audioTag" id="{audioId}" src="{getAudioLink()}" controls></audio>
    {#if status === 'init'}
        <button on:click={fileInput.click()} ><IconsWorker icon="mp3FileF" width="15px" color="#fff"/></button>
        <!--<button on:click={startRecording}><IconsWorker icon="startRecord" width="15px" color="red"/></button>-->
    {:else if status === 'recording'}
        <button on:click={stopRecording}><IconsWorker icon="stopRecordF" width="15px" color="#fff"/></button>
    {:else if status === 'stopped' || status === 'playing' || status === 'paused' || serial !== ''}
        {#if status === 'playing'}
            <button on:click={pausePlaying}><IconsWorker icon="pauseF" width="15px" color="#fff"/></button>
            <div class="gapper"></div>
            <button on:click={stopPlaying}><IconsWorker icon="stopRecordF" width="15px" color="#fff"/></button>
        {:else if status === 'paused' || status === 'stopped'}
            <button on:click={playRecording}><IconsWorker icon="playF" width="15px" color="#fff"/></button>
        {/if}
    {/if}
    <div class="gapper"></div>
    <div class="infoArea">
        {#if ['stopped', 'playing', 'paused'].includes(status)}

        {:else}
            <div class="recodingTimer">{formatMSS(recorderTimerValue)}</div>
        {/if}
        {#if ['stopped', 'playing', 'paused'].includes(status)}
            <input type="range" class="progressIndicator" on:mousedown={onPlayingProgressBarStartToChange} on:mouseup={onPlayingProgressBarEndChange} value="{playingProgress}" />
        {/if}
    </div>
    {#if ['stopped', 'playing', 'paused'].includes(status)}
        <div class="gapper"></div>
        <button style="background: red;" on:click={deleteRecord}><IconsWorker icon="deleteF" width="15px" color="#fff"/></button>
    {/if}
</div>

<style>

    .contentSoundRecorder{
        width: 96%;
        display: flex;
        flex-direction: row;
        padding: 5px 2%;
        background: #fff;
        border-radius: 100px;
        place-content: start;
        place-items: center;
        height: min-content;
    }

    .contentSoundRecorder .gapper{
        width: 10px;
        height: 10px;
    }
    .contentSoundRecorder button{
        width: 30px;
        height: 30px;
        border-radius: 100px;
        background: #222;
        display: flex;
        place-content: center;
        place-items: center;
        flex: unset;
        border: 0;
        cursor: pointer;
        margin: 0;
    }

    .audioTag{
        display: none;
    }

    .infoArea{
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .progressIndicator{
        width: 100%;
        height: 15px;
        border-radius: 100px;
        background: #ccc;
        overflow: hidden;
        cursor: pointer;
    }

    .progressIndicator .currentProgress{
        width: 20%;
        height: 100%;
        border-radius: 100px;
        background: #F09035;
        pointer-events: none;
    }

</style>