<script lang="ts">
    import {onMount} from "svelte";
    import IconsWorker from "../IconsWorker.svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import DuButton from "../DuButton.svelte";

    export let onTap;
    export let data;
    export let onRemove;
    export let isPlaceHolder: false;

    export let emptyPlaceHolderText;
    export let emptyPlaceHolderIcon;

    let isGetFileInfo:boolean = true;

    let state = '';
    let type = '';
    let previewImage;
    let previewImageFromSerial;



    onMount(() => {
        setTimeout(async () => {
            if(!isPlaceHolder) {
                if(data.state !== 'uploaded') {
                    if (data.serial === undefined && state === '') {
                        state = 'ready';
                    }

                    if (data.file !== undefined) {
                        type = data.file.type.split('/')[1];
                    }

                    if (['png', 'jpeg', 'jpg', 'gif',].includes(type)) {
                        let reader = new FileReader();
                        await reader.readAsDataURL(data.file);
                        reader.onload = e => {
                            previewImage = e.target.result;
                        }
                    }

                    upload(data.file);
                } else {
                    getUploadedFileInfo();
                }
            }
        }, 200);
    });


    const getUploadedFileInfo = () => {

        isGetFileInfo = true;
        new ConnectionWorker().request(<any>{
            url: '/file/getFileInfo/' + data.serial,
            onLoad: (response) => {
                if(response['status']) {
                    type = response.data.type;
                    if (['png', 'jpeg', 'jpg', 'gif',].includes(type)) {
                        previewImageFromSerial = ConnectionWorker.rootUrl + '/file/' + data.serial;
                    } else {
                        // todo: show icon if the extension not image
                    }
                } else {
                    getUploadedFileInfo();
                }
            },
            onFail: (code) => {
                setTimeout(() => {
                    getUploadedFileInfo();
                }, 2000);
            }
        });
    }

    const upload = (file) => {
        state = 'uploading';
        new ConnectionWorker().request(<any>{
            url: '/upload',
            filesParams: {
                'file': file,
            },
            onLoad: (response) => {
                console.log(response);
                if(response['status']) {
                    data.serial = response['serial'];
                    state = 'uploaded';
                } else {
                    state = 'fail';
                }
            },
            onFail: (code) => {
                state = 'fail';
            }
        });
    }

    const deleteFile = () => {
        onRemove();
    }

    $: previewBackgroundImageStyle = () => {
        if(previewImage !== undefined)
            return previewImage;

        if(previewImageFromSerial !== undefined)
            return previewImageFromSerial;

        return '';
    }
</script>


<div class="DuFileUploaderGridCell" on:click={() => {
    if(onTap !== undefined) {
        onTap();
    }
}}>
    {#if isPlaceHolder}
        <div class="placeHolder noTextSelect">
            <IconsWorker icon="{emptyPlaceHolderIcon}" width="30px" color="#333"/>
            <span>{emptyPlaceHolderText}</span>
        </div>
    {:else}
        <div class="preview" style="{ 'background-image: url(' + previewBackgroundImageStyle() + ')' }"></div>
        {#if ['uploading', 'fail'].includes(state)}
            <div class="progressOverlay">
                {#if (state === 'uploading')}
                    <IconsWorker width="25px" color="#fff" icon="loading" />
                {:else if (state === 'fail')}
                    <IconsWorker width="25px" color="red" icon="errorF" />
                    <div class="actionButton">
                        <DuButton textColor="#fff" fontSize="{12}" background="#F09035" text="إعادة المحاولة" onTap="{() => {
                            upload(data.file);
                        }}"/>
                    </div>
                {/if}
            </div>
        {/if}
        <div class="deleteButton brightnessGlobalHover" on:click={deleteFile}>
            <IconsWorker color="#fff" icon="deleteF" width="18px"/>
        </div>
    {/if}
</div>


<style>
    .DuFileUploaderGridCell{
        background: #fff;
        margin: 5px;
        border-radius: 12px;
        box-shadow: 0 0 5px #ccc;
        position: relative;
        overflow: hidden;
        aspect-ratio: 1;
    }

    .DuFileUploaderGridCell:hover .deleteButton{
        visibility: visible;
    }
    .DuFileUploaderGridCell .preview{
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        overflow: hidden;
    }

    .DuFileUploaderGridCell .progressOverlay{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.4);
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
    }

    .DuFileUploaderGridCell .actionButton{
        margin-top: 5px;
    }

    .DuFileUploaderGridCell .deleteButton{
        visibility: hidden;
        position: absolute;
        top: 15px;
        left: 15px;
        padding: 10px;
        border-radius: 100px;
        background: red;
        box-shadow: 0 0 10px #ccc;
        opacity: 0.8;
    }

    .DuFileUploaderGridCell .placeHolder{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        place-items: center;
        place-content: center;
        cursor: pointer;
    }

    .DuFileUploaderGridCell .placeHolder span{
        font-size: 13px;
        font-weight: bold;
        color: #555;
        margin-top: 10px;
    }

    .DuFileUploaderGridCell .placeHolder:hover{
        opacity: 0.6;
    }
    .DuFileUploaderGridCell .placeHolder:active{
        opacity: 0.3;
    }
</style>