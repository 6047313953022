<script lang="ts">
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import IconsWorker from "../Components/IconsWorker.svelte";
    import DuOptionMenu from "../Components/DuOptionsMenu/DuOptionsMenu";
    import {Link, useNavigate} from "svelte-navigator";
    import DuConfirmDialog from "../Components/DuConfirmDialog/DuConfirmDialog";

    export let data;
    export let isDeleted = false;
    const navigate = useNavigate();

    const getContentImageStyle = () => {
        if(data.image) {
            return `background-image: url('${ConnectionWorker.rootUrl + '/file/'}${data.image}')`;
        }
        return '';
    }

    const openOptionsMenu = (e) => {
        new DuOptionMenu().create({
            event: e,
            options: [
                {title: 'حذف', icon: 'delete', onSelect: () => {
                    new DuConfirmDialog().create({
                        title: 'هل تود حذف المقال ؟',
                        subTitle: 'لا يمكن التراجع عن هذا الإجراء',
                        titleColor: 'red',
                        options: [
                            {title: 'حذف', isBold: true, onSelect: () => {
                                deleteIt();
                            }, color: 'red'},
                            {title: 'تراجع',}
                        ]
                    });
                }, color: 'red'},
                {title: 'مشاركة', icon: 'share', onSelect: () => {

                    }},
                {title: 'تحرير', icon: 'pen', onSelect: () => {
                        Object.assign(document.createElement('a'), {
                            target: '_blank',
                            rel: 'noopener noreferrer',
                            href: '/updateContent/' + data.id.toString(),
                        }).click();
                }},
            ]
        });
    }

    const deleteIt = () => {
        new ConnectionWorker().request(<any>{
            url: '/content/delete',
            postParams: { id: data.id },
            onLoad: (response) => {
                console.log(response);
                if(response.status) {
                    isDeleted = true;
                } else {

                }
            }
        });
    }

    const getLink = () => {
        return '/content/' + data.serial + '/' + data.title;
    }
</script>

{#if (!isDeleted)}
<div class="userContentFragment">
    <Link to='{getLink()}'>
        <div class="contentImage" style="{getContentImageStyle()}">
            {#if !data.image}
                <IconsWorker icon="cameraF" width="30px" color="#555"/>
            {/if}
        </div>
    </Link>
    <div class="infoSide">
        <div class="title"><Link to='{getLink()}'>{data.title ? data.title : '(بدون عنوان)'}</Link></div>
        <div class="subTitle"><Link to='{getLink()}'>{data.sub_title ? data.sub_title : ''}</Link></div>
    </div>
    <div class="optionButton brightnessGlobalHover" on:click={openOptionsMenu}>
        <IconsWorker icon="xDotMenuF" width="15px" color="#fff"/>
    </div>
</div>
{/if}

<style>
    .userContentFragment{
        width: available;
        display: flex;
        flex-direction: row;
        margin-bottom: 2px;
        background-color: #fff;
        box-shadow: 0 0 5px  #ccc;
        overflow: hidden;
    }
    .userContentFragment:first-child{
        border-radius: 10px 10px 0 0;
    }
    .userContentFragment:last-child{
        border-radius: 0 0 10px 10px;
    }

    .contentImage{
        width: 100px;
        height: 100px;
        background-color: #ccc;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        place-items: center;
        place-content: center;
    }

    .infoSide{
        display: flex;
        flex-direction: column;
        flex: 1;
        width: available;
        margin-inline-start: 10px;
        place-content: center;
    }
    .infoSide .title{
        font-size: 17px;
        color: #555;
    }
    .infoSide .subTitle{
        font-size: 15px;
        color: #888;
        margin-top: 5px;
    }

    .optionButton{
        width: 35px;
        height: 35px;
        background: #555;
        margin-inline-end: 15px;
        place-self: center;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        border-radius: 100px;
        box-shadow: 0 0 5px #ccc;
    }
</style>