var _a;
export const LocationHelper = (_a = class {
    },
    _a.calcCrow = (lat1, lon1, lat2, lon2) => {
        let deg2rad = Math.PI / 180;
        lat1 *= deg2rad;
        lon1 *= deg2rad;
        lat2 *= deg2rad;
        lon2 *= deg2rad;
        let diam = 12742; // Diameter of the earth in km (2 * 6371)
        let dLat = lat2 - lat1;
        let dLon = lon2 - lon1;
        let a = ((1 - Math.cos(dLat)) +
            (1 - Math.cos(dLon)) * Math.cos(lat1) * Math.cos(lat2)) / 2;
        return diam * Math.asin(Math.sqrt(a));
    },
    // Converts numeric degrees to radians
    _a.toRad = (Value) => Value * Math.PI / 180,
    _a);
