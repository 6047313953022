<script lang="ts">
    import IconsWorker from "./IconsWorker.svelte";
    import firebase from "firebase/compat";

    export let text:string = '';
    export let background:string = '#F09035';
    export let textColor:string = '#fff';
    export let icon:string = '';
    export let fontSize:number = 15;
    export let isDisabled:boolean = false;
    export let isLoading:boolean = false;
    export let fullWidth:boolean = false;
    export let isBold:boolean = false;
    export let padding:string;

    export let onTap:Function;

    $: getStyle = () => {
        let style = `background: ${background};
            color: ${textColor};
            font-size: ${fontSize}px;
            opacity: ${isDisabled ? 0.7 : 1};
            pointer-events: ${isDisabled ? 'none' : 'auto'};`;

        if(fullWidth) {
            style = style + `
                width: 100%;
                justify-content: center;
            `;
        }

        if(isBold) {
            style = style + `
                font-weight: bold;
            `;
        }

        if(padding !== undefined) {
            style = style + `
                padding: ${padding};
            `;
        }
        return style;
    }

    const onClick = () => {
        onTap();
    }
</script>

<button {...$$props}  class="duButton brightnessGlobalHover" style="{getStyle()}" on:click={onClick}>
    {#if isLoading}
        <IconsWorker icon="loading" width="{fontSize}px" color="{textColor}"/>
    {:else}
        <span>{text}</span>
        {#if icon !== ''}
            <div class="splitter" style="width: 10px;"></div>
            <IconsWorker icon="{icon}" width="{fontSize}px" color="{textColor}"/>
        {/if}
    {/if}
</button>

<style>
    .duButton{
        border-radius: 100px;
        padding: 8px 15px;
        margin: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        place-items: center;
    }
</style>