<script lang="ts">
    import Prism from 'prismjs';
    import PrismLangs from 'prismjs/components/prism-dart';
    import NormalizeWhitespace from 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';
    import PrismStyle from 'prismjs/themes/prism-twilight.css';
    import {onMount} from "svelte";
    import DuButton from "../DuButton.svelte";


    export let readOnly = false;
    export let blockProps;
    export let value = '';
    let isEditing = true;
    let htmlValue = '';

    const onCodeTagChange = (e) => {
        const prism = Prism;
        let nw = Prism.plugins.NormalizeWhitespace;
        let valueAfterIntends = nw.normalize(value, {
            'remove-initial-line-space': true,
            'remove-trailing': true,
            'left-trim': false,
            'right-trim': false,
        });

        htmlValue = prism.highlight(valueAfterIntends, Prism.languages.dart, 'prism-dart');
        isEditing = false;//prism-dart.min
        //<?php echo "ff"; ?>
    }

    const onChange = (e) => {
        const prism = Prism;
        let nw = Prism.plugins.NormalizeWhitespace;
        let valueAfterIntends = nw.normalize(value, {
            'remove-initial-line-space': true,
            'remove-trailing': true,
            'left-trim': false,
            'right-trim': false,
        });

        htmlValue = prism.highlight(valueAfterIntends, Prism.languages.dart, 'prism-dart');
        isEditing = false;//prism-dart.min
        //<?php echo "ff"; ?>
    }

    const edit = () => {
        isEditing = true;
    }

    onMount(() => {
        if(readOnly) {
            onCodeTagChange(null);
        }
       // Prism.plugins.autoloader.languages_path = '/assets/prism-components';
    });

</script>

<div class="codeSnippet" dir="ltr">
    {#if !readOnly}
        <div class="options">
            {#if !isEditing}
                <DuButton text="تعديل الكود" icon="penF" fontSize="{12}" background="#000" onTap="{edit}"/>
            {/if}
        </div>
    {/if}
    {#if isEditing}
        <textarea
            contenteditable="true"
            on:focusout={onCodeTagChange}
            bind:value={value}
            placeholder="قم بكتابة الكود هنا"
            ></textarea>
    {:else}
        <div class="formatedCode">
            <pre class="language-dart"><code>{@html htmlValue}</code></pre>
        </div>
    {/if}
</div>


<style>
    .codeSnippet{
        color: #fff;
        width: available;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .codeSnippet .options{
        width: available;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
    }

    .formatedCode {
        white-space: pre-wrap;
        width: available;
        flex: 1;
        display: flex;
        flex-direction: row;
        background: #000;
        border-radius: 12px;
    }

    pre{
        flex: 1;
        width: available;
        border:0;
        background: #000;
    }

    code{
        flex: 1;
        width: 100%;
        background: #000;
        border-radius: 12px;
    }

    textarea{
        white-space: pre-wrap;
        background: #000;
        padding: 20px;
        color: #fff;
        min-height: 200px;
        font-size: 17px;
        text-align: start;
        direction: ltr;
    }
</style>