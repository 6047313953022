var _a;
import { writable } from 'svelte/store';
import Cookies from 'js-cookie';
import ConnectionWorker from "../Utils/ConnectionWorker";
const MyAccountStore = (_a = class {
        constructor() {
            this.init = () => {
                const authKey = Cookies.get('authKey');
                const userId = Cookies.get('userId');
                const longitude = Cookies.get('longitude');
                const latitude = Cookies.get('latitude');
                MyAccountStore.state['authKey'] = authKey;
                MyAccountStore.state['userId'] = userId;
                if (longitude !== undefined && longitude !== null && latitude !== null) {
                    MyAccountStore.state.update((oldState) => {
                        oldState.location.latitude = latitude;
                        oldState.location.longitude = longitude;
                        return oldState;
                    });
                }
                this.fetchData();
            };
            this.login = (token, userId) => {
                Cookies.set('authKey', token);
                Cookies.set('userId', userId);
                MyAccountStore.state.update((oldState) => {
                    oldState.userId = userId;
                    oldState.authKey = token;
                    oldState.isLogin = true;
                    return oldState;
                });
            };
            this.logout = () => {
                Cookies.remove('authKey');
                Cookies.remove('userId');
                MyAccountStore.state.update((oldState) => {
                    oldState.userId = null;
                    oldState.authKey = null;
                    oldState.isLogin = false;
                    return oldState;
                });
            };
            this.fetchData = () => {
                const myAccountStore = MyAccountStore.state;
                if (myAccountStore['authKey'] !== undefined && myAccountStore['userId'] !== undefined) {
                    MyAccountStore.state.update((oldState) => {
                        oldState.userId = myAccountStore['userId'];
                        oldState.authKey = myAccountStore['authKey'];
                        oldState.isLogin = true;
                        return oldState;
                    });
                    const getParams = {
                        id: myAccountStore['userId'],
                    };
                    if (myAccountStore['data'] === undefined || myAccountStore['data'] === null) {
                        getParams['withNotificationCount'] = true;
                    }
                    new ConnectionWorker().request({
                        url: '/user/get',
                        getParams: getParams,
                        onLoad: (response) => {
                            if (response) {
                                MyAccountStore.state.update((oldState) => {
                                    oldState.data = response.data;
                                    if (response.notifications) {
                                        oldState.notifications = response.notifications;
                                    }
                                    return oldState;
                                });
                            }
                            else {
                                //todo: make logout when something wrong in server
                            }
                        }
                    });
                }
            };
        }
        setLocation(latitude, longitude) {
            Cookies.set('longitude', longitude);
            Cookies.set('latitude', latitude);
            MyAccountStore.state.update((oldState) => {
                oldState.location.latitude = latitude;
                oldState.location.longitude = longitude;
                return oldState;
            });
        }
    },
    _a.state = writable({
        isLogin: false,
        authKey: null,
        userId: null,
        data: null,
        notifications: null,
        location: {
            longitude: null,
            latitude: null
        },
    }),
    _a.isListenToUpdates = false,
    _a);
export default MyAccountStore;
