<script lang="ts">
    import IconsWorker from "../Components/IconsWorker.svelte";
    import DuConfirmDialog from "../Components/DuConfirmDialog/DuConfirmDialog";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import FixedNotifications from "../Components/DuNotifications/FixedNotifications";
    export let data = [];
    export let onDelete:() => void;
    export let onEdit:() => void;

    let isDeleting = false;
    let isDeleted = false;

    const typesArray =  [
        {'key': 'providers', 'ar': 'أسئلة البائعين', 'en': 'Providers Questions'},
        {'key': 'buyers', 'ar': 'أسئلة المشترين', 'en': 'Buyer Questions'},
    ];

    const getTypeAsFromLanguage = (theKey) => {
        let _val = ';'
        typesArray.forEach((item) => {
            if(item.key === theKey) {
                _val = item['ar'];
            }
        });
        return _val;
    }

    let sendDeleteRequest = () => {
        isDeleting = true;
        new ConnectionWorker().request(<any>{
            url: '/faq/delete',
            postParams: {
                id: data['id'],
            },
            onLoad: (response) => {
                if(response['status']) {
                    new FixedNotifications().create({
                        title: 'تم حذف السؤال بنجاح',
                        icon: 'checkRoundedOut',
                        color: 'green',
                        removeAfter: 2000,
                    });
                    isDeleted = true;
                } else {
                    new FixedNotifications().create({
                        title: 'حدث خطأء غير معروف',
                        subTitle: 'حاول مجددًا',
                        icon: 'error',
                        color: 'red',
                        removeAfter: 2000,
                    });
                }
                isDeleting = false;
            },
            onFail: (code) => {
                new FixedNotifications().create({
                    title: 'حدث خطأء غير معروف',
                    subTitle: 'حاول مجددًا',
                    icon: 'error',
                    color: 'red',
                    removeAfter: 2000,
                });
                isDeleting = false;
            }
        });
    }

    let deleteItem = (data) => {
        new DuConfirmDialog().create({
            title: 'هل تريد إزالة السؤال ؟',
            titleColor: 'red',
            subTitle: 'لا يمكن التراجع عن هذا الإجراء',
            options: [
                {title: 'إزالة', onSelect: () => sendDeleteRequest(), color: 'red'},
                {title: 'تراجع', },
            ],
        })
    }

</script>

{#if !isDeleted}
    <div class="faqFragment {isDeleting ? 'disable' : ''}">
        <div class="question">
            <span class="cat">{getTypeAsFromLanguage(data.topic)}</span>
            <span class="text">{data.question}</span>
            <button class="brightnessGlobalHover" style="background: #F09035;" on:click={() => {
                if(onEdit) { onEdit(); }
            }}><IconsWorker icon="PenF" width="15px" color="#fff"/></button>
            <button class="brightnessGlobalHover" style="background: red;" on:click={() => {
                deleteItem();
            }}><IconsWorker icon="deleteF" width="15px" color="#fff"/></button>
        </div>
        <div class="answer">{data.answer}</div>
    </div>
{/if}

<style>
.faqFragment{
    display: flex;
    flex-direction: column;
    margin: 10px;
    box-shadow: 0 0 10px #ccc;
    border-radius: 12px;
}

.question{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: #eee;
    padding: 13px 20px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    font-weight: bold;
}

.question .cat{
    padding: 7px 12px;
    border-radius: 100px;
    background: rgba(240, 144, 53, 0.1);
    font-size: 11px;
    font-weight: bold;
    margin-inline-end: 5px;
}
.question .text{
    flex: 1;
}

.question button{
    margin-inline-start: 5px;
    margin-inline-end: 5px;
    margin-bottom: 0;
    margin-top: 0;
    background: #000;
    border-radius: 100px;
    padding: 10px;
    border: 0;
}

.answer{
    padding: 20px;
    font-size: 14px;
}

.disable{
    opacity: 0.5;
    pointer-events: none;
}
</style>