<script lang="ts">
    import {onMount} from "svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import viewport from '../Utils/useViewportAction';
    import DuAnimation from "../Utils/DuAnimation";

    export let serial;
    let container:Element;
    let mainImage:Element;
    let placeHolderImageUrl = '';
    let imageUrl = '';
    let isPlaceHolderImageLoad:boolean = false;
    let isInViewPort:boolean = false;
    let isImageLoad:boolean = false;
    const newId = Math.floor(Math.random() * 100000);

    const onImageHolderLoad = () => {
        isPlaceHolderImageLoad = true;
    }
    const loadImage = () => {
        isInViewPort = true;
        const maxWidth = (container.parentElement.clientWidth);
        imageUrl = ConnectionWorker.rootUrl + '/file/' + serial.toString() + '?w='  + maxWidth;
        let img = new Image();
        img.onload = function() {
            isImageLoad = true;
            setTimeout(() => {
                mainImage.src = img.src;
                DuAnimation.animate({
                    id: newId,
                    animation: 'fadeIn',
                    duration: 500,
                });
            }, 1);
        }
        img.src = imageUrl;

    }
    onMount(() => {
        placeHolderImageUrl = ConnectionWorker.rootUrl + '/file/' + serial.toString() + '?w=20&h=20&blur=true';
    });
</script>
    <div class="contentImage" bind:this={container} use:viewport on:enterViewport={() => { loadImage(); }} id="{newId}">
        {#if !isImageLoad}
            <div class="loadingPlaceHolder">
                <img src="{placeHolderImageUrl}" on:load={() => {
                    onImageHolderLoad();
                }}/>
            </div>
        {:else}
            <img class="mainImage" bind:this={mainImage}/>
        {/if}
    </div>
<style>
    .contentImage{
        width: available;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
    .contentImage .loadingPlaceHolder{
        width: 90%;
    }
    .contentImage .loadingPlaceHolder img{
        width: 100%;
        opacity: 0.5;
    }
    .contentImage .mainImage{
        max-width: 100%;
    }
</style>