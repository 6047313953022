<script lang="ts">
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import IconsWorker from "./IconsWorker.svelte";
    import {onMount} from "svelte";
    import ContentImage from "./ContentImage.svelte";
    import {link} from "svelte-navigator";

    export let id;
    let isLoading = true;
    let data = [];

    const getData = () => {
        isLoading = true;
        new ConnectionWorker().request(<any>{
            url: '/content/getRelatedPosts',
            getParams: {
                'id': id,
            },
            onLoad: (response) => {
                console.log(response);
                isLoading = false;
                data = response.data;
            }
        });
    }

    onMount(() => {
        getData();
    });

</script>

{#if id}
    <div class="relatedPosts">
        {#if isLoading}
            <div class="loading">
                <IconsWorker icon="loading" width="30px"/>
            </div>
        {:else}
            {#each data as dataRow}
                <a href="/content/{dataRow.serial}/{dataRow.title}" class="cell" use:link>
                    <div class="thumb">
                        <ContentImage serial="{dataRow.image}"/>
                    </div>
                    <div class="contentArea">
                        <div class="title">{dataRow.title}</div>
                        <div class="subTitle">{dataRow.sub_title}</div>
                    </div>
                </a>
            {/each}
        {/if}
    </div>
{/if}

<style>
    .relatedPosts{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
    }

    .relatedPosts .cell{
        width: 90%;
        padding: 10px 5%;
        border-top: 1px solid #ccc;
        display: flex;
        flex-direction: row;
    }

    .relatedPosts .cell .thumb{
        width: 80px;
        height: 80px;
        background: #eee;
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        justify-items: center;
    }

    .relatedPosts .cell .contentArea{
        flex: 1;
        margin-inline-start: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .relatedPosts .cell .contentArea .title{
        font-size: 16px;
        color: #555;
    }

    .loading{
        place-self: center;
        align-self: center;
        justify-self: center;
        margin-top: 40px;
    }
</style>