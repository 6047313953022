<script lang="ts">
    import IconsWorker from "./IconsWorker.svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import DuButton from "./DuButton.svelte";
    import MyAccountStore from "../stores/MyAccountStore";

    export let isOpen = false;
    export let data;
    export let daysCount = 1;

    let isWaitingForPay = false;
    let checkingTimeInterval;

    const myAccountStore = MyAccountStore.state;


    const close = (e) => {
        isOpen = false;
    }

    const getFirstImage = () => {
        return ConnectionWorker.rootUrl + '/file/' + data.images.split(',')[0];
    }

    const openPayView = (e) => {

        let windowWidth = 380;
        let windowHeight = 500;

        const win = window;

        const y = win.top.outerHeight / 2 + win.top.screenY - ( windowHeight / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - ( windowWidth / 2);

        const rootUrl = ConnectionWorker.rootUrl.replace('/api', '');
        const fullUrl = rootUrl + `/payWebView?serial=${data.serial}&count=${daysCount}&type=service&userId=${$myAccountStore.userId}`;
        const newWindow = win.open(fullUrl, 'payNow', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${windowWidth}, height=${windowHeight}, top=${y}, left=${x}`);
        win.focus();

    }
</script>

{#if isOpen}
    <div class="singleOrderDialog">
        <div class="container">
            <div class="titleBar">
                <span>إكمال الطلب</span>
                <div class="closeButton" on:click={close}><IconsWorker icon="dismiss" color="red" width="25px"/></div>
            </div>
            <div class="cell">
                <div class="thumb" style="background-image: url({getFirstImage()})"></div>
                <div class="infoSide">
                    <div class="itemTitle">{data.title}</div>
                    <div class="daysCount">
                        <span>{daysCount}</span>
                        <span class="label">يوم</span>
                    </div>
                </div>
                <div class="total">{daysCount * data.price} <div class="currency">رس</div></div>
            </div>

            <div class="calculations">
                <div class="calculationCell">
                    <div class="theKey">المجموع الكلي بدون الضريبة</div>
                    <div class="theValue">{(data.price / (1 + data.vat)) * daysCount} <div class="currency">رس</div> </div>
                </div>
                <div class="calculationCell">
                    <div class="theKey">المجموع الكلي مع الضريبة ({data.vat}%)</div>
                    <div class="theValue">{daysCount * data.price} <div class="currency">رس</div></div>
                </div>
            </div>

            <div class="buttonsHolder" on:click={openPayView}>
                <DuButton text="الدفع" fullWidth="{true}" padding="10px" fontSize="20" isBold="{true}"/>
            </div>

        </div>
    </div>
{/if}

<style>
    .singleOrderDialog{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,0.5);
        top: 0;
        left: 0;
        position: fixed;
        z-index: 100;
    }

    .container{
        width: 30%;
        display: flex;
        flex-direction: column;
        min-height: 200px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }

    .titleBar{
        display: flex;
        flex-direction: row;
        width: available;
        color: #777;
        font-size: 17px;
        align-items: center;
        padding: 25px;
        border-bottom: 1px solid #eee;
    }

    .titleBar span{ flex: 1; }

    .closeButton{ cursor: pointer; }

    .currency{display: inline; font-size: 12px;}

    .cell{
        width: available;
        padding: 10px 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .cell .thumb{
        width: 50px;
        height: 50px;
        background-color: #eee;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
    }
    .cell .infoSide{
        display: flex;
        flex-direction: column;
        flex: 1;
        width: available;
        margin-inline-start: 10px;
    }
    .cell .infoSide .itemTitle{
        font-size: 18px;
        color: #444;
    }
    .cell .infoSide .daysCount{
        font-size: 16px;
        color: #555;
        display: flex;
        flex-direction: row;
    }
    .cell .infoSide .daysCount .label{
        margin-inline-start: 5px;
        color: #777;
    }
    .cell .total{
        font-size: 15px;
        font-weight: bold;
        color: green;
    }

    .calculations{
        display: flex;
        flex-direction: column;
        padding: 25px 25px;
    }

    .calculationCell{
        display: flex;
        flex-direction: row;
        width: available;
        margin-top: 5px;
        align-items: center;
        padding: 10px 0px;
        border-top: 1px solid #eee;
    }
    .calculationCell .theKey{
        flex: 1;
        font-size: 15px;
        color: #333;
    }
    .calculationCell .theValue{
        font-size: 19px;
        color: green;
    }

    .buttonsHolder{
        display: flex;
        padding: 25px;
    }
</style>