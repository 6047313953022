<script lang="ts">
    import MyAccountStore from "../stores/MyAccountStore";
    import BlogHome from "./Blog/BlogHome.svelte";
    const myAccountState = MyAccountStore.state;
</script>

<BlogHome />


<style>
    .homePage{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        width: 100%;
    }
</style>