<script lang="ts">
    import IconsWorker from "../Components/IconsWorker.svelte";

    export let data:Object;

    const getDirectionProps = () => {
        const props = {
            background: '#E96574',
            icon: 'arrowUpF',
        };
        if(data['direction'] === 'income') {
            props.background = '#71D16CFF';
            props.icon = 'arrowDownF';
        }

        return props;
    }
</script>

<div class="paymentHistoryCell">
    <div class="direction" style="background: {getDirectionProps().background};">
        <IconsWorker icon="{getDirectionProps().icon}" color="#fff" width="20px"/>
    </div>
    <div class="infoSide">
        <div class="serial">#{data['serial']}</div>
        <div class="amount">
            <div class="text">{data["amount"] / 100}</div>
            <div class="currency">ريال سعودي</div>
        </div>
    </div>
</div>


<style>
    .paymentHistoryCell{
        display: flex;
        flex-direction: row;
        width: available;
        padding: 15px;
        border-bottom: 1px solid #eee;
    }

    .paymentHistoryCell:last-of-type{border: 0;}

    .direction{
        background: #555;
        padding: 15px;
        border-radius: 100px;
        height: min-content;
    }

    .infoSide{
        display: flex;
        flex-direction: column;
        margin-inline-start: 10px;
    }

    .infoSide .serial{
        font-size: 14px;
        color: #555;
    }

    .amount{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
    }
    .amount .text{
        font-size: 15px;
        font-weight: bold;
    }
    .amount .currency{
        margin-inline-start: 2px;
        font-size: 13px;
        color: #555;
    }
</style>