<script lang="ts" context="module">
    // files example : { serial: xxxx, file: xxxx, extension: 'png' }
    export type DuFileUploaderFile = {
        serial: undefined,
        file: undefined,
        extension: undefined,
    }
</script>


<script lang="ts">
    import IconsWorker from "../IconsWorker.svelte";
    import DuFileUploaderCell, {data} from "./DuFileUploaderCell.svelte";
    import {onMount} from "svelte";

    let fileInput;

    export let acceptedExtensions = 'jpg, jpeg, png';
    export let emptyPlaceHolderText = '';
    export let emptyPlaceHolderIcon = 'camera';
    export let showType = 'grid'; /// grid OR list
    export let minCount:number = 1;
    export let maxCount:number = 10;
    export let isValid:boolean = false;

    export let files: DuFileUploaderFile[] = [];

    $: getAcceptedExtensions = () => {
        const acceptedExtensionsAsArray = acceptedExtensions.replace(/\s/g, '').split(',');
        acceptedExtensionsAsArray.forEach((value, index) => {
            acceptedExtensionsAsArray[index] = '.' + value;
        });
        return acceptedExtensionsAsArray.join(',');
    }

    $: checkFilesCount = () => {
        console.log('fd');
        if(files.length >= minCount && files.length <= maxCount) {
            isValid = true;
        } else {
            isValid = false;
        }
    }


    const onFileSelected =(e)=>{

        const _files = e.target.files;
        for (let i = 0; i < _files.length; i++) {
            let allowedToSelectFilesCount = ((files.length) < maxCount);
            if(allowedToSelectFilesCount) {
                const randId = Math.floor(Math.random() * 100000);
                files = [...files, {file: _files[i], id: randId,}];
            }
        }

    }

    const removeFile = (file) => {
        let _files = files;
        _files = _files.filter((_file) => {
            return _file !== file
        });
        files = _files;
    }

    onMount(() => {
        if(files.length > 0) {
            let _files = files;
            _files.forEach((file, index) => {
               if(file.serial !== undefined) {
                   _files[index].state = 'uploaded';
                   _files[index].id = Math.floor(Math.random() * 100000);
               }
            });
            files = _files;
        }
        setTimeout(() => {
            checkFilesCount();
        }, 200)
    });

</script>

<div class="DuPhotosUploader">
    {#if files.length === 0}
        <div class="noImagesPlaceHolder brightnessGlobalHover" on:click={()=>{fileInput.click();}}>
            <IconsWorker icon="{emptyPlaceHolderIcon}" width="50px" color="#777"/>
            <span>{emptyPlaceHolderText}</span>
        </div>
    {/if}

    <div class="filesList">
        {#each files as file (file.id)}
            <DuFileUploaderCell bind:data="{file}" {...$$props} onRemove="{() => removeFile(file)}"/>
        {/each}
        {#if files.length !== 0 && (files.length < maxCount)}
            <DuFileUploaderCell isPlaceHolder={true} {...$$props} onTap="{ () => {
               fileInput.click();
            }}"/>
        {/if}
    </div>

    <input style="display:none" type="file" accept="{getAcceptedExtensions}" on:change={(e)=>onFileSelected(e)} bind:this={fileInput} multiple="multiple"/>
</div>

<style>
    .DuPhotosUploader{
        width: available;
        display: flex;
        flex-direction: column;
    }

    .noImagesPlaceHolder{
        width: 200px;
        height: 200px;
        display: flex;
        flex-direction: column;
        place-self: center;
        align-self: center;
        place-content: center;
        place-items: center;
    }

    .noImagesPlaceHolder span{
        margin-top: 10px;
    }


    .filesList{
        flex-direction: row;
        display: grid;
        gap: 1px;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
    }
</style>