import 'svelte';
export class GlobalEventor {
    static sink(type, data) {
        document.dispatchEvent(new CustomEvent('globalEventor', {
            detail: {
                type: type,
                data: data,
            }
        }));
    }
    static listen(fun) {
        document.addEventListener('globalEventor', (e) => {
            const detail = e['detail'];
            fun({ type: detail['type'], data: detail['data'] });
        }, false);
    }
}
