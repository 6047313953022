<script lang="ts">
    import {onDestroy, onMount} from "svelte";
    export let options:Object;
    let defaultTextColor = '#222';
    let defaultTitleColor = '#333';
    let defaultSubTitleColor = '#555';
    let nodeRef;

    const close = () => {
        nodeRef.parentNode.removeChild(nodeRef);
        document.getElementById("app").classList.remove("globalBlur");
    }

    const cancel = () => {
        if(options.onCancel !== undefined) {
            options.onCancel();
        }
        close();
    }

    const onSelect = (event, option) => {
        if(option.onSelect !== undefined) {
            option.onSelect();
        }
        close();
    }

    onMount(() => {
        document.getElementById("app").classList.add("globalBlur");
    });

    $: getCellStyle = (option) => {
        return `
            color: ${option.color !== undefined ? option.color : defaultTextColor};
            font-weight: ${option.isBold !== undefined && option.isBold ? 'bold' : 'normal'};
        `;
    }
</script>

<div class="DuConfirmDialogComponent" bind:this={nodeRef}>
    <div class="dialogBox">
        {#if (options.title !== undefined) || (options.subTitle !== undefined)}
            <div class="dialogHeader">
                {#if (options.title !== undefined)}
                    <div class="title" style="color: {options.titleColor === undefined ? defaultTitleColor : options.titleColor}">{options.title}</div>
                {/if}
                {#if (options.subTitle !== undefined)}
                    <div class="subTitle" style="color: {options.subTitleColor === undefined ? defaultSubTitleColor : options.subTitleColor}">{options.subTitle}</div>
                {/if}
            </div>
        {/if}
        <div class="{options.options.length === 2 ? 'rowCellsArea' : 'columnCellsArea'}">
            {#each options.options as option}
                <div class="cell {options.options.length === 2 ? 'rowCell' : 'columnCell'}" on:click={(e) => onSelect(e, option) } style="{getCellStyle(option)}">
                    {option.title}
                </div>
            {/each}
        </div>
    </div>
    <div class="dismissArea" on:click={cancel}></div>
</div>


<style>
    .DuConfirmDialogComponent{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 200;
        background-color: rgba(0, 0, 0, 0.3);
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        place-content: center;
    }

    .DuConfirmDialogComponent .dismissArea{
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
        position: fixed;
        z-index: -1;
    }

    .dialogBox{
        width: auto;
        min-width: 300px;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        place-self: center;
        align-self: center;
        justify-self: center;
    }

    .dialogBox .dialogHeader{
        background-color: rgba(255, 255, 255, 0.8);
        padding: 20px;
        display: flex;
        flex-direction: column;
        place-items: center;
        place-content: center;
        text-align: center;
    }

    .dialogBox .dialogHeader .title{
        font-size: 16px;
        font-weight: bold;
    }

    .dialogBox .dialogHeader .subTitle{
        font-size: 14px;
        margin-top: 10px;
    }

    .dialogBox .cell{
        text-align: center;
        background-color: rgba(255, 255, 255 ,0.8);
        padding: 14px 10px;
        font-size: 15px;
        cursor: pointer;
        flex: 1;
        width: max-content;
    }

    .dialogBox .cell:hover{
        background-color: rgba(255, 255, 255 ,0.9);
    }

    .dialogBox .rowCell{
        width: auto;
        margin-top: 1px;
        margin-inline-end: 1px;
    }

    .dialogBox .rowCell:last-child{
        margin-inline-end: 0;
    }

    .dialogBox .columnCell{
        width: auto;
        margin-top: 1px;
    }

    .dialogBox .columnCell:last-child{
        margin-inline-end: 0;
    }

    .rowCellsArea{
        display: flex;
        flex-direction: row;
    }

    .columnCellsArea{
        display: flex;
        flex-direction: column;
    }
</style>