<script lang="ts">
    import {Loader} from "@googlemaps/js-api-loader";
    import MapOptions = google.maps.MapOptions;
    import googleMap = google.maps.Map;
    import {onMount} from "svelte";


    export let latitude;
    export let longitude;
    export let markerIcon = 'locationF';
    export let zoomLevel = 14;
    export let id = 'mapView';

    const loader = new Loader({
        apiKey: "AIzaSyCgc8-Oj1F95ZwsRc0ZME7bDOw_QdDX3t0",
        version: "weekly",
        libraries: ["places"]
    });

    let map:googleMap;
    let mapOptions:MapOptions = {
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        center: {
            lat: 0,
            lng: 0
        },
        zoom: zoomLevel,
    };


    const drawMap = () => {
        loader.load().then((google) => {
            map = new google.maps.Map(document.getElementById(id) as HTMLElement, mapOptions);
            setTimeout(() => {
                const locationLatLng = new google.maps.LatLng(latitude, longitude);
                new google.maps.Marker({
                    position: locationLatLng,
                    icon: new google.maps.MarkerImage(`/assets/icons/${markerIcon}.svg`, null, null, null, new google.maps.Size(40,40)),
                    map,
                });

                map.moveCamera({
                    center: locationLatLng,
                    zoom: zoomLevel,
                });
            }, 100);
        }).catch(e => {
            // do something
        });
    }

    onMount(() => {
        drawMap();
    });

</script>


<div class="mapView" id="{id}">

</div>


<style>
   .mapView{
       width: 100%;
       height: 100%;
   }
</style>