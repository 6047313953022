<script lang="ts">
    import IconsWorker from "./IconsWorker.svelte";

    export let rating:number = 3.5;
    export let maxRating:number = 5;
    export let allowToChange:boolean = true;
    export let emptyColor:string = '#555';
    export let fullColor:string = '#F1BF45';
    export let cellSize:number = 20;
    export let onChange:() => number;

    $: getCellStyle = (index) => {
        index = index +1;

        const options = {
            icon: 'starF',
            color: 'transparent',
        };

        const mod = index - rating;


        if(index <= rating) {
            options.color = fullColor;
        } else if(mod > 0 && mod < 1) {

            options.color = fullColor;
            options.icon = 'starHalfF';
        }

        return options;
    }

    const onDrag = (index) => {
        rating = index + 1;
    }
</script>

<div class="RatingBar" style="cursor: {allowToChange? 'pointer' : 'auto'}">
    {#each {length: parseInt(maxRating)} as _, index}
        <div class="cell" style="width: {cellSize+'px'};height: {cellSize+'px'};" on:mouseover={() => onDrag(index)} on:click={() => onDrag(index)}>
            <div class="underLay">
                <IconsWorker icon="starF" color="{emptyColor}" width="{cellSize}px"/>
            </div>
            <div class="overLay">
                <IconsWorker icon="{getCellStyle(index).icon}" color="{getCellStyle(index).color}" width="{cellSize}px"/>
            </div>
        </div>
    {/each}
</div>

<style>
.RatingBar{
    display: flex;
    flex-direction: row;
    place-content: center;
    place-items: center;
    direction: ltr;
}
.cell{
    display: flex;
    flex-direction: row;
    place-content: center;
    place-items: center;
    margin: 0 1px;
    position: relative;
}
.cell .underLay{
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
}
.cell .overLay{
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
}
</style>