<script lang="ts">
    export let length:number = 5;
    export let background:string = '#eee';
    export let fontSize:string = '17px';
    export let size:string = '50px';
    export let textColor:string = '#000';
    export let value:any = '';
    export let onDone: (allValues: string) => void;

    const getStyle = () => {
        return `
            background: ${background};
            font-size: ${fontSize};
            color: ${textColor};
            width: ${size};
            height: ${size};
        `;
    }

    const onKeyDown = (event:any, index:number) => {
        if(event.keyCode === 8) {
            if(index > 0) {
                setTimeout(() => {
                    document.getElementById('penInputField' + (index - 1).toString()).value = '';
                    document.getElementById('penInputField' + (index - 1).toString()).focus();
                });
            }
        } else {

            if(event.target.value === '' || event.target.value === ' ') {

                console.log('rr');
                console.log(event.target.value);

                document.getElementById('penInputField' + (index).toString()).value = '';
            } else {
                if (index + 1 < length) {
                    setTimeout(() => {
                        document.getElementById('penInputField' + (index + 1).toString()).focus();
                    }, 100);
                } else {
                    setTimeout(() => {
                        document.getElementById('penInputField' + (length - 1).toString()).blur();
                    }, 100);
                }
            }

        }
    }

    const onChange = (event:any, index:number) => {
        const newVal = event.target.value;
        if(newVal.length > 1) {
            newVal.split('').forEach((singleChar,i) => {
                console.log(i);
                document.getElementById('penInputField' + i.toString()).value = newVal[i];
            });
        } else {

        }

        let isDoneAll:boolean = true;
        let allValues:string = '';
        for(let i=0; i<length; i++) {
            const val:string = document.getElementById('penInputField' + i.toString()).value;
            allValues = allValues+val;
            if(val === '' || val === ' ') {
                isDoneAll = false;
            }
        }
        if(isDoneAll) {
            console.log('done');
            onDone(allValues);
        }
    }

    const onFocus = (event:any, index:number) => {
        let lastEmptyIndex:number = null;
        if(index > 0) {
            for(let i=index; i> 0; i--) {
                if(document.getElementById('penInputField' + (i-1).toString()).value === ''){
                    lastEmptyIndex = i-1;
                    //document.getElementById('penInputField' + (i-1).toString()).focus();
                }
            }

            if(lastEmptyIndex != null) {
                document.getElementById('penInputField' + (lastEmptyIndex).toString()).focus();
            }
        }

        if(index < length) {
            let lastFieldIndex: number = null;
            for (let i = 0; i < length-1; i++) {
                if (document.getElementById('penInputField' + (i).toString()).value !== '') {
                    lastFieldIndex = i;
                }
            }
            if (lastFieldIndex != null) {
                document.getElementById('penInputField' + (lastFieldIndex + 1).toString()).focus();
            }
        }
    }
</script>

<div class="pinCode" {...$$props}>
    {#each {length: length} as _, i}
        <div class="inputHolder" style="{getStyle()}">
            <input maxlength="{1}" on:input={(e) => onChange(e, i)} on:keyup={(e) => onKeyDown(e, i)} id="penInputField{i}" on:focusin={(e) => onFocus(e, i)}/>
        </div>
    {/each}
</div>

<style>
.pinCode{
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: space-between;
}
.inputHolder{
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
}
.inputHolder input{
    border: 0;
    place-self: center;
    background: transparent;
    text-align: center;
    width: 100%;
    padding: 0;
    margin: 0;
    line-height: 0;
    font-weight: bold;
}
</style>