<script lang="ts">
    import {onDestroy, onMount} from "svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import Component from "svelte/types/compiler/compile/Component";
    import IconsWorker from "./IconsWorker.svelte";

    export let data:Array<any> = [];
    export let isLoading:boolean = true;
    export let isLoadingMore:boolean = false;
    export let fragmentName:string;
    export let fragment:Component;
    export let fetchType:string = 'loadMore'; //
    export let getParams:Object = {}; //
    export let scrollContainer:Element = document.body;
    export let reverseScroll:boolean = false;
    export let pageNumber = 1;
    export let lastPage = 1;
    let slots = $$props.$$slots;


    const getData = () => {
        getParams['page'] = pageNumber;

        let url = '/';

        if(fragmentName === 'userContent') {
            url = '/content/getList';
        } else if(fragmentName === 'dashboardUser') {
            url = '/user/getList';
        } else if(fragmentName === 'dashboardTag') {
            url = '/hashtag/get';
        } else if(fragmentName === 'service') {
            url = '/services/getList';
        } else if(fragmentName === 'messagesInterface') {
            url = '/messages/getInterfaces';
        } else if(fragmentName === 'message') {
            url = '/messages/getMessages';
        }else if(fragmentName === 'paymentHistory') {
            url = '/payments/getHistory';
        }else if(fragmentName === 'QaA') {
            url = '/faq/getList';
        }else if(fragmentName === 'supportMessage') {
            url = '/support/getSupportMessages';
        }else if(fragmentName === 'fixedPages') {
            url = '/fixedPages/getList';
        }

        new ConnectionWorker().request(<any>{
            url: url,
            getParams: getParams,
            onLoad: (response) => {
                console.log(response);
                if(response.status) {
                    isLoading = false;
                    if(response.data.data.length > 0) {
                        lastPage = response.data.last_page;
                        if(reverseScroll) {
                            setTimeout(() => {
                                scrollContainer.scrollTo({
                                    top: 10000,
                                    behavior: 'smooth',
                                });
                                isLoadingMore = false;
                            }, 500);
                            let _data = response.data.data.reverse();
                            data = [..._data, ...data];
                        } else {
                            let _data = response.data.data;
                            data = [...data, ..._data];
                            isLoadingMore = false;
                        }
                    }
                }
                console.log(response);
            },
            onFail: (code) => {

            }
        });
    }

    const onScrollListener = (event) => {
        let element = event.target;
        let scrollCondition = scrollContainer.scrollHeight - scrollContainer.scrollTop <= (scrollContainer.clientHeight + 100);
        if(reverseScroll) {
            scrollCondition = (scrollContainer.scrollTop <= 100);
        }

        if (scrollCondition) {
            if(!isLoading && !isLoadingMore) {
                if(pageNumber < lastPage) {
                    pageNumber = pageNumber+1;
                    isLoadingMore = true;
                    getData();
                }
            }
        }
    }

    onMount(() => {
        setTimeout(() => {
            getData();
            if(fetchType === 'loadMore') {
                scrollContainer.addEventListener('scroll', onScrollListener);
            }
        }, 200);
    });

    onDestroy(() => {
        scrollContainer.removeEventListener('scroll', onScrollListener);
    })
</script>

<div {...$$props}>

    {#if (isLoadingMore && reverseScroll)}
        <div class="duListLoadingMore">
            <IconsWorker icon="loading" width="20px"/>
        </div>
    {/if}

    {#if (isLoading)}
        <div class="duListLoading">
            <IconsWorker icon="loading" width="25px"/>
        </div>
    {/if}

    {#if !isLoading}
        {#if data.length > 0}
            {#each data as row}
                {#if $$slots.fragment}
                    <slot name="fragment" data="{row}"/>
                {:else}
                    <svelte:component this="{fragment}" data="{row}"/>
                {/if}
            {/each}
        {:else}
            <slot name="noResults"></slot>
        {/if}
    {/if}

    {#if (isLoadingMore && !reverseScroll)}
        <div class="duListLoadingMore">
            <IconsWorker icon="loading" width="20px"/>
        </div>
    {/if}

</div>

<style>
    .duListLoading{
        width: auto;
        height: auto;
        place-self: center;
        align-self: center;
        justify-self: center;
        margin: 20px;
    }
    .duListLoadingMore{
        width: auto;
        height: auto;
        place-self: center;
        align-self: center;
        justify-self: center;
        margin: 20px;
    }
</style>