<script lang="ts">

    import MyAccountStore from "../../stores/MyAccountStore";
    import DuButton from "../../Components/DuButton.svelte";
    import DuConfirmDialog from "../../Components/DuConfirmDialog/DuConfirmDialog";
    import DuOptionMenu from "../../Components/DuOptionsMenu/DuOptionsMenu";
    import IconsWorker from "../../Components/IconsWorker.svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import {GlobalEventor} from "../../Utils/GlobalEventor";
    import DuListView from "../../Components/DuListView.svelte";
    import PaymentFragment from "../../Fragments/PaymentFragment.svelte";

    const state = MyAccountStore.state;

    let selectedPaymentMethod = '';

    let isRechargeBoxOpen = false;
    let selectedRechargeAmount = 10;

    const selectPaymentMethod = (event) => {

        const _options = [
            { title: 'البطاقة الإئتمانية', icon: 'creditCard' , onSelect: () => {
                    selectedPaymentMethod = 'creditCard';
                }},
            { title: 'STC باي', icon: 'bulletedListF', color: '#000', onSelect: () => {
                    selectedPaymentMethod = 'stcPay';
                }},
            { title: 'إدارة تدويناتي', icon: 'bulletedListF', color: '#000', onSelect: () => {
                    selectedPaymentMethod = 'applePay';
                }},
        ];


        new DuOptionMenu().create({
            event: event,
            onCancel: () => {

            },
            options: _options
        });
    }


    const onChange = (e) => {
        setTimeout(() => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
        }, 100);
    }

    const increment = () => {
        if(selectedRechargeAmount < 2000) {
            selectedRechargeAmount++;
        }
    }

    const decrement = () => {
        if(selectedRechargeAmount > 2) {
            selectedRechargeAmount--;
        }
    }


    const startPayListen = () => {
        GlobalEventor.listen((message) => {
            if(message.type === 'payWalletDone') {
                new MyAccountStore().fetchData();
            }
        });
    }


    const openPayView = (e) => {

        startPayListen();

        let windowWidth = 380;
        let windowHeight = 500;

        const win = window;

        const y = win.top.outerHeight / 2 + win.top.screenY - ( windowHeight / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - ( windowWidth / 2);

        const rootUrl = ConnectionWorker.rootUrl.replace('/api', '');
        const fullUrl = rootUrl + `/payWebView?amount=${selectedRechargeAmount}&type=wallet&userId=${$state.userId}`;
        const newWindow = win.open(fullUrl, 'payNow', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${windowWidth}, height=${windowHeight}, top=${y}, left=${x}`);
        win.focus();

    }


    const openPaymentWindow = (e) => {
        openPayView(e);
    }

    let scrollContainer;
    scrollContainer = document.body;

</script>

<div class="walletAndHistory">
    <div class="wallet">

        <div class="walletAndHistoryTitleBar">
            <IconsWorker icon="wallet2" width="18px;"/>
            <div class="theTitle">الرصيد الحالي</div>
        </div>

        {#if $state.data !== null}
            <div class="balanceMainContainer">
                <div class="BalanceSection">
                    <div class="currency">ر.س</div>
                    <div class="balance">{$state.data.balance / 100}</div>
                    <div class="label">
                        <span>الرصيد الحالية</span>
                    </div>
                </div>
                <div class="BalanceSection">
                    <div class="currency">ر.س</div>
                    <div class="balance">{$state.data.balance / 100}</div>
                    <div class="label">
                        <span>الرصيد المعلق</span>
                        <IconsWorker icon="timeF" width="14px;" color="#555"/>
                    </div>
                </div>
            </div>

            <div class="selectRechargeAmountBox">

                {#if !isRechargeBoxOpen}
                    <span on:click={() => isRechargeBoxOpen = true}><DuButton text=" شحن رصيد " icon="creditCardF" fontSize="18"/></span>
                {:else}
                    <div class="controllAndInputArea">
                        <button on:click={increment}><IconsWorker icon="plusF" color="#fff" width="22px" /></button>
                        <input bind:value={selectedRechargeAmount} type="number" on:change={onChange}/>
                        <button on:click={decrement}><IconsWorker icon="minusF" color="#fff" width="22px" /></button>
                    </div>
                    <span on:click={openPaymentWindow}><DuButton text=" شحن {selectedRechargeAmount} ريال سعودي  " icon="purchaseOutF"/></span>
                {/if}

            </div>

        {/if}
    </div>

    <div class="paymentHistory">
        <div class="walletAndHistoryTitleBar">
            <IconsWorker icon="time" width="18px;"/>
            <div class="theTitle">سجل العمليات</div>
        </div>
        <div class="paymentList">
            <DuListView class="list" fragmentName="paymentHistory" getParams="{{}}" fragment="{PaymentFragment}" scrollContainer={scrollContainer} />
        </div>
    </div>

</div>

<style>
    .walletAndHistory{
        width: available;
        display: flex;
        flex-direction: column;
    }

    .walletAndHistoryTitleBar{
        display: flex;
        flex-direction: row;
        padding: 0 10px;
        align-items: center;
    }

    .walletAndHistoryTitleBar .theTitle{
        font-size: 17px;
        margin-inline-start: 7px;
        margin-top: 1px;
    }

    .wallet{
        width: available;
        padding: 30px 20px;
        background: #fff;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 20px #ccc;
    }


    .balanceMainContainer{
        display: flex;
        flex-direction: row;
    }

    .BalanceSection{
        text-align: center;
        align-items: center;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: fit-content;
        place-self: center;
        flex: 1;
    }

    .BalanceSection .balance{
        width: 100%;
        font-size: 40px;
        font-weight: bold;
        color: green;
    }

    .BalanceSection .currency{
        font-size: 17px;
        font-weight: bold;
        color: #888;
        margin-inline-start: 50px;
    }

    .BalanceSection .label{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-items: center;
        font-size: 14px;
    }

    .BalanceSection .label span{margin-inline-end: 5px;}

    .chargeButton {
        place-self: center;
        margin-top: 50px;
    }

    .selectRechargeAmountBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        padding-top: 25px;
        border-top: 1px solid #eee;
    }

    .selectRechargeAmountBox .controllAndInputArea{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100px;
    }

    .selectRechargeAmountBox .controllAndInputArea input{
        border: 0;
        font-size: 25px;
        text-align: center;
        flex: 1;
        width: 80px;
    }

    .selectRechargeAmountBox .controllAndInputArea input::-webkit-outer-spin-button,
    .selectRechargeAmountBox .controllAndInputArea input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    .selectRechargeAmountBox .controllAndInputArea input[type=number] {
        -moz-appearance: textfield;
    }

    .selectRechargeAmountBox .controllAndInputArea button{
        padding: 8px;
        background: #000;
        border-radius: 100px;
        border: 0;
        cursor: pointer;
    }

    .selectRechargeAmountBox .controllAndInputArea button:hover{
        background: #555;
    }
    .selectRechargeAmountBox .controllAndInputArea button:active{
        background: #888;
    }

    .paymentHistory{
        width: available;
        padding: 30px 20px;
        background: #fff;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px #ccc;
        margin-top: 20px;
    }

    .paymentHistory .paymentList{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
</style>