<script lang="ts">
    import DuInput, {DuInputType} from "../Components/DuInput/DuInput.svelte";
    import DuInputPhone from "../Components/DuInput/DuInputPhone.svelte";
    import DuInputCountry from "../Components/DuInput/DuInputCountry.svelte";

    let val = '';
    let val2 = '';
    let val3 = ''

    let phone;

</script>


<div class="About">
    hello from about {val}

    <DuInput
        label="الاسم"
        bind:value="{val}"
        placeHolder="add name here"
        inputType="{DuInputType.password}"
        isRequired="{true}"
        isRequiredMessage="its required hi"
        minLength={10}
        minLengthMessage="mmmmmm"
        bind:this={phone}
    />

    <DuInputPhone
        label="الدولة"
        bind:value="{val2}"
        placeHolder="add name here"
        isRequired="{true}"
        isRequiredMessage="its required hi"
    />




    <button on:click={(e) => {
        console.log(phone.value);
        phone.validate();
    }}>change</button>


    <DuInputCountry
        label="الدولة"
        bind:value="{val3}"
        placeHolder="add name here"
        isRequired="{true}"
        isRequiredMessage="its required hi"
    />

</div>

<style>
    .About{
        width: 50%;
        padding: 100px;
        height: 500px;
        place-self: center;
        direction: rtl;
        background: #fff;
    }
</style>