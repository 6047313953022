<script lang="ts">
    import MainHeader from "../Components/MainHeader.svelte";
    import {onDestroy, onMount} from "svelte";
    import {useParams} from "svelte-navigator";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import MyAccountStore from "../stores/MyAccountStore";
    import IconsWorker from "../Components/IconsWorker.svelte";
    import DuListView from "../Components/DuListView.svelte";
    import MessageFragment from "../Fragments/MessageFragment.svelte";
    import DuButton from "../Components/DuButton.svelte";
    import DuConfirmDialog from "../Components/DuConfirmDialog/DuConfirmDialog";
    import TakeImagesDialog from "../Components/ReservationsImages/TakeImagesDialog.svelte";
    import moment from "moment/moment";
    import {GlobalEventor} from "../Utils/GlobalEventor";
    import DuAnimation from "../Utils/DuAnimation";

    const params = useParams();

    let data;
    let isLoading = true;
    let isSendingMessage = false;
    let message = '';
    let scrollContainer;
    scrollContainer = document.body;
    let interfaceId;
    let isTakeImagesDialogOpen = false;
    let takingImageAction = 'pickup';
    let isMakingAction = false;

    const myAccountState = MyAccountStore.state;

    const getInterfaceInfo = () => {
        isLoading = true;
        interfaceId = $params['interfaceId'];
        new ConnectionWorker().request(<any>{
            url: '/messages/getInterfaces',
            postParams: {
              id: interfaceId,
            },
            onLoad: (response) => {
                if(response['status']) {
                    data = response['data'];
                    isLoading = false;
                } else {
                    //todo : handle this error
                }
            },
            onFail: (code) => {

            }
        });
    }



    onMount(() => {
       interfaceId = $params['interfaceId'];
       // todo handle if there is no id;
        getInterfaceInfo();
        //let rec = new recorder(sourceNode, {});


    });

    const getUserThumb = () => {
        return data.user_info.thumb === null ? '../assets/images/thumb.png' : ConnectionWorker.rootUrl + '/file/' + data.user_info.thumb;
    }

    $: getActionButtonInfo = () => {
        let actionButtonPreferences = {
            background: '#F09035',
            icon: 'sendF'
        };

        if (!message.replace(/\s/g, '').length) {
            actionButtonPreferences.background = '#000';
            actionButtonPreferences.icon = 'cameraF';
        }

        return actionButtonPreferences;
    }

    const isIAmOwner = () => data['to_user'] === $myAccountState.data.id;

    const dismissOrder = () => {
        new DuConfirmDialog().create({
            title: 'هل تريد إلغاء هذا الطلب ؟',
            subTitle: 'لن تستطيع إكمال المحادثة في حال إلغاء هذا الطلب',
            titleColor: 'red',
            options: [
                {title: 'إلفاء', color: 'red', onSelect: () => { sendCancelRequest() }},
                {title: 'تراجع', onSelect: () => {}},
            ],
        });
    }

    const sendCancelRequest = () => {
        isMakingAction = true;
        new ConnectionWorker().request(<any>{
            url: '/order/cancel',
            postParams: {
                id: data['order_info']['id'],
            },
            onLoad: (response) => {
                console.log(response);
                if(response['status']) {
                    data['order_info']['status'] = 'canceled';
                    isMakingAction = false;
                } else {
                    //todo: handle this
                }
            },
            onFail: (code) => {
                isMakingAction = false;
                //todo: handle this
            }
        });
    }

    const acceptOrder = () => {
        isMakingAction = true;
        new ConnectionWorker().request(<any>{
            url: '/order/accept',
            postParams: {
                id: data['order_info']['id'],
            },
            onLoad: (response) => {
                isMakingAction = false;
                console.log(response);
                if(response['status']) {
                    data['order_info']['status'] = 'accepted';
                } else {
                    isMakingAction = false;
                    //todo: handle this
                }
            },
            onFail: (code) => {
                //todo: handle this
            }
        });
    }

    const startOrder = () => {
        isMakingAction = true;
        new ConnectionWorker().request(<any>{
            url: '/order/start',
            postParams: {
                id: data['order_info']['id'],
            },
            onLoad: (response) => {
                console.log(response);
                if(response['status']) {
                    data['order_info']['status'] = 'inProgress';
                    isMakingAction = false;
                } else {
                    //todo: handle this
                }
            },
            onFail: (code) => {
                isMakingAction = false;
                //todo: handle this
            }
        });
    }

    const openReturnOrderDialog = () => {
        new DuConfirmDialog().create({
            title: 'هل أنت في موقع الإرجاع ؟',
            subTitle: 'الموافقة تعني أنك في مكان الإرجاع و أن المالك أمامك الأن , سنقوم بإرسال إشعار للمالك للموافقة على الإرجاع',
            options: [
                {title: 'نعم أنا في الموقع', color: 'green', isBold: true, onSelect: () => { returnOrder() }},
                {title: 'تراجع', onSelect: () => {}},
            ],
        });
    }

    const returnOrder = () => {
        isMakingAction = true;
        new ConnectionWorker().request(<any>{
            url: '/order/askForReturn',
            postParams: {
                id: data['order_info']['id'],
            },
            onLoad: (response) => {
                console.log(response);
                if(response['status']) {
                    data['order_info']['status'] = 'waitingForReturn';
                    isMakingAction = false;
                } else {
                    //todo: handle this
                }
            },
            onFail: (code) => {
                //todo: handle this
                isMakingAction = false;
            }
        });
    }

    const ownerConfirmReturn = () => {
        isTakeImagesDialogOpen = true;
        takingImageAction = 'return';
    }


    onMount(() => {
        GlobalEventor.listen(async (message) => {
            if(message.type === 'newMessage') {

                if(message.data['order_info']) {
                    data['order_info'] = message.data['order_info'];
                }

                if(message.data.from === $myAccountState.data.id) {
                    if(["0", "1", "2"].includes(message.data.type.toString()))
                    await new Promise(resolve => setTimeout(resolve, 1500));
                }

                if(!document.getElementById("message_fragment_" + message.data.id)) {
                    const newElement: HTMLElement = document.createElement('div')
                    document.getElementsByClassName('list')[0].append(newElement);
                    new MessageFragment({
                        target: newElement,
                        props: {
                            data: message.data,
                            isNeedToSend: false,
                        },
                    });
                    scrollContainer.scrollTo({top: 10000, behavior: 'smooth',});
                }
            }
        });
        dateCountDownTimer = setInterval(() => {
            if(data['order_info']['status'] === 'inProgress') {
                dateCountDown = updateReturnDateCountDown();
            }
        }, 1000);
        setInterval(() => {
            if(data['order_info']['status'] === 'inProgress') {
                if(isLate) {
                    // 'returnTimer' is the is of timer
                    DuAnimation.animate({ id: 'returnTimer', animation: 'heartBeat', delay: 0, duration: 2000, looping: true });
                    DuAnimation.animate({ id: 'returnButton', animation: 'heartBeat', delay: 0, duration: 2000, looping: true });
                }
            }
        }, 2500);
    });

    onDestroy(() => {
        if(dateCountDownTimer)
            clearInterval(dateCountDownTimer);
    });

    const sendMessage = (type:string, content:string) => {
        /*
        isSendingMessage = true;
        new ConnectionWorker().request(<any>{
            url: '/messages/getInterfaces',
            postParams: {
                id: interfaceId,
            },
            onLoad: (response) => {
                if(response['status']) {
                    data = response['data'];
                    isLoading = false;
                } else {
                    //todo : handle this error
                }
            },
            onFail: (code) => {

            }
        });*/
    }

    const appendMessage = (type:string, _message:string, file:File = undefined) => {
        let _type = 0;
        if(type === 'image') {
            _type = 1;
        }
        const newElement:HTMLElement = document.createElement('div')
        document.getElementsByClassName('list')[0].append(newElement);
        new MessageFragment({
            target: newElement,
            props: {
                data: {
                    id: 'rand' + Date.now(),
                    type: _type,
                    interface: interfaceId,
                    created_at: moment().format(),
                    is_received: 0,
                    is_read: 0,
                    from: $myAccountState.data.id,
                    text: _message,
                },
                isNeedToSend: true,
                file: file,
            },
        });

        message = '';
    }

    const onTextMessageSubmit = (e) => {
        if (e.keyCode === 13) {
            if (message.replace(/\s/g, '').length) {
                appendMessage('text', message);
            }
        }
    }

    let  imageFileInput;
    const onFileSelected = (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        appendMessage('image', '', image);
    }

    const onSendButtonClick = () => {
        if (message.replace(/\s/g, '').length) {
            appendMessage('text', message);
        } else {
            imageFileInput.click();
        }
    }

    const isRecodingSupported = () => (navigator.mediaDevices || navigator.mediaDevices.getUserMedia) ? true : false;


    const startRecording = () => {
        if(isRecodingSupported()) {
            navigator.mediaDevices.getUserMedia({
                audio: true,
            });
        }
    }

    $:isExplicitMessageAllowed = () => {
        if(!isLoading && data !== null) {
            if (['waitingForStart', 'accepted', 'inProgress', 'waitingForReturn'].includes(data['order_info']['status'])){
                return true;
            }
        }

        return false;
    }

    $:getReturnDate = () => {
        const _date = new Date(data['order_info']['start_date']+ 'Z');
        return _date.getHours() + ':' + _date.getMinutes().toString().padStart(2, '0') + ' ' + _date.getDate() + '/' + (_date.getMonth() + 1) + '/' + _date.getFullYear();
    }

    let dateCountDownTimer;
    let dateCountDown = '';
    let isLate:boolean = false;
    $: updateReturnDateCountDown = () => {

        let asText = '';

        let dateFuture = new Date(data['order_info']['start_date']+ 'Z');
        dateFuture.setDate(dateFuture.getDate() + data['order_info']['days_count']);

        let dateNow = new Date();

        let seconds = Math.floor((dateFuture - (dateNow))/1000);
        let minutes = Math.floor(seconds/60);
        let hours = Math.floor(minutes/60);
        let days = Math.floor(hours/24);

        hours = hours-(days*24);
        minutes = minutes-(days*24*60)-(hours*60);
        seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

        isLate = (dateNow.getTime() > dateFuture.getTime());

        if(days > 0)
            asText = days.toString() + ' يوم و ';

        if(isLate) {
            if(days !== 0)
                asText = Math.abs(days).toString() + ' يوم و ';
        }

        if(hours > 0)
            asText = asText + hours.toString() + ':' + minutes.toString() + ':' + seconds.toString();

        return asText;

    }

</script>

<MainHeader />
<div class="mainPage mainContainer">

    {#if !isLoading}
        <input style="display:none" type="file" accept=".jpg, .jpeg, .png" on:change={(e)=>onFileSelected(e)} bind:this={imageFileInput} />
        <div class="messengerBody">
            <div class="messengerHeader">
                <div class="messengerHeaderBody">
                    <div class="thumb" style="background-image: url({getUserThumb()})"></div>
                    <div class="contentSide">
                        <div class="userName">{data.user_info.name}</div>
                        <div class="itemTitle">{data.order_info.item_info.title}</div>
                    </div>
                    <div class="statusSide">
                        {#if data['order_info']['status'] === 'init'}
                            {#if isIAmOwner()}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">المستأجر بإنتظار قبولك</div>
                            {:else}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">بإنتظار القبول من طرف المؤجر</div>
                            {/if}
                        {:else if data['order_info']['status'] === 'accepted'}
                            {#if isIAmOwner()}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">بإنتظار المستأجر للاستلام</div>
                            {:else}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">بإنتظارك للاستلام</div>
                            {/if}
                        {:else if data['order_info']['status'] === 'rejected'}
                            {#if isIAmOwner()}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">مرفوض بواسطتك</div>
                            {:else}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">مرفوض بواسطة المستأجر</div>
                            {/if}
                        {:else if data['order_info']['status'] === 'canceled'}
                            {#if isIAmOwner()}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">ملغي بواسطة المستأجر</div>
                            {:else}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">ملغي بواسطتك</div>
                            {/if}
                        {:else if data['order_info']['status'] === 'waitingForStart'}
                            {#if isIAmOwner()}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">بإنتظارك لبدأ الطلب</div>
                            {:else}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">بإنتظار بدأ الطلب من المؤجر</div>
                            {/if}
                        {:else if data['order_info']['status'] === 'inProgress'}
                            {#if isIAmOwner()}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">طلب مفتوح</div>
                            {:else}
                                <div class="status" style="background: rgba(67, 124, 229, 0.9)">طلب مفتوح</div>
                            {/if}
                        {:else if data['order_info']['status'] === 'accepted'}
                        {:else}
                            {data['order_info']['status']}
                        {/if}
                    </div>
                </div>
            </div>

            {#if isMakingAction}
                <div class="actionsButtons"><div class="actionLoading"><IconsWorker width="25px" icon="loading" /></div></div>
            {:else}
                <div class="actionsButtons">

                    {#if data['order_info']['status'] === 'inProgress'}
                        <div class="reservationTimer">
                            <div class="cell">
                                <div class="key">تاريخ الارجاع المتوقع:</div>
                                <div class="value">{getReturnDate()}</div>
                            </div>
                            <div class="reservationTimerGapper"></div>
                            <div class="cell" style="{isLate ? 'color:red' : ''}">
                                <div class="key">{isLate? 'تم تخطي وقت التسليم بـ:' : 'المتبقي من الوقت'}</div>
                                <div class="value" id="returnTimer" >{dateCountDown}</div>
                            </div>
                        </div>
                    {/if}

                    {#if isIAmOwner()}
                        {#if data['order_info']['status'] === 'init'}
                            <div class="button" on:click="{ acceptOrder }"><DuButton text="قبول الطلب" icon="checkF" background="#65C75D" /></div>
                            <div class="button" on:click="{ dismissOrder }"><DuButton text="رفض" icon="closeF" background="#FC5A70" /></div>
                        {:else if data['order_info']['status'] === 'waitingForStart'}

                            <!--<div class="button"><DuButton text="عرض موقع الاستلام في الخرائط" icon="mapF" background="#65C75D" /></div>-->
                            <div class="button" on:click="{ () => {
                                startOrder();
                            } }"><DuButton text="بدأ الحجز" icon="timer" /></div>

                        {:else if data['order_info']['status'] === 'waitingForReturn'}

                            <div class="button" on:click="{ () => {
                                ownerConfirmReturn();
                            } }"><DuButton text="تأكيد الإرجاع" icon="truckInDoneF" /></div>

                        {/if}
                    {:else}
                        {#if data['order_info']['status'] === 'init'}
                            <!--<div class="button"><DuButton text="عرض موقع الاستلام في الخرائط" icon="mapF" background="#65C75D" /></div>-->
                        {:else if data['order_info']['status'] === 'accepted'}

                            <!--<div class="button"><DuButton text="عرض موقع الاستلام في الخرائط" icon="mapF" background="#65C75D" /></div>-->
                            <div class="button" on:click="{ () => {
                                isTakeImagesDialogOpen = true;
                            } }"><DuButton text="تأكيد الاستلام" icon="checkF" background="#7EC46A" /></div>

                        {:else if data['order_info']['status'] === 'waitingForStart'}

                            <!--<div class="button"><DuButton text="عرض موقع الاستلام في الخرائط" icon="mapF" background="#65C75D" /></div>-->
                            <div class="button" on:click="{ () => {
                                isTakeImagesDialogOpen = true;
                            } }"><DuButton text="إضافة المزيد من الصور" icon="camera" background="#000" /></div>

                        {:else if data['order_info']['status'] === 'inProgress'}

                            <div class="button" id="returnButton" on:click="{ () => {
                                openReturnOrderDialog();
                            } }"><DuButton text="إرجاع المنتج" icon="truckInDoneF" /></div>

                        {/if}

                        {#if data['order_info']['status'] !== 'inProgress'}
                            <div class="expandedArea"></div>
                        {/if}

                        {#if ['init', 'accepted', 'waitingForStart'].includes(data['order_info']['status'])}
                            <div class="button" on:click="{ dismissOrder }"><DuButton text="إلغاء الطلب" icon="closeF" background="#FC5A70" /></div>
                        {/if}
                    {/if}
                </div>
            {/if}

            <div class="messagesArea">
                <DuListView class='list' fragmentName='message' fragment='{MessageFragment}' getParams="{{'interfaceId': interfaceId}}" scrollContainer={scrollContainer} reverseScroll={true}/>
            </div>

            <div class="writeMessageAria { !isExplicitMessageAllowed() ? 'disabledWriteMessage' : '' }">
                <div class="input">
                    <input type="text" bind:value="{message}" on:keypress={onTextMessageSubmit}/>
                </div>
                <button class="actionButton" style="background: {getActionButtonInfo().background}" on:click={onSendButtonClick}>
                    <IconsWorker icon="{getActionButtonInfo().icon}" width="25px" color="#fff"/>
                </button>
                <!--
                <button class="actionButton" style="background: #5383EC;" on:click={startRecording}>
                    <IconsWorker icon="mic" width="25px" color="#fff"/>
                </button>-->
            </div>

        </div>

        <TakeImagesDialog bind:isTakeImagesDialogOpen="{isTakeImagesDialogOpen}" orderId="{data['order_info']['id']}" takingImageAction="{takingImageAction}"/>
    {/if}
</div>

<style>
.messengerBody{
    width: 60%;
    align-self: center;
    background: #fff;
    border-radius: 12px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px #ccc;
}

.messengerHeader{
    width: available;
    background: #fff;
    position: sticky;
    border-radius: 12px 12px 0 0;
    top: 50px;
    z-index: 10;
}

.messengerHeader .messengerHeaderBody{
    width: available;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: row;
    background: rgba(240, 144, 53, 0.1);
}

.messengerHeader .thumb{
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
}

.messengerHeader .contentSide{
    width: available;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-inline-start: 10px;
}

.messengerHeader .contentSide .userName{
    font-size: 14px;
    color: #222;
}
.messengerHeader .contentSide .itemTitle{
    margin-top: 5px;
    font-size: 17px;
    color: #777;
}
.messengerHeader .statusSide{
    display: flex;
    flex-direction: column;
}
.messengerHeader .statusSide .status{
    font-size: 12px;
    font-weight: bold;
    border-radius: 100px;
    background: rgba(67, 124, 229, 0.9);
    color: #fff;
    padding: 7px 15px;
}

.actionsButtons{
    width: available;
    padding: 7px 10px;
    min-height: 37px;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: row;
    position: sticky;
    border-radius: 0 0 12px 12px;
    top: 140px;
    z-index: 10;
}

.actionsButtons .button{
    margin-inline-end: 10px;
    display: flex;
    width: fit-content;
}

.actionsButtons .expandedArea{
    width: available;
    flex: 1;
}
.actionsButtons .actionLoading{
    place-self: center;
    justify-self: center;
    align-self: center;
    width: 25px;
    height: 25px;
}


.writeMessageAria{
    position: sticky;
    bottom: 0;
    width: available;
    padding: 10px 15px;
    border-radius: 12px 12px 0 0;
    background: #fff;
    box-shadow: 0 0 10px #ccc;
    display: flex;
    flex-direction: row;
    height: 50px;
}

.disabledWriteMessage{pointer-events: none; cursor: pointer;filter: brightness(90%)}

.writeMessageAria .input{
    flex: 1;
    width: available;
    border-radius: 100px;
    border: 1px solid rgba(0,0,0, 0.5);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    font-size: 15px;
}
.writeMessageAria .input input{
    width: available;
    flex: 1;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
}

.writeMessageAria .actionButton{
    height: 45px;
    width: 45px;
    margin-inline-start: 10px;
    border-radius: 100px;
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.messagesArea{
    display: flex;
    flex-direction: column;
}

.reservationTimer{
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 15px;
}

.reservationTimerGapper{
    width: 5px;
    height: 5px;
}

.reservationTimer .cell{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reservationTimer .cell .value{
    margin-inline-start: 5px;
    font-weight: bold;
}
</style>