<script lang="ts">
    export let label:string = '';
    export let value:string = '';
    export let placeHolder:string = '';
    export let errorMessage:string = '';
    export let magnifier = 0;
    export let backgroundColor:string;

    $: getInputHolderStyle = () => {
        let _style:string = 'background:' + backgroundColor;
        if(errorMessage !== '') {
            _style = "border: 1px solid rgba(234, 56, 108, 0.2); background: rgba(234, 56, 108, 0.1);";
        }
        return _style;
    }
</script>

<div class="duInput" style="font-size: {15 + magnifier}px;">
    <div class="label" style="font-size: {14 + magnifier}px;">{label}</div>
    <div class="inputHolder" style={getInputHolderStyle()}>
        <slot name="input"></slot>
    </div>
        {#if errorMessage !== ''}
        <div class="errorMessage" style="font-size: {12 + magnifier}px;">
            <span>{errorMessage}</span>
        </div>
    {/if}
</div>

<style>
    .duInput{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .duInput .label{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: start;
    }

    .inputHolder{
        width: 90%;
        padding: 12px 5%;
        background: #eee;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        border-radius: 10px;
    }

    .errorMessage{
        width: 90%;
        background: rgba(234, 56, 108, 0.7);
        padding: 7px 5%;
        border-radius: 2px;
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        color: #fff;
        box-shadow: 0 0 5px #ccc;
    }
</style>
