<script lang="ts">
    import {link} from "svelte-navigator";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import {LocationHelper} from "../Utils/LocationHelper";
    import myAccountStore from "../stores/MyAccountStore";
    import MyAccountStore from "../stores/MyAccountStore";
    import IconsWorker from "../Components/IconsWorker.svelte";
    export let data:Object;

    const myAccountStore = MyAccountStore.state;

    const getFirstImageSerial = () => {
        const allImages = data['images'].split(',');
        return ConnectionWorker.rootUrl + '/file/' + allImages[0];
    }

    $: getDistanceIfThere = () => {
        if($myAccountStore.location.longitude !== null && $myAccountStore.location.latitude !== null) {
            if(data.longitude !== null && data.latitude !== null) {
                return LocationHelper.calcCrow(
                    data.latitude, data.longitude,
                    $myAccountStore.location.latitude, $myAccountStore.location.longitude
                ).toFixed(2)
            }
        }
        return '';
    }
</script>

<a class="serviceFragment" href="/service/{data.serial}/{data.title}" use:link>
    <div class="imageSide" style="background-image: url({getFirstImageSerial()})"></div>
    <div class="contentSide">
        <div class="title">{data.title}</div>
        {#if (getDistanceIfThere() !== '')}
            <div class="distance">
                <span>يبعد عنك</span>
                <span class="marked">{getDistanceIfThere()}</span>
                <span>كم</span>
            </div>

            <a class="serviceFragment" href="/profile/{data.serial}/{data.title}" use:link>
                <div class="userInfo">
                    <div class="userThumb"></div>
                    <div class="userInfoSide">
                        <div class="name">dsldksdlk</div>
                        <div class="rates">
                            <IconsWorker icon="starF" width="12px" color="#F1BE43" />
                            <IconsWorker icon="starF" width="12px" color="#F1BE43" />
                            <IconsWorker icon="starF" width="12px" color="#F1BE43" />
                            <IconsWorker icon="starF" width="12px" color="#F1BE43" />
                            <IconsWorker icon="starF" width="12px" color="#F1BE43" />
                        </div>
                    </div>
                </div>
            </a>

        {/if}
    </div>
    <div class="price">
        <span class="marked">{data.price}.00</span>
        <span>ريال لليوم</span>
    </div>
</a>


<style>
    .serviceFragment{
        display: flex;
        flex-direction: row;
        background: #fff;
        margin-top: 2px;
        overflow: hidden;
    }

    .serviceFragment:first-child{ border-radius: 12px 12px 0 0; }
    .serviceFragment:hover{
        filter: brightness(96%);
    }

    .imageSide{
        width: 120px;
        height: 120px;
        background-color: #555;
        background-position: center;
        background-size: cover;
    }

    .contentSide{
        flex: 1;
        margin-inline-start: 15px;
        width: available;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }

    .contentSide .title{
        width: available;
        font-size: 18px;
        color: #555;
    }

    .contentSide .distance{
        font-size: 13px;
        display: flex;
        flex-direction: row;
        background: rgba(55, 126, 34, 0.1);
        align-items: center;
        margin-top: 7px;
        padding: 5px 15px;
        border-radius: 100px;
        color: #222;
    }

    .contentSide .distance .marked{
        font-weight: bold;
        font-size: inherit;
        margin: 0 5px;
    }

    .price{
        width: available;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        color: #555;
        align-items: center;
        align-self: center;
        margin: 5px 15px;
    }
    .marked{
        font-size: 20px;
        font-weight: bold;
        color: green;
        margin-bottom: 5px;
    }

    .userInfo{
        display: flex;
        flex-direction: row;
        height: 30px;
        border-radius: 100px;
        overflow: hidden;
        padding: 5px 0;
        background-color: transparent;
    }

    .userInfo:hover{
        filter: brightness(20%);
    }

    .userInfo .userThumb{
        width: 30px;
        height: 30px;
        background-color: #eee;
        border-radius: 100px;
    }

    .userInfo .userInfoSide{
        margin-inline-start: 5px;
        display: flex;
        flex-direction: column;
    }

    .userInfo .userInfoSide .name{
        font-size: 13px;
        color: #555;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .userInfo .userInfoSide .name{
        font-size: 13px;
        color: #555;
    }
    .userInfo .userInfoSide .rates{
        font-size: 13px;
        color: #555;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
</style>