<script lang="ts">
    import { Loader } from '@googlemaps/js-api-loader';
    import MainHeader from "../Components/MainHeader.svelte";
    import ContentSideBar from "../Components/ContentSideBar.svelte";
    import {onMount} from "svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import {useParams, useNavigate, link} from "svelte-navigator";
    import IconsWorker from "../Components/IconsWorker.svelte";
    import MapOptions = google.maps.MapOptions;
    import googleMap = google.maps.Map;
    import DuButton from "../Components/DuButton.svelte";
    import ProblemReporterDialog from "../Components/ProblemReporter/ProblemReporterDialog.svelte";
    import SingleOrderDialog from "../Components/SingleOrderDialog.svelte";
    import {GlobalEventor} from "../Utils/GlobalEventor";
    import DuOptionMenu, {DuOptionMenuItem} from "../Components/DuOptionsMenu/DuOptionsMenu";
    import RatingBar from "../Components/RatingBar.svelte";


    const _useParams = useParams();
    const _useNavigate = useNavigate();

    let isLoadingData = true;
    let isReportBoxOpen = false;
    let data = {}

    let isOrderDialogOpen = false;

    let orderDays = 1;



    const loader = new Loader({
        apiKey: "AIzaSyCgc8-Oj1F95ZwsRc0ZME7bDOw_QdDX3t0",
        version: "weekly",
        libraries: ["places"]
    });
    let map:googleMap;
    let mapOptions:MapOptions = {
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        center: {
            lat: 0,
            lng: 0
        },
        zoom: 14,
    };

    const startPayListen = () => {
        GlobalEventor.listen((message) => {
            if(message.type === 'payOrderDone' && message.data.item_id.toString() === data['id'].toString()) {
                alert('pay done');
               isOrderDialogOpen = false;
               _useNavigate('/messenger/' + message.data.id.toString());
            }
        });
    }

    const loadData = () => {
        isLoadingData = true;
        new ConnectionWorker().request(<any>{
            url: '/services/get',
            getParams: {
                serial: $_useParams.serial,
            },
            onLoad: (response) => {
                console.log(response);
                isLoadingData = false;
                if(response['status']) {
                    data = response['data'];
                    drawMap();
                    startPayListen();
                } else {
                    // todo: show error or handle this false status
                }
            },
            onFail: (code) => {
                // todo: handle http errors
            }
        });
    }


    const drawMap = () => {
        loader.load().then((google) => {
            map = new google.maps.Map(document.getElementById("mapView") as HTMLElement, mapOptions);
            setTimeout(() => {
                const locationLatLng = new google.maps.LatLng(data.latitude, data.longitude);
                new google.maps.Marker({
                    position: locationLatLng,
                    icon: new google.maps.MarkerImage("/assets/icons/locationF.svg", null, null, null, new google.maps.Size(50,50)),
                    map,
                });

                map.moveCamera({
                    center: locationLatLng,
                    zoom: 13,
                });
            }, 100);
        }).catch(e => {
            // do something
        });
    }


    const getImagesLinksAsArray = () => {
        const links = [];
        data.images.split(',').forEach((serial) => {
            links.push(ConnectionWorker.rootUrl + '/file/' + serial);
        });
        return links;
    }


    const decdDays = (e) => {
        if(orderDays > 1)
            orderDays--;
    }

    const incdDays = (e) => {
        if(orderDays < 90)
            orderDays++;
    }

    const openReportBox = () => {
        isReportBoxOpen = true;
    }

    let isSendingFavorite = false;
    const addToFavorite = () => {
        isSendingFavorite = true;
        new ConnectionWorker().request(<any>{
            url: '/favorites/toggleService',
            postParams: {
                serial: data['serial'],
            },
            onLoad: (response) => {
                isSendingFavorite = false;
                console.log(response);
                if(response['status']) {
                    if(response['action'] === 'add') {
                        data['is_in_favorites'] = true;
                    } else {
                        data['is_in_favorites'] = false;
                    }
                } else {

                }
            },
            onFail: (code) => {
                isSendingFavorite = false;
                // todo show error notification
            }
        });
    }

    const share = (method:string) => {
        const url = window.location.href;
        const message = data['title'] + '\n' + url + '\n' + `(${data['price']}ريال لليوم) `;
        let shareUrl = '';

        if(method === 'facebook') {
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u==${encodeURIComponent(url)}`;
        } else if(method === 'whatsapp') {
            shareUrl = `https://wa.me?text=${encodeURIComponent(message)}`;
        } else if(method === 'twitter') {
            shareUrl = `https://wa.me?text=${encodeURIComponent(message)}`;
            shareUrl = `https://twitter.com/share?url=${url}`;
        }

        if(method === 'copy') {
            navigator.clipboard.writeText(shareUrl);
            // todo show notification here
        } else {
            window.open(shareUrl, '_blank').focus();
        }
    }

    const openShareBox = (e) => {
        new DuOptionMenu().create({
            event: e,
            options: [
                {title: 'مشاركة على فيسبوك', icon: 'faceBookF', color: '#555', onSelect: () => share('facebook')},
                {title: ' مشاركة على واتساب', icon: 'whatsappF', color: '#555', onSelect: () => share('whatsapp')},
                {title: 'مشاركة على تويتر', icon: 'twitterF', color: '#555', onSelect: () => share('twitter')},
                {title: 'نسخ الرابط', icon: 'copy2F', color: '#555', onSelect: () => share('copy')},
            ]
        });
    }

    onMount(() => {
        loadData();
    });
</script>

<MainHeader />

<div class="mainPage mainContainer">
    <div class="servicePage">
        <div class="contentSide">
            {#if isLoadingData}
                <div class="loading">
                    <IconsWorker icon="loading" width="30px"/>
                </div>
            {:else}
                <div class="titleBar">
                    <div class="title">{data.title}</div>
                    <div class="price">
                        <span class="marked">{data.price}</span>
                        <span>ريال لليوم</span>
                    </div>
                </div>

                <div class="description">{data.description}</div>

                <div class="images">
                    {#each getImagesLinksAsArray() as imageLink}
                        <div class="cell" style="background-image: url({imageLink})"></div>
                    {/each}
                </div>

                <div class="locationOnTheMap">
                    <div class="mapView" id="mapView"></div>
                </div>

                <a href="/profile/{data.user_info['id']}" class="userInfo" use:link>
                    <div class="thumb"></div>
                    <div class="infoSide">
                        <div class="nameAndType">
                            <div class="name">{data.user_info['name']}</div>
                            <div class="typeBudg">مؤسسة</div>
                        </div>
                        <div class="rating">
                            <RatingBar rating="{5}" cellSize="{12}"/>
                        </div>
                    </div>
                </a>


                <div class="buttonsHolder">
                    <button on:click={addToFavorite} class="{isSendingFavorite ? 'disabled' : ''}">
                        <div class="icon"><IconsWorker icon="{data['is_in_favorites'] ? 'loveF' : 'love'}" color="red" width="18px"/></div>
                        <span class="label">{data['is_in_favorites'] ? 'إزالة من المفضلة': 'إضافة للمفضلة'}</span>
                    </button>
                    <button on:click={openReportBox}>
                        <div class="icon"><IconsWorker icon="error" color="red" width="18px"/></div>
                        <span class="label">إبلاغ</span>
                    </button>
                    <button on:click={openShareBox}>
                        <div class="icon"><IconsWorker icon="share" color="red" width="18px"/></div>
                        <span class="label">مشاركة</span>
                    </button>
                </div>


                <div class="orderBox">
                    <div class="countButtons">
                        <button on:click={incdDays}><IconsWorker icon="plusF" width="20px" color="#fff"/></button>
                        <span class="daysCount">
                            <span>{orderDays}</span>
                            <span class="label">يوم</span>
                        </span>
                        <button on:click={decdDays}><IconsWorker icon="minusF" width="20px" color="#fff"/></button>
                    </div>
                    <div class="totalPrice">
                        <span>{data.price * orderDays}</span>
                        <span class="currency">ريال</span>
                    </div>
                    <DuButton text="اكمال الحجز" fontSize="19px" onTap="{() => {
                        isOrderDialogOpen = true;
                    }}"/>
                </div>

            {/if}
        </div>

        <div class="sideBar">
            <textarea>{JSON.stringify(data)}</textarea>
            <ContentSideBar />
        </div>
    </div>
</div>

{#if !isLoadingData}
    <SingleOrderDialog itemId="{data.id}" bind:isOpen="{isOrderDialogOpen}" data="{data}" daysCount="{orderDays}"/>
{/if}

{#if !isLoadingData && isReportBoxOpen}
    <ProblemReporterDialog itemId="{data.id}" itemType="service"/>
{/if}


<style>
.servicePage{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 150px;
}
.servicePage .sideBar{
    width: 30%;
    margin-inline-start: 2%;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
}

.servicePage .contentSide{
    width: available;
    flex: 1;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.servicePage .contentSide .loading{
    width: 200px;
    height: 200px;
    display: flex;
    place-items: center;
    place-content: center;
    place-self: center;
}

.servicePage .contentSide .titleBar{
    width: available;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: center;
}
.servicePage .contentSide .titleBar .title{
    width: available;
    flex: 1;
    font-size: 19px;
    color: #333;
}
.servicePage .contentSide .titleBar .price{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    color: #666;
}
.servicePage .contentSide .titleBar .marked{
    margin-bottom: 5px;
    color: green;
    font-size: 20px;
}

.servicePage .contentSide .description{
    width: available;
    padding: 20px;
    font-size: 17px;
    color: #555;
}

.images{
    width: available;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.images .cell:first-child:nth-last-child(1){
    height: auto;
    display: flex;
    width: 40%;
    margin: 0 30%;
}

.images .cell:first-child:nth-last-child(2), .images .cell:first-child:nth-last-child(2) ~ .cell{
    height: auto;
    display: flex;
    width: 48%;
}

.images .cell:first-child:nth-last-child(3), .images .cell:first-child:nth-last-child(3), .images .cell:first-child:nth-last-child(3) ~ .cell{
    height: auto;
    display: flex;
    width: 31%;
}


.images .cell{
    aspect-ratio: 1;
    background-color: #555;
    width: 23%;
    border-radius: 12px;
    margin: 1%;
    background-size: cover;
    background-position: center;
}

.locationOnTheMap{
    width: available;
    margin: 20px;
    background-color: #eee;
    height: 250px;
    border-radius: 12px;
    position: relative;
}

.locationOnTheMap .mapView{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.buttonsHolder{
    width: available;
    margin: 20px;
    overflow: hidden;
    border: 1px solid rgba(220, 113, 44, 0.5);
    border-radius: 100px;
    display: flex;
    flex-direction: row;
}

.buttonsHolder button{
    padding: 9px 20px;
    margin: 0;
    flex: 1;
    border-top: 0;
    border-bottom: 0;
    border-inline-start: 0;
    border-inline-end: 1px solid rgba(220, 113, 44, 0.5);
    background: rgba(220, 113, 44, 0.05);
    display: flex;
    flex-direction: row;
    place-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
}

.buttonsHolder .disabled{
    opacity: 1;
    filter: grayscale(100%);
    pointer-events: none;
}

.buttonsHolder button:last-child{
    border: 0;
}
.buttonsHolder button:hover{
    background: rgba(220, 113, 44, 0.2);
}
.buttonsHolder button:active{
    background: rgba(220, 113, 44, 0.1);
}
.buttonsHolder button .label{
    margin-inline-start: 7px;
    font-size: 16px;
    color: #444;
}

.orderBox{
    width: available;
    position: sticky;
    bottom: 15px;
    padding: 15px 20px;
    margin: 15px;
    box-shadow: 0 0px 15px #888;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
}

.orderBox .totalPrice{
    font-weight: bold;
    font-size: 25px;
    color: green;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
}

.orderBox .totalPrice .currency{
    margin-inline-start: 8px;
    font-size: 18px;
    color: #888;
}

.orderBox .countButtons{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.orderBox .countButtons button{
    padding: 7px;
    margin: 0;
    background: #222;
    border-radius: 100px;
    border: 0;
    cursor: pointer;
}

.orderBox .countButtons button:hover{
    opacity: 0.9;
}
.orderBox .countButtons button:active{
    opacity: 0.5;
}
.orderBox .countButtons .daysCount{
    margin: 0 10px;
    font-size: 18px;
    color: #000;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.orderBox .daysCount .label{
    margin-inline-start: 5px;
    color: #777;
}

.userInfo{
    width: available;
    padding: 10px 20px;
    margin: 10px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userInfo .thumb{
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: #ccc;
}

.userInfo .infoSide{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-inline-start: 10px;
}
.userInfo .infoSide .nameAndType{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.userInfo .infoSide .nameAndType .name{
    font-size: 14px;
    color: #555;
}
.userInfo .infoSide .nameAndType .typeBudg{
    font-size: 11px;
    color: #555;
    font-weight: bold;
    padding: 3px 10px;
    border-radius: 100px;
    margin-inline-start: 6px;
    background: rgba(240, 144, 53, 0.1);
}
.userInfo .infoSide .rating{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
}
</style>