<script lang="ts">
    import IconsWorker from "./IconsWorker.svelte";
    import {link, useLocation } from 'svelte-navigator';
    import {onMount} from "svelte";
    export let sideBarLinks:Array<any> = [];

    const params = useLocation();
    $: getCurrentPath = () => {
        return $params.pathname;
    }
    onMount(() => {
        setTimeout(() => {
            checkOpenCells();
        }, 100);
    });

    const checkOpenCells = () => {
        sideBarLinks.forEach((cell, index) => {
            if(cell.children !== undefined) {
                if(getCurrentPath().includes(cell.link)) {
                    sideBarLinks[index].isOpen = true;
                }
            }
        });
    }
</script>

<div class="sideBar">
    {#each sideBarLinks as cell}
        <a href="{cell.link}" class="cell {cell.link === getCurrentPath() ? 'currentTab' : ''}" on:click={(e) => {
            if(cell.children !== undefined) {
                cell.isOpen = !cell.isOpen;
                e.preventDefault();
            }
        }} use:link>
            <div class="icon">
                <IconsWorker icon="{cell.icon}{cell.link === getCurrentPath() ? 'F' : ''}" width="20px" />
            </div>
            <div class="title">{cell.title}</div>
            {#if cell.children !== undefined }
                <div class="rowMark">
                    <IconsWorker icon="{cell.isOpen ? 'arrowUpF' : 'arrowDownF'}" color="#777" width="15px"/>
                </div>
            {/if}
        </a>
        {#if cell.children !== undefined && cell.isOpen}
            {#each cell.children as cell}
                <a href="{cell.link}" class="cell {cell.link === getCurrentPath() ? 'currentTab' : ''} intend" use:link>
                    <div class="icon">
                        <IconsWorker icon="{cell.icon}{cell.link === getCurrentPath() ? 'F' : ''}" width="20px" />
                    </div>
                    <div class="title">{cell.title}</div>
                </a>
            {/each}
        {/if}
    {/each}
</div>

<style>
    .sideBar{
        width: 28%;
        height: min-content;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 0 5px #ccc;
        overflow: hidden;
        margin-inline-end: 17px;
    }
    .sideBar .cell{
        padding: 15px;
        width: available;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .sideBar .cell:hover{background: #f5f5f5}
    .sideBar .cell:active{background: #ccc}
    .sideBar .cell .icon{
        margin-inline-end: 10px;
    }
    .sideBar .cell .rowMark{
        margin-inline-start: 10px;
    }
    .sideBar .intend{
        padding-inline-start: 30px;
        background-color: #f5f5f5;
    }
    .sideBar .cell .title{
        width: available;
        flex: 1;
    }
    .currentTab{
        background: rgba(240, 144, 53, 0.2) !important;
        font-weight: bold;
    }
</style>