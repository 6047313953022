<script lang="ts">
    import MyAccountStore from "../../stores/MyAccountStore";
    import {useLocation, useParams} from "svelte-navigator";
    import {QueryWorker} from "../../Utils/QueryWorker";
    import {onMount} from "svelte";
    import MainHeader from "../../Components/MainHeader.svelte";
    import HorizontalMainTagsMenu from "../../Components/HorizontalMainTagsMenu.svelte";
    import DuListView from "../../Components/DuListView.svelte";
    import MainContentFragment from "../../Fragments/MainContentFragment.svelte";

    let scrollContainer;
    scrollContainer = document.body;
    let orderBy = 0;
    const myAccountState = MyAccountStore.state;

    const _userParams = useParams();
    const _useLocation = useLocation();
    let searchKeyWord = '';

    $: getGetParams = () => {

        const params = {};

        if($_userParams.id) {
            params['tag'] = $_userParams.id;
            document.title = "إنكيدو | " + $_userParams.title;
        }

        if(searchKeyWord !== '') {
            params['q'] = searchKeyWord;
            document.title = "إنكيدو | " + 'نتائج بحث عن :' + " " + params['q'];
        }

        return params
    };



    const onOrderChanged = (index) => {

    }

    let onSearchSubmit = (keyWord) => {
        QueryWorker({
            q: keyWord,
        });
        searchKeyWord = keyWord;
    };

    onMount(() => {
        document.title = "إنكيدو | " + "الرئيسية";
        const urlSearchParams = new URLSearchParams($_useLocation.search);
        if(urlSearchParams.get('q')) {
            searchKeyWord = decodeURIComponent(urlSearchParams.get('q'));
        }
    });
</script>

<MainHeader />
<div class="mainPage mainContainer">
    <div class="blogHome">
        <div class="contentSide">
            <HorizontalMainTagsMenu />
            <div class="mainContent">
                <DuListView class="list" fragmentName="userContent" fragment="{MainContentFragment}" getParams="{getGetParams()}" scrollContainer={scrollContainer} />
            </div>
        </div>
        <div class="sideBar">

        </div>
    </div>
</div>


<style>
    .blogHome{
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .contentSide{
        display: flex;
        flex-direction: column;
        width: 70%;
    }
    .sideBar{
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-inline-start: 10px;
        background: #fff;
        padding: 20px;
        width: available;
        border-radius: 12px;
    }
    .mainContent{
        margin-top: 5px;
    }

    @media only screen and (max-width: 600px) {
        .sideBar {
            display: none;
        }
        .contentSide{
            width: 100%;
        }
    }
</style>