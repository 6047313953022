<script lang="ts">
import IconsWorker from "../IconsWorker.svelte";
import DuButton from "../DuButton.svelte";
import ConnectionWorker from "../../Utils/ConnectionWorker";

export let itemId;
export let itemType;

let step = 0;
let isLoadingCats = false;
let cats = {};

const loadCats = () => {
    new ConnectionWorker().request(<any>{
        url: '/problemReport/getCats',
        getParams: {
            type: itemType,
        },
        onLoad: (response) => {
            if(response['status']) {
                cats = response['data'];
                step++;
            } else {
                // todo show error
            }
        },
        onFail: (code) => {
            // todo handle it
        }
    });
}

const alignIndicatorToTheEnd = () => {
    document.getElementById("progressIndicator").scrollLeft = -1000;
}

</script>

    <div class="problemReporterContainer">
        {#if step === 0}
            <div class="startUpInterface">
                <div class="icon">
                    <IconsWorker icon="info" width="100px"/>
                </div>
                <p class="sorryToSeeYouHere">نأسف بشدة لرويتك هنا</p>
                <p class="description">سنعمل على حل هذا الإعضال في أسرع وقتٍ ممكن , سنطرح عليك بعض الأسئلة لمساعدتنا على تصنيف بلاغك وحله بأسرع ما يمكن, أنقر على زر البدأ إذا كنت جاهزًا</p>
                <div class="startButton">
                    <DuButton text="استمرار" icon="checkF" isDisabled="{isLoadingCats}" isLoading="{isLoadingCats}" onTap="{ () => loadCats() }"/>
                </div>
            </div>
        {:else}
            <div class="optionsHolder">
                <div class="titleBar">
                    <div class="progressIndicator" id="progressIndicator">
                        <div class="indicatorCell">kdfldkf</div>
                        <div class="indicatorArrow"><IconsWorker icon="arrowLeftF" color="#555" width="14px" /></div>
                    </div>
                    <div class="closeButton"><IconsWorker icon="dismiss" color="red" width="30px" /></div>
                </div>
                <div class="cell"></div>
            </div>
        {/if}
    </div>

<style>
    .problemReporterContainer{
        width: 30%;
        display: flex;
        flex-direction: column;
        background: #fff;
        height: min-content;
        border-radius: 12px;
    }

    .startUpInterface{
        width: available;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 50px;
        place-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 12px;
    }

    .startUpInterface .sorryToSeeYouHere{
        font-size: 18px;
        color: #777;
        font-weight: bold;
    }

    .startUpInterface .description{
        font-size: 16px;
        color: #444;
        font-weight: bold;
    }

    .startUpInterface .icon{
        font-size: 16px;
        color: #444;
        font-weight: bold;
    }

    .startUpInterface .startButton{
        margin-top: 20px;
    }

    .titleBar{
        width: available;
        padding: 25px;
        display: flex;
        flex-direction: row;
    }

    .titleBar .closeButton{
        cursor: pointer;
        margin-inline-start: 20px;
    }

    .titleBar .progressIndicator{
        width: available;
        display: flex;
        flex-direction: row;
        flex: 1;
        overflow: hidden;
        align-items: center;
    }

    .titleBar .progressIndicator .indicatorCell{
        font-size: 14px;
        color: #555;
    }

    .titleBar .progressIndicator .indicatorArrow{
        margin: auto 10px;
    }

    .optionsHolder{
        width: available;
        border-radius: 12px;
    }
</style>