<script lang="ts">
import SwitchTabs from "../../../Components/Controllers/SwitchTabs.svelte";
import IconsWorker from "../../../Components/IconsWorker.svelte";
import DuInput, {DuInputType} from "../../../Components/DuInput/DuInput.svelte";
import DuButton from "../../../Components/DuButton.svelte";
import ConnectionWorker from "../../../Utils/ConnectionWorker";
import DuAnimation from "../../../Utils/DuAnimation";
import FixedNotifications from "../../../Components/DuNotifications/FixedNotifications";
import DuListView from "../../../Components/DuListView.svelte";
import DashboardQaAFragment from "../../../Fragments/DashboardQaAFragment.svelte";
import DuConfirmDialog from "../../../Components/DuConfirmDialog/DuConfirmDialog";
import DuSelectMenu from "../../../Components/DuInput/DuSelectMenu.svelte";

    let langIndex = 0;
    let isAddBoxOpen = false;
    let isSendingData = false;

    let question;
    let questionVal:string = '';
    let questionValid:boolean = false;

    let answer;
    let answerVal:string = '';
    let answerValid:boolean = false;

    let topic;
    let topicVal:string = '';
    let topicValid:boolean = false;

    let questionId = undefined;

    let scrollContainer;
    scrollContainer = document.body;

    const supportedLanguages = ['ar', 'en'];
    const getLangsCells = () => {
        return [
            { title: 'عربي', onTap: () => { langIndex = 0; } },
            { title: 'English', onTap: () => { langIndex = 1; } },
        ];
    }

    const typesArray =  [
        {'key': 'providers', 'ar': 'أسئلة البائعين', 'en': 'Providers Questions'},
        {'key': 'buyers', 'ar': 'أسئلة المشترين', 'en': 'Buyer Questions'},
    ];

    const getTypes = () => {
        const arr = [];
        typesArray.forEach((item) => {
            arr.push({text: item[supportedLanguages[langIndex]], value: item.key, icon: 'close'});
        });
        return arr;
    }

    const toggleAddBox = () => {
        DuAnimation.animate({id: 'addNew', animation: 'rotateIn', duration: 1000,})
        isAddBoxOpen = !isAddBoxOpen;
        questionId = undefined;
    }

    const openEdit = (data) => {
        toggleAddBox();
        questionId = data.id;
        questionVal = data.question;
        questionValid = true;
        answerVal = data.answer;
        answerValid = true;
        topicVal = data.topic;
        topicValid = true;
    }

    $: isFormValid = () => {
        return questionValid && answerValid && topicValid;
    }

    const addOrUpdate = () => {
        if(isFormValid()) {
            isSendingData = true;

            const postParams = {
                'question': questionVal,
                'answer': answerVal,
                'language': supportedLanguages[langIndex],
                'topic': topicVal,
            };
            if(questionId !== undefined) {
                postParams['id'] = questionId;
            }
            new ConnectionWorker().request(<any>{
                url: '/faq/add',
                postParams: postParams,
                onLoad: (response) => {
                    if (response['status']) {
                        questionVal = '';
                        answerVal = '';
                        new FixedNotifications().create({
                            title: questionId !== undefined ? 'تم إضافة السؤال بنجاح' : 'تم تحديث السؤال بنجاح',
                            icon: 'checkRoundedOut',
                            color: 'green',
                            removeAfter: 1000,
                        });
                        toggleAddBox();
                    } else {
                        new FixedNotifications().create({
                            title: 'حدث خطأء غير معروف',
                            subTitle: 'حاول مجددًا',
                            icon: 'error',
                            color: 'red',
                            removeAfter: 1000,
                        });
                    }
                    isSendingData = false;
                },
                onFail: (code) => {
                    new FixedNotifications().create({
                        title: 'حدث خطأء غير معروف',
                        subTitle: 'حاول مجددًا',
                        icon: 'error',
                        removeAfter: 1000,
                    });
                    isSendingData = false;
                }
            });
        }
    }



    $: getGetParams = () => {
        return {'language': supportedLanguages[langIndex]};
    }

</script>

<div class="faq">
    {#if supportedLanguages.length > 0}
        <div class="languageSelector">
            <div class="langsCellsContainer">
                <SwitchTabs  foreground="#555" cells={getLangsCells()} bind:selectedIndex={langIndex}/>
            </div>
            <button class="addButton brightnessGlobalHover" style="background: {isAddBoxOpen ? 'red' : ''}" id="addNew" on:click={toggleAddBox}><IconsWorker icon="{isAddBoxOpen ? 'closeF' : 'plusF'}" width="20px" color="#fff"/></button>
        </div>
    {/if}

    <div class="theBody">
        {#if isAddBoxOpen}
            <div class="addNewBox">

                <DuInput
                        inputType="{DuInputType.text}"
                        label="السؤال ({getLangsCells()[langIndex].title})"
                        bind:value="{questionVal}"
                        placeHolder=""
                        isRequired="{true}"
                        isRequiredMessage="قم بكتابة السؤال "
                        typeErrorMessage=""
                        bind:this={question}
                        bind:isValid={questionValid}
                />
                <div class="gapper"></div>
                <DuInput
                        inputType="{DuInputType.textArea}"
                        label="الإجابة ({getLangsCells()[langIndex].title})"
                        bind:value="{answerVal}"
                        placeHolder=""
                        isRequired="{true}"
                        isRequiredMessage="قم بكتابة الإجابة"
                        typeErrorMessage=""
                        bind:this={answer}
                        bind:isValid={answerValid}
                />
                <div class="gapper"></div>
                <DuSelectMenu
                        label="التصنيف"
                        placeHolder="قم بإختيار التصنيف"
                        bind:value="{topicVal}"
                        bind:this={topic}
                        bind:isValid={topicValid}
                        isRequired="{true}"
                        isRequiredMessage="رجاء قم بإختيار سبب المراسلة"
                        options={getTypes()}
                />
                <div class="actionButtonContainer">
                    <DuButton text="إضافة" onTap="{() => addOrUpdate()}" isDisabled="{!isFormValid() || isSendingData}" isLoading="{isSendingData}"/>
                </div>
                
            </div>
        {:else}

            {#key getGetParams()}
                <DuListView class="list" fragmentName="QaA" getParams="{getGetParams()}" scrollContainer={scrollContainer} let:data>
                    <DashboardQaAFragment slot="fragment" data="{data}" onEdit="{() => openEdit(data)}"/>
                </DuListView>
            {/key}

        {/if}
    </div>
</div>

<style>
.faq{
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    background: #fff;
}

.languageSelector{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
}

.languageSelector .langsCellsContainer{
    flex: 1;
}

.languageSelector .addButton{
    padding: 9px;
    margin-inline-start: 5px;
    margin-inline-end: 0;
    margin-bottom: 0;
    margin-top: 0;
    background: #F09035;
    border:0;
    border-radius: 100px;
    width: min-content;
    height: min-content;
    cursor: pointer;
}

.theBody{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.addNewBox{
    width: available;
    display: flex;
    flex-direction: column;
}
.addNewBox .gapper{
    width: 15px;
    height: 15px;
}

.actionButtonContainer{
    width: available;
    padding: 10px 0;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: end;
}
</style>