<script lang="ts">
    import DuInput, { DuInputType } from "../../Components/DuInput/DuInput.svelte";
    import DuSelectMenu from "../../Components/DuInput/DuSelectMenu.svelte";
    import DuButton from "../../Components/DuButton.svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import FixedNotifications from "../../Components/DuNotifications/FixedNotifications";
    import IconsWorker from "../../Components/IconsWorker.svelte";
    import MyAccountStore from "../../stores/MyAccountStore";


    const myAccountState = MyAccountStore.state;


    let name;
    let nameVal:string = '';
    let nameValid:boolean = false;

    let contactChannel;
    let contactChannelVal:string = '';
    let contactChannelValid:boolean = false;

    let topic;
    let topicVal:string = '';
    let topicValid:boolean = false;

    let message;
    let messageVal:string = '';
    let messageValid:boolean = false;


    let isSending = false;
    let isSent = false;

    $: isFormValid = () => {
        if($myAccountState.isLogin)
            return topicValid && messageValid;
        return nameValid && contactChannelValid && topicValid && messageValid;
    }

    const sendData = () => {
        if(!$myAccountState.isLogin) {
            name.validate();
            contactChannel.validate();
        }
        topic.validate();
        message.validate();


        const postParams = {};
        postParams['message'] = messageVal;
        postParams['topic'] = topicVal;

        if(!$myAccountState.isLogin) {
            postParams['contact_channel'] = contactChannelVal;
            postParams['name'] = nameVal;
        }

        if(isFormValid()) {
            isSending = true;
            new ConnectionWorker().request(<any>{
                url: '/support/sendMessage',
                postParams: postParams,
                onLoad: (response) => {
                    if(response['status']) {
                        isSent = true;
                    } else {
                        new FixedNotifications().create({
                            title: 'حدث خطأء غير معروف',
                            subTitle: 'حاول مجددًا',
                            icon: 'error',
                            color: 'red',
                            removeAfter: 2000,
                        });
                    }
                    isSending = false;
                },
                onFail: (code) => {
                    new FixedNotifications().create({
                        title: 'حدث خطأء غير معروف',
                        subTitle: 'حاول مجددًا',
                        icon: 'error',
                        color: 'red',
                        removeAfter: 2000,
                    });
                    isSending = false;
                }
            });
        }
    }

</script>


<div class="contactUs">

    {#if !isSent}
        <div class="theMainBody {isSending? 'disable' : ''}">
            <img src="/assets/images/SupportHeader.png" class="waterMark" />
            <div class="formHeader">
                <div class="theTitle">اتصل بنا</div>
                <div class="subTitle">
                    قم بملئ النموذج أدناه , وسنقوم بالرد عليك في أقرب وقت ممكن
                </div>
            </div>
            <div class="formBody">

                {#if (!$myAccountState.isLogin)}
                    <DuInput
                            inputType="{DuInputType.text}"
                            label="الاسم"
                            bind:value="{nameVal}"
                            placeHolder=""
                            isRequired="{true}"
                            isRequiredMessage="رجاء قم بكتابة اسمك"
                            typeErrorMessage="استعمل الأحرف العربية أو الاتينية فقط"
                            minLength="{2}"
                            minLengthMessage="رجاء قم بكتابة اسمك الحقيقيً"
                            maxLength="{20}"
                            maxLengthMessage="الاسم طويل بعض الشيءً"
                            bind:this={name}
                            bind:isValid={nameValid}
                    />
                    <div class="gapper"></div>
                    <DuInput
                            inputType="{DuInputType.text}"
                            label="البريد أو رقم الهاتف"
                            bind:value="{contactChannelVal}"
                            placeHolder=""
                            isRequired="{true}"
                            isRequiredMessage="رجاء قم بإدخال بريدك أو رقم هاتفك"
                            maxLength="{20}"
                            maxLengthMessage="البريد أو الهاتف طويل جدًا"
                            bind:this={contactChannel}
                            bind:isValid={contactChannelValid}
                    />
                    <div class="gapper"></div>
                {/if}
                <DuSelectMenu
                    label="سبب المراسلة"
                    placeHolder="قم بإختيار سبب المراسلة"
                    bind:value="{topicVal}"
                    bind:this={topic}
                    bind:isValid={topicValid}
                    isRequired="{true}"
                    isRequiredMessage="رجاء قم بإختيار سبب المراسلة"
                    options={[
                        {text: 'التبليغ عن مشكلة', value: 'issue', icon: 'close'},
                        {text: 'إقتراح', value: 'suggest', icon: 'close'},
                        {text: 'سبب اّخر', value: 'other', icon: 'close'},
                    ]}
                />
                <div class="gapper"></div>
                <DuInput
                        inputType="{DuInputType.textArea}"
                        label="الرسالة"
                        bind:value="{messageVal}"
                        placeHolder=""
                        isRequired="{true}"
                        isRequiredMessage="قم بتضمين رسالتك"
                        maxLength="{500}"
                        maxLengthMessage="الرسالة طويلة بعض الشيء , حاول تلخيصها حتى ٥٠٠ حرف"
                        bind:this={message}
                        bind:isValid={messageValid}
                />

                <div class="mainButton">
                    <DuButton text="إرسال" padding="10px 20px;" icon="send" onTap="{sendData}" isDisabled="{!isFormValid() || isSending}" isLoading="{isSending}"/>
                </div>

            </div>
        </div>
    {:else}
        <div class="afterSendBox">
            <img src="/assets/images/supportSent.png" class="image"/>
            <div class="info">لقد استلمنا رسالتك , سنقوم بالرد عليها في أقرب وقت بالأسفل ستجد رقم المتابعة الخاص برسالتك</div>
            <div class="serial">
                <input value="{34454545454}" readonly/>
                <IconsWorker icon="copy2" width="20px;" color="#555"/>
            </div>
            <DuButton text="عودة للرئيسية" icon="homeF" fontSize="17" isBold="{true}" padding="10px 20px;"/>
        </div>
    {/if}

</div>

<style>

    .contactUs{
        display: flex;
        flex-direction: column;
        width: max-content;
        flex: 1;
    }

    .contactUs .theMainBody{
        width: 50%;
        place-self: center;
        justify-self: center;
        align-self: center;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px #ccc;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 100px;
    }

    .contactUs .theMainBody .waterMark{
        position: absolute;
        top: -100px;
        left: -50px;
        z-index: -1;
        width: 200px;
    }

    .contactUs .theMainBody .formHeader{
        display: flex;
        flex-direction: column;
        padding: 30px 30px;
        background: rgba(240, 144, 53, 0.1);
        border-radius: 12px 12px 0 0;
    }

    .contactUs .theMainBody .formHeader .theTitle{
        font-size: 18px;
        color: #222;
        font-weight: bold;
    }

    .contactUs .theMainBody .formHeader .subTitle{
        font-size: 16px;
        color: #444;
        margin-top: 5px;
    }

    .contactUs .formBody{
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
    }

    .contactUs .formBody .gapper{
        padding: 10px;
    }


    .contactUs .formBody .mainButton{
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: end;
    }

    .disable{
        opacity: 0.6;
        pointer-events: none;
    }

    .afterSendBox{
        width: 300px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-items: center;
        text-align: center;
        align-self: center;
    }

    .afterSendBox .image{
        width: 150px;
    }


    .afterSendBox .info{
        font-size: 17px;
        font-weight: bold;
        color: #333;
        margin: 15px 0;
    }

    .afterSendBox .serial{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        padding: 9px;
        border: 1px solid #ccc;
        margin: 20px 0;
        border-radius: 12px;
    }

    .afterSendBox .serial input{
        border: 0;
        padding: 0;
        margin: 0;
        background: transparent;
    }

</style>