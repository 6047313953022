<script lang="ts">

    import MainHeader from "../../Components/MainHeader.svelte";
    import IconsWorker from "../../Components/IconsWorker.svelte";
    import {link, Route, Router} from "svelte-navigator";
    import ContactUs from "./ContactUs.svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import FixedNotifications from "../../Components/DuNotifications/FixedNotifications";
    import {onMount} from "svelte";
    import Faq from "../SupportCenter/Faq.svelte";
    
    let url;
    let isLoadingMediaData;
    let socailMediaData = [];

    const getSocialMedia = () => {
        isLoadingMediaData = true;
        new ConnectionWorker().request(<any>{
            url: '/socialMedia/getAll',
            onLoad: (respone) => {
                if(respone['status']) {
                    respone['data'].forEach((socialData) => {
                        if(socialData.value !== '') {
                            const obj = {
                                key: socialData.key,
                                value: socialData.value,
                                username: socialData.value.split('/')[socialData.value.split('/').length-1],
                            };
                            if(socialData.key === 'facebook') {
                                obj['icon'] = 'facebookF';
                            } else if(socialData.key === 'twitter') {
                                obj['icon'] = 'twitterF';
                            } else if(socialData.key === 'instagram') {
                                obj['icon'] = 'instagramF';
                            } else if(socialData.key === 'snapchat') {
                                obj['icon'] = 'snapchatF';
                            } else if(socialData.key === 'phone') {
                                obj['icon'] = 'phoneF';
                            }
                            socailMediaData = [...socailMediaData, obj];
                        }
                    });
                } else {
                    setTimeout(() => getSocialMedia, 2000);
                }
                isLoadingMediaData = false;
            },
            onFail: (code) => {
                setTimeout(() => getSocialMedia, 2000);
            }
        });
    }

    onMount(() => {
        getSocialMedia();
    });
    
</script>

<div class="SupportCenter">
    <MainHeader />
    <div class="mainContainer mainPage">


        <div class="supportBody">

            <Router url="{url}">
                <Route path="/" primary="{true}">

                    <div class="socialMedia">
                        <div class="title">حساباتنا الرسمية</div>
                        {#each socailMediaData as socialItem}
                            <a href="{socialItem.value}" target="_blank" class="cell"><IconsWorker icon="{socialItem.icon}" color="#555" width="20px"/> <span>{socialItem.username}</span></a>
                        {/each}
                    </div>
                    <div class="mainButtons">
                        <a href="/supportCenter/contactUs" class="cell" use:link>
                            <IconsWorker icon="onlineSupport" color="#555" width="50px"/>
                            <span>اتصل بنا</span>
                        </a>
                        <a href="/supportCenter/fAq" class="cell" use:link>
                            <IconsWorker icon="faq" color="#555" width="50px"/>
                            <span>الأسئلة الشائعة</span>
                        </a>
                    </div>

                </Route>
                <Route path="/contactUs" component="{ContactUs}" primary="{false}"/>
                <Route path="/fAq" component="{Faq}" primary="{false}"/>
            </Router>



        </div>

    </div>
</div>

<style>
.SupportCenter{
    display: flex;
    flex-direction: column;
}

.cover{
    width: available;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cover .infoSide{
    flex: 1;
    margin-top: 15px;
}

.cover .infoSide .title{
    font-size: 20px;
    font-weight: bold;
    color: #F09035;
}

.cover img{ width: 200px;}

.supportBody{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.socialMedia{
    width: 30%;
    background: #fff;
    border-radius: 12px;
}

.socialMedia .title{
    width: available;
    padding: 20px;
    border-bottom: 1px solid #eee;
    font-size: 17px;
}

.socialMedia .cell{
    width: available;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.socialMedia .cell span{
    flex: 1;
    margin-inline-start: 7px;
    font-size: 17px;
    font-weight: bold;
}

.socialMedia .cell:hover{opacity: 0.7;}

.mainButtons{
    flex: 1;
    margin-inline-start: 10px;
    border-radius: 12px;
    padding: 20px;
    background: #fff;
    display: flex;
    flex-direction: row;
}

.mainButtons .cell:first-of-type{border: 0;}
.mainButtons .cell{
    flex: 1;
    border-inline-start: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mainButtons .cell img{
    width: 80px;
}
.mainButtons .cell span{
    font-size: 20px;
    margin-top: 10px;
    font-weight: bold;
    color: #555;
}
</style>