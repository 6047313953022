<script lang="ts">
    import MainHeader from "../../Components/MainHeader.svelte";
    import { Route, Router} from "svelte-navigator";
    import Content from "./Content.svelte";
    import IconsWorker from "../../Components/IconsWorker.svelte";
    import DashboardSideBar from "../../Components/DashboardSideBar.svelte";
    import Settings from "./Settings.svelte";
    import Wallet from "./Wallet.svelte";
    import Favorites from "./Favorites.svelte";
    export let url = "";

    const sideBarLinks = () => {
        return [
            {title: 'تدويناتي', icon: 'pen', link: '/myAccount/content',},
            {title: 'إعدادات حسابي', icon: 'user', link: '/myAccount/settings',},
            {title: 'الإحصائيات', icon: 'chartsBars', link: '/myAccount/statistics',},
        ];
    }
</script>


<MainHeader />
<div class="mainPage mainContainer">
    <div class="myAccount">
        <DashboardSideBar sideBarLinks="{sideBarLinks()}"/>
        <div class="content">
            <Router url="{url}">
                <Route path="/content" component="{Content}" primary="{false}"/>
                <Route path="/settings" component="{Settings}" primary="{false}"/>
                <Route path="/wallet" component="{Wallet}" primary="{false}"/>
                <Route path="/favorites" component="{Favorites}" primary="{false}"/>
            </Router>
        </div>
    </div>
</div>


<style>
    .myAccount{
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 50px;
    }
    .myAccount .content{
        width: available;
        margin-inline-start: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
</style>