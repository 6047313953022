<script lang="ts">
    import IconsWorker from "../Components/IconsWorker.svelte";
    import { link } from 'svelte-navigator';
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import MyAccountStore from "../stores/MyAccountStore";
    import {onDestroy, onMount} from "svelte";
    import {GlobalEventor} from "../Utils/GlobalEventor";
    export let data;

    const myAccountState = MyAccountStore.state;

    const getFirstImage = () => {
        return ConnectionWorker.rootUrl + '/file/' + data.order_info.item_info.images.split(',')[0];
    }

    const getLastMessageThumb = () => {
        return data.user_info.thumb === null ? '../assets/images/thumb.png' : ConnectionWorker.rootUrl + '/file/' + data.user_info.thumb;
    }

    $:getStatusInfo = () => {
        const status = data.order_info.status;
        const statusInfo = {
            icon: 'close',
            color: 'green',
            text: status,
            message: status,
        };

        if(data['last_message']['text'] !== '') {
            statusInfo.message = data['last_message']['text'];
        }

        if(status === 'init') {
            statusInfo.text = 'بإنتظار الموافقة';
            statusInfo.color = 'rgba(67, 124, 229, 0.1)';
            if(data['last_message']['from'] === $myAccountState.data.id) {
                statusInfo.message = 'بإنتظار موافقة المؤجر على طلبك';
            } else {
                statusInfo.message = 'المستأجر بإنتظار موافقتك';
            }
        } else if(status === 'accepted') {
            statusInfo.text = 'جاهز للإستلام';
            statusInfo.color = 'rgba(95, 198, 80, 0.2)';
            if(data['last_message']['type'] === 7) {
                if (data['last_message']['from'] === $myAccountState.data.id) {
                    statusInfo.message = 'بإنتظار المستأجر لإستلام الطلب';
                } else {
                    statusInfo.message = 'المؤجر في إنتظارك للاستلام , أنقر لمزيد من التفاصيل';
                }
            }
        } else if(status === 'ended') {
            statusInfo.text = 'طلب مكتمل';
            statusInfo.color = 'rgba(240, 144, 53, 0.2)';
            statusInfo.message = 'تم إكمال الطلب بنجاح';
        }  else if(status === 'canceled') {
            statusInfo.text = 'طلب ملغي';
            statusInfo.color = 'rgba(240, 144, 53, 0.2)';
            if(data['last_message']['from'] === $myAccountState.data.id) {
                statusInfo.message = 'تم إلغاء هذا الطلب بواستطك';
            } else {
                statusInfo.message = 'تم إلغاء الطلب بواسطة المستأجر';
            }
        }   else if(status === 'rejected') {
            statusInfo.text = 'طلب مرفوض';
            statusInfo.color = 'rgba(240, 144, 53, 0.2)';
            if(data['last_message']['from'] === $myAccountState.data.id) {
                statusInfo.message = 'تم رفض الطلب بواسطتك';
            } else {
                statusInfo.message = 'تم رفض الطلب بواسطة المؤجر';
            }
        } else if(status === 'waitingForStart') {
            statusInfo.text = 'بإنتظار تأكيد البدأ';
            statusInfo.color = 'rgba(240, 144, 53, 0.2)';
        } else if(status === 'inProgress') {
            statusInfo.text = 'طلب مفتوح';
            statusInfo.color = 'rgba(240, 144, 53, 0.2)';
            if(data['last_message']['type'] === 9) {
                if(data['last_message']['from'] === $myAccountState.data.id) {
                    statusInfo.message = 'تم تسليم الطلب للمستأجر';
                } else {
                    statusInfo.message = 'تم استلام الطلب من المؤجر';
                }
            }
        } else if(status === 'waitingForReturn') {
            statusInfo.text = 'بإنتظار الإرجاع';
            statusInfo.color = 'rgba(240, 144, 53, 0.2)';
            if(data['last_message']['type'] === 10) {
                if(data['last_message']['from'] === $myAccountState.data.id) {
                    statusInfo.message = 'بإنتظار المؤجر لتأكيد الإرجاع';
                } else {
                    statusInfo.message = 'المستأجر بإنتظارك لتأكيد الإرجاع';
                }
            }
        }
        return statusInfo;
    }


    let dateCountDown = '';
    let dateCountDownTimer;
    let isLate:boolean = false;
    $: updateReturnDateCountDown = () => {

        let asText = '';

        let dateFuture = new Date(data['order_info']['start_date']+ 'Z');
        dateFuture.setDate(dateFuture.getDate() + data['order_info']['days_count']);

        let dateNow = new Date();

        let seconds = Math.floor((dateFuture - (dateNow))/1000);
        let minutes = Math.floor(seconds/60);
        let hours = Math.floor(minutes/60);
        let days = Math.floor(hours/24);

        hours = hours-(days*24);
        minutes = minutes-(days*24*60)-(hours*60);
        seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

        isLate = (dateNow.getTime() > dateFuture.getTime());

        if(days > 0)
            asText = days.toString() + ' يوم و ';

        if(isLate) {
            if(days !== 0)
                asText = Math.abs(days).toString() + ' يوم و ';
        }

        if(hours > 0)
            asText = asText + hours.toString() + ':' + minutes.toString() + ':' + seconds.toString();

        return asText;

    }


    onMount(() => {
        GlobalEventor.listen((message) => {
            if(message.type === 'messageSeen' && message.data.interface.toString() === data['id'].toString()) {
                data['last_message']['is_read'] = 1;
            } if(message.type === 'newMessage' && message.data.interface.toString() === data['id'].toString()) {
                let oldMessageData = data['last_message'];
                console.log(oldMessageData);
                Object.keys(message.data).forEach((key) => {
                    oldMessageData[key] = message.data[key];
                });
                console.log(oldMessageData);
                data['last_message'] = oldMessageData;
            }
        });

        dateCountDownTimer = setInterval(() => {
            if(data['order_info']['status'] === 'inProgress' || data['order_info']['status'] === 'waitingForReturn') {
                dateCountDown = updateReturnDateCountDown();
            }
        }, 1000);

    });

    onDestroy(() => {
        if(dateCountDownTimer)
            clearInterval(dateCountDownTimer);
    });
</script>


{JSON.stringify(data)}
<a class="messagesInterface" href="/messenger/{data.id}" use:link>
    <div class="itemImage" style='background-image: url({getFirstImage()})'></div>
    <div class="infoSide">
        <div class="itemTitleAndState">
            <div class="itemTitle">{data.order_info.item_info.title}</div>
            <div class="status" style="background: {getStatusInfo().color}">{getStatusInfo().text}</div>
        </div>
        <div class="userInfoAndThumb">
            <div class="userThumb" style='background-image: url({getLastMessageThumb()})'></div>
            <div class="userName">{data.user_info.name}</div>
        </div>
        <div class="messageAndMessageStatus">
            {#if data['last_message']['from'] !== $myAccountState.data.id && data['last_message']['is_read'] === 0}
                <div class="budg"></div>
            {/if}
            <div class="message">
                {#if data['last_message']['type'] === 1}
                    <div class="messageType">
                        <IconsWorker icon="cameraF" width="20px" color="#fff"/>
                    </div>
                {:else if data['last_message']['type'] === 8}
                    <div class="messageType">
                        <IconsWorker icon="photosF" width="20px" color="#fff"/>
                    </div>
                {:else}
                    <span>{getStatusInfo().message}</span>
                {/if}
            </div>
            {#if data['order_info']['status'] === 'inProgress' || data['order_info']['status'] === 'waitingForReturn'}
                <div class="countDownTimer">
                    <div class="timer" style="color:{isLate ? 'red' : 'green'}">{dateCountDown}</div>
                    <div class="icon"><IconsWorker icon="timerF" width="15px" color="{isLate ? 'red' : 'green'}"/></div>
                </div>
            {/if}
        </div>
    </div>
</a>

<!--
<textarea>
    {JSON.stringify(data)}
</>-->

<style>
.messagesInterface{
    width: available;
    padding: 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
    height: 90px;
    position: relative;
}

.messagesInterface:last-child{
    border-bottom: 0;
}

.messagesInterface .itemImage{
    width: 80px;
    height: 90px;
    border-radius: 12px;
    background-color: #eee;
    background-size: cover;
    background-position: center;
}

.messagesInterface .infoSide{
    flex: 1;
    width: available;
    margin-inline-start: 10px;
}

.messagesInterface .infoSide .itemTitleAndState{
    width: available;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.messagesInterface .infoSide .itemTitleAndState .itemTitle{
    width: available;
    font-size: 14px;
    color: #777;
    flex: 1;
}

.messagesInterface .infoSide .itemTitleAndState .status{
    padding: 5px 12px;
    background-color: #eee;
    border-radius: 100px;
    font-size: 14px;
    font-weight: bold;
}
.messagesInterface .infoSide .userInfoAndThumb{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.messagesInterface .infoSide .userInfoAndThumb .userThumb{
    width: 25px;
    height: 25px;
    background-color: #eee;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
}
.messagesInterface .infoSide .userInfoAndThumb .userName{
    font-size: 14px;
    color: #444;
    margin-inline-start: 5px;
}
.messagesInterface .infoSide .messageAndMessageStatus{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-inline-start: 30px;
}

.messagesInterface .infoSide .messageAndMessageStatus .countDownTimer{
    margin-inline-start: 10px;
    padding: 5px 12px;
    border-radius: 100px;
    border: 2px solid green;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
}

.messagesInterface .infoSide .messageAndMessageStatus .countDownTimer .icon{
    margin-inline-start: 5px;
    display: flex;
}

.messagesInterface .infoSide .messageAndMessageStatus .message{
    display: flex;
    width: fit-content;
    flex-direction: column;
    font-size: 17px;
    color: #222;
    flex: 1;
}

.messagesInterface .infoSide .messageAndMessageStatus .budg{
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background: red;
    margin-inline-end: 5px;
    opacity: 0.8;
}

.messageType{
    width: fit-content;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    background: rgba(240, 144, 53, 0.8);
    align-items: center;
    border-radius: 100px;
}
</style>