<script lang="ts">
    import DuButton from './DuButton.svelte'
    import {Link, link, useNavigate} from "svelte-navigator";
    import MyAccountStore from "../stores/MyAccountStore";
    import {onMount} from "svelte";
    import DuOptionMenu from "./DuOptionsMenu/DuOptionsMenu";
    import DuConfirmDialog from "./DuConfirmDialog/DuConfirmDialog";
    import IconsWorker from "./IconsWorker.svelte";


    const navigate = useNavigate();
    const  goToHome = () => {
        navigate('/home');
    }

    const myAccountState = MyAccountStore.state;

    const onAccountInfoTap = (event) => {
        const _options = [
            { title: 'الصفحة الشخصية', icon: 'user', onSelect: () => {
                navigate('/profile/' + $myAccountState.data.serial);
            }},
            { title: 'إدارة تدويناتي', icon: 'bulletedListF', color: '#000', onSelect: () => {
                    navigate('/myAccount/content');
            }},
            { title: 'إعدادات حسابي', icon: 'settings' , onSelect: () => {
                navigate('/myAccount/settings');
            }},
        ];

        if($myAccountState.data.type === 'admin') {
            _options.push({ title: 'لوحة التحكم', icon: 'dashboard', color: '#000', onSelect: () => {
                navigate('/dashboard');
            }});
        }

        _options.push({ title: 'مركز المساعدة', icon: 'lifebuoyFloat', color: '#000', onSelect: () => {
                navigate('/supportCenter');
            }});

        _options.push({ title: 'تسجيل الخروج', icon: 'logoutF', color: 'red', onSelect: () => {
                new DuConfirmDialog().create({
                    title: 'هل تريد تسجيل الخروج من حسابك ؟',
                    options: [
                        {title: 'تسجيل الخروج', color: 'red', isBold: true, onSelect: () => {
                                new MyAccountStore().logout();
                            }},
                        {title: 'تراجع',},
                    ]
                });
        }});

        new DuOptionMenu().create({
            event: event,
            onCancel: () => {

            },
            options: _options
        });
    }

    onMount(() => {


    });
</script>

<div class="mainHeader mainContainer">
    <img src="/assets/images/fullLogo.png" class="logo brightnessGlobalHover" on:click={goToHome} width="70px" height="60px"/>
    <div style="width: 10px;height: 10px;display: block"></div>
    <slot name="startComponent">
        {#if $myAccountState.isLogin}
            <Link to="/addNewContent">
                <DuButton text="كتابة تدوينة" />
            </Link>
        {/if}
    </slot>

    <div class="expanded">

    </div>

    {#if !$myAccountState.isLogin}
        <!--<Link to="/login">
            <DuButton text="تسجيل الدخول"/>
        </Link>-->
    {/if}


    {#if $myAccountState.isLogin && $myAccountState.data !== null}
        <div class="myAccountSection">
            <div class="budgeButtons">
                <a href="/orders" use:link>
                    <IconsWorker icon="truckF" width="22px" color="#555"/>
                    {#if $myAccountState.notifications['messages']['count'] > 0}
                        <div class="budg">{$myAccountState.notifications['messages']['count']}</div>
                    {/if}
                </a>
            </div>
            <div class="info">
                <div class="name">{$myAccountState.data.name}</div>
                <a href="/myAccount/wallet" class="balance" use:link>
                    <span class="amount">{($myAccountState.data.balance / 100).toFixed(2)}</span>
                    <!--<span class="currency">ر.س</span>-->
                    <IconsWorker width="18px" icon="walletF" color="#666"/>
                </a>
            </div>
            <div class="thumb" on:click={onAccountInfoTap}>
                {#if $myAccountState.data['thumb'] === null}
                    {#if $myAccountState.data['organization_number'] !== null}
                        <IconsWorker icon="company" width="20px" color="#555"/>
                    {:else}
                        <IconsWorker icon="user" width="20px" color="#555"/>
                    {/if}
                {/if}
            </div>
        </div>
    {/if}
</div>

<style>
    .mainHeader{
        background-color: #fff;
        box-shadow: 0 0 10px #ccc;
        height: 40px;
        padding-top: 7px;
        padding-bottom: 7px;
        display: flex;
        flex-direction: row;
        align-items: center;
        place-items: center;
        position: fixed;
        top:0;
        left: 0;
        z-index: 100;
    }

    .expanded{
        flex: 1;
    }

    .logo{
        height: 60%;
        cursor: pointer;
    }

    .actionButton{
        margin-inline: 20px;
    }

    .myAccountSection{
        margin-inline-start: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .myAccountSection .thumb{
        width: 35px;
        height: 35px;
        border-radius: 100px;
        background-color: #eee;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .myAccountSection .info{
        display: flex;
        flex-direction: column;
        margin-inline-end: 10px;
        align-items: end;
    }

    .myAccountSection .info .name{ font-size: 14px; color: #555; }
    .myAccountSection .info .balance{
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        margin-inline-start: 10px;
        align-items: center;
        font-size: 14px;
    }

    .myAccountSection .info .balance .amount{ color: green; }
    .myAccountSection .info .balance .currency{ color: #555; font-size: 13px; font-weight: bold; margin: 0 3px;}

    .budgeButtons{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-inline-end: 10px;
    }
    .budgeButtons a{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-inline-start: 10px;
        margin-top: 0;
        margin-bottom: 0;
        background: transparent;
        padding: 0;
        position: relative;
        border: 0;
    }
    .budgeButtons .budg{
        position: absolute;
        top: -15px;
        left: -15px;
        font-size: 10px;
        font-weight: bold;
        background: red;
        width: 21px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        color: #fff;
        border-radius: 100px;
        border: 3px solid #fff;
    }
</style>