<script lang="ts">
    import IconsWorker from "../IconsWorker.svelte";
    import {onMount} from "svelte";
    import DuAnimation from "../../Utils/DuAnimation";

    export let options:Object;
    $: color = options.color === undefined ? '#F09035' : options.color;
    $: mainCursor = options.onClick === undefined ? 'auto' : 'pointer';
    let nodeRef;
    let randId = 'fixedNotification' + Math.floor(Math.random() * 10000).toString();

    const onNotificationTap = (e) => {
        if(options.onClick !== undefined) {
            options.onClick();
            dismiss();
        }
    }

    const dismiss = () => {
        DuAnimation.animate({
            id: randId,
            animation: 'zoomOut',
            duration: 500,
        });
        if(nodeRef !== undefined && nodeRef !== null)
            nodeRef.parentNode.removeChild(nodeRef);
    }

    onMount(() => {
        if(options.removeAfter !== undefined) {
            setTimeout(() => {
                dismiss();
            }, (options.removeAfter));
        }
        DuAnimation.animate({
            id: randId,
            animation: 'pulse',
            duration: 500,
        });
    });

</script>

<div class="fixedNotification" bind:this={nodeRef} id="{randId}">
    {#if (options.icon !== undefined)}
        <div class="icon" style="background-color: {color}">
            <IconsWorker icon="{options.icon}" width="20px" color="#fff"/>
            <div class="close" on:click={dismiss}>
                <IconsWorker icon="closeF" width="20px" color="#fff"/>
            </div>
        </div>
    {/if}
    <div class="infoSide" style="cursor: {mainCursor}" on:click={onNotificationTap}>
        {#if (options.title !== undefined)}
            <div class="title" style="color: {color}">{options.title}</div>
        {/if}
        {#if (options.subTitle !== undefined)}
            <div class="subTitle">{options.subTitle}</div>
        {/if}
    </div>
    <div class="colorOverlay" style="background-color: {color}"></div>
</div>

<style>
    :global(.notificationsContainer){
        width: 300px;
        max-height: 100%;
        position: fixed;
        right: 15%;
        top: 60px;
    }

    .fixedNotification{
        width: 90%;
        padding: 15px 5%;
        background-color: rgba(255, 255, 255, 1);
        margin-top: 10px;
        box-shadow: 0 0 5px #ccc;
        display: flex;
        flex-direction: row;
        position: relative;
        overflow: hidden;
    }

    .fixedNotification .colorOverlay{
        width: 100%;
        height: 100%;
        background-color: #000;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0.01;
    }

    .fixedNotification:first-child{
        border-radius: 12px 12px 0 0;
    }

    .fixedNotification:last-child{
        border-radius: 0 0 12px 12px;
    }

    .fixedNotification:only-child{
        border-radius: 12px;
    }


    .fixedNotification .icon{
        width: 45px;
        height: 45px;
        background-color: #eee;
        border-radius: 100px;
        display: flex;
        place-items: center;
        place-content: center;
        z-index: 2;
        position: relative;
        cursor: pointer;
    }

    .fixedNotification .infoSide{
        margin-inline-start: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
    }

    .fixedNotification .infoSide .title{
        font-size: 13px;
        margin-bottom: 5px;
        font-weight: bold;
    }

    .fixedNotification .infoSide .subTitle{
        font-size: 13px;
        color: #555;
    }

    .fixedNotification:hover .close{
        display: flex;
    }

    .fixedNotification .close{
        width: 100%;
        height: 100%;
        display: none;
        background-color: #FF3C52;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 100px;
        z-index: 2;
    }

    .fixedNotification:hover .close{
        display: flex;
    }

</style>