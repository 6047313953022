<script lang="ts">
    import {onMount} from "svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import {useParams} from "svelte-navigator";
    import MainHeader from "../Components/MainHeader.svelte";
    import ContentSideBar from "../Components/ContentSideBar.svelte";
    import RelatedPosts from "../Components/RelatedPosts.svelte";
    import {MetaTags} from "svelte-meta-tags";
    import ContentDecryption from "../Components/ContentDecryption/ContentDecryption.svelte";
    import HorizontalMainTagsMenu from "../Components/HorizontalMainTagsMenu.svelte";
    import IconsWorker from "../Components/IconsWorker.svelte";

    const params = useParams();
    let serial:string = '';
    let data:Object = {};
    let isLoading:boolean = true;
    let firstParagraph = '';


    const getData = () => {
        isLoading = true;
        new ConnectionWorker().request(<any>{
            url: '/content/get',
            getParams: {
                'serial': serial,
                'relatedPosts': 'true'
            },
            onLoad: (response) => {
                isLoading = false;
                data = response.data;
                data.content.forEach((contentCell) => {
                    if(firstParagraph === '') {
                        if(contentCell.tag === 'p') {
                            firstParagraph = contentCell.value;
                        }
                    }
                });
            }
        });
    }
    onMount(() => {
        serial = $params.serial;
        getData();
    });

    $: if($params.serial !== serial) {
        if(!isLoading) {
            serial = $params.serial;
            getData();
        }
    }

    const share = () => {
        navigator.share({title: "إنكيدو | " + data['title'], text: window.location.href});
    }
</script>

<MainHeader />
<div class="mainContainer mainPage allPAge" itemscope itemtype="https://schema.org/NewsArticle">
    <HorizontalMainTagsMenu  showSearch="{false}"/>
    <div class="contentPage">
        <div class="sideBar">
            <ContentSideBar data={isLoading? undefined : data.user_info}/>
        </div>
        <div class="contentSide">
            <div class="contentArea mainContent">
                {#if !isLoading}

                    <MetaTags
                        title={"إنكيدو | " + data['title']},
                        description="{firstParagraph}"
                    />

                    <div class="theTitle" itemprop="headline">{data['title']}</div>
                    <ContentDecryption data="{data.content}"/>
                {/if}
            </div>
            <!--
            <div class="likeButton">{data.id}
                <FixedLikeButton item_id="{data.id}"/>
            </div>-->

            {#if !isLoading}
                <div class="shareButton" on:click={share}>
                    <span>مشاركة</span>
                    <IconsWorker icon="share" color="#fff"/>
                </div>
            {/if}
            {#if !isLoading}
                <RelatedPosts id="{data.id}"/>
            {/if}
        </div>
    </div>
</div>

<style>

    .allPAge{
        margin-top: 70px;
    }
    .contentPage{
        margin-top: 20px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
    }
    .contentPage .sideBar{
        width: 30%;
        position: sticky;
        height: min-content;
        top: 70px;
    }
    .contentPage .contentSide{
        width: available;
        flex: 1;
        margin-inline-start: 15px;
        min-height: 300px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px #ccc;
        display: flex;
        flex-direction: column;
        padding-bottom: 50px;
    }

    .theTitle{
        width: 92%;
        padding: 20px 4%;
        border-bottom: 1px solid #ccc;
        font-size: 25px;
        font-weight: bold;
        color: #555;
    }

    .contentArea{
        display: flex;
        flex-direction: column;
    }



    :global(.contentBlockSection){
        width: available;
        display: flex;
        flex-direction: column;
        padding: 0 25px;
        margin-top: 10px;
    }

    .likeButton{
        width: 80px;
        height: 80px;
        position: fixed;
        bottom: 50px;
        left: 15%;
    }

    .shareButton{
        position: fixed;
        bottom: 70px;
        left: 15%;
        display: flex;
        flex-direction: row;
        background: #000;
        padding: 10px 15px;
        font-size: 14px;
        color: #fff;
        border-radius: 100px;
        align-items: center;
        cursor: pointer;
    }

    .shareButton:hover{
        background: #000;
    }

    .shareButton span{
        margin-inline-end: 10px;
    }

    @media only screen and (max-width: 600px) {
        .contentPage{
            flex-direction: column-reverse;
        }
        .contentPage .sideBar{
            width: 100%;
        }
        .contentPage .contentSide{
            margin-inline-start: 0px;
            margin-bottom: 10px;
        }
        .shareButton{
            left: 5%;
        }
    }

</style>