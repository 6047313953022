<script lang="ts">
    import ConnectionWorker from "../../Utils/ConnectionWorker";

    export let serial;
</script>
    <div class="videoPlayer">
        <video src="{ConnectionWorker.rootUrl + '/file/' + serial}" controls></video>
    </div>
<style>
    .videoPlayer{
        width: 100%;
        display: flex;
        flex-direction: column;
        place-items: center;
        place-content: center;
    }

    .videoPlayer video{
        max-width: 100%;
        width: auto;
    }
</style>