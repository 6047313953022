<script lang="ts">
    // cell example = [ { text: 'exam', onTap: () => {}, } ]

    import {ColorsWorkers} from "../../Utils/ColorsWorker";

    export let background = '#fff';
    export let foreground = '#F09035';
    export let fontSize = '15px';
    export let cells:{ title: 'exam', onTap: () => {}, }[];
    export let onChange: (number) => number;
    export let selectedIndex = 0;
    export let hoveredIndex;

    const getContainerStyle = () => {
        return `
            background: ${ColorsWorkers.hexToRgbA(background, 1)};
            color: ${foreground};
            font-size: ${fontSize};
        `;
    }

    $: getCellStyle = (index) => {
        let style =  `
            font-size: ${fontSize};
            border-top: 2px solid ${foreground};
            border-bottom: 2px solid ${foreground};
            border-inline-start: 2px solid ${foreground};
        `;

        if(index === cells.length - 1) {
            style = style + `border-inline-end: 2px solid ${foreground};`;
        }

        if(index === selectedIndex) {
            style = style + `background: ${ColorsWorkers.hexToRgbA(foreground, 1)};`;
            style = style + `color: #fff;`;
            style = style + `font-weight: bold;`;
        } else {
            style = style + `background: ${ColorsWorkers.hexToRgbA(background, 1)};`
            style = style + `color: ${foreground};`
        }

        if(hoveredIndex === index) {
            style = style + `background: ${ColorsWorkers.hexToRgbA('#000000', 0.8)};`;
            style = style + `border-color: ${ColorsWorkers.hexToRgbA('#000000', 0)};`;
            style = style + `color: #fff;`;
        }

        return style;
    }

    const _onSelect = (index) => {
        selectedIndex = index;
        if(onChange !== undefined)
            onChange(index);
    }
</script>

<div class="SwitchTabs" style="{getContainerStyle()}">
    {#each cells as cell, index}
        <div class="cell" style="{getCellStyle(index)}" on:click={(e) => _onSelect(index) } on:mouseover={(e) => hoveredIndex = index } on:mouseleave={(e) => hoveredIndex = undefined }>{cell.title}</div>
    {/each}
</div>

<style>
    .SwitchTabs{
        border-radius: 100px;
        display: flex;
        flex-direction: row;
    }
    .SwitchTabs .cell{
        padding: 7px 15px;
        cursor: pointer;
    }
    .SwitchTabs .cell:first-child{ border-radius: 0px 100px 100px 0; }
    .SwitchTabs .cell:last-child{ border-radius: 100px 0 0 100px;}
</style>