<script lang="ts">

    export let isDialogOpen = false;
    export let value:string = '';
    export let event;
    export let options:{text: 'noText', value: 'noValue', icon: undefined}[] = [];
    export let onChange:void;

    let screenHeight = 0;
    let randId = 'selectM_' + Math.floor(Math.random() * 10000);


    $: {
        if(event) {
            let target = event.target;
            let yPosition = target.getBoundingClientRect().top + window.scrollY;
            let xPosition = target.getBoundingClientRect().left + window.scrollX;
            screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            setTimeout(() => {
                if(isDialogOpen) {
                    document.getElementById(randId).style.width = target.offsetWidth + 30 + 'px';
                    document.getElementById(randId).style.left = (-10) + 'px';
                    document.getElementById(randId).style.maxHeight = (screenHeight / 2) + 'px';

                    if (screenHeight / 2 > yPosition) {
                        document.getElementById(randId).style.top = 0 + 'px';
                    } else {
                        document.getElementById(randId).style.top = (-(document.getElementById(randId).clientHeight)) + 'px';
                    }
                }
            }, 200);

            if(isDialogOpen) {
                document.body.style.overflow = 'hidden';

            } else {
                document.body.style.overflow = 'auto';
            }
        }
    }

    const selectCell = (option) => {
        isDialogOpen = false;
        value = option.value;
        if(onChange) {
            onChange();
        }
    }


</script>

{#if isDialogOpen}
    <div class="selectMenuDialog noTextSelect" style="max-height: {screenHeight}px;" id="{randId}">
        {#each options as option}
            <div class="cell" on:click={selectCell(option)}>{option.text}</div>
        {/each}
    </div>
    <div class="backdrop" on:click="{() => isDialogOpen = false}"></div>
{/if}

<style>
    .backdrop{
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(255, 255, 255, 0.2);
        top: 0;
        left: 0;
        z-index: 100;
        backdrop-filter: blur(2px);
    }
    .selectMenuDialog{
        width: 100%;
        height: fit-content;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 12px;
        position: absolute;
        z-index: 110;
        box-shadow: 0 0 10px #ccc;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    .selectMenuDialog .cell{
        width: available;
        padding: 20px;
        border-bottom: 1px solid #eee;
        cursor: pointer;
    }

    .selectMenuDialog .cell:last-of-type{border: 0;}

    .selectMenuDialog .cell:hover{
        background: rgba(240, 144, 53, 0.1);
    }

    .selectMenuDialog .cell:active{
        background: rgba(240, 144, 53, 0.2);
    }
</style>