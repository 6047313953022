<script lang="ts">
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import {useLocation, useParams} from "svelte-navigator";
    import {afterUpdate, onMount} from "svelte";
    import IconsWorker from "../../Components/IconsWorker.svelte";
    import DuButton from "../../Components/DuButton.svelte";
    import FixedNotifications from "../../Components/DuNotifications/FixedNotifications";

    const _userParams = useParams();
    const _useLocation = useLocation();
    let language = 'ar';

    let isLoading = false;
    let isFailLoad = false;
    let isSending = false;
    let data = {};


    const getPageData = () => {
        isLoading = true;
        new ConnectionWorker().request(<any>{
            url: '/fixedPages/get',
            postParams: {
                'language': language,
                'key': $_userParams.key
            },
            onLoad: (response) => {
                if(response['data']) {
                    data = response['data'];
                } else {
                    isFailLoad = true;
                }
                isLoading = false;
            },
            onFail: (code) => {
                isFailLoad = true;
                isLoading = false;
            }
        });
    }

    onMount(() => {
        const urlSearchParams = new URLSearchParams($_useLocation.search);
        if(urlSearchParams.get('lang')) {
            language = urlSearchParams.get('lang');
        }
        getPageData();
    });

    afterUpdate(() => {
        console.log('ggg')
    });

    const save = () => {
        isSending = true;
        new ConnectionWorker().request(<any>{
            url: '/fixedPages/save',
            postParams: {
                'language': language,
                'key': $_userParams.key,
                'content': data['content'],
            },
            onLoad: (response) => {
                if(response['data']) {
                    new FixedNotifications().create({
                        title: 'تم حفظ الصفحة',
                        icon: 'checkRoundedOut',
                        color: 'green',
                        removeAfter: 2000,
                    });
                } else {
                    new FixedNotifications().create({
                        title: 'حدث خطأء غير معروف',
                        subTitle: 'حاول مجددًا',
                        icon: 'error',
                        color: 'red',
                        removeAfter: 2000,
                    });
                }
                isSending = false;
            },
            onFail: (code) => {
                new FixedNotifications().create({
                    title: 'حدث خطأء غير معروف',
                    subTitle: 'حاول مجددًا',
                    icon: 'error',
                    color: 'red',
                    removeAfter: 2000,
                });
                isSending = false;
            }
        });
    }
</script>

<div class="FixedPagesEditor {isSending ? 'disable' : ''}">
    {#if isLoading}
        <div class="loading"><IconsWorker icon="loading" width="30px;" /></div>
    {:else}
        {#if isFailLoad}
           Error
        {:else}
            <div class="titleBar">
                <div class="title">{data.title}</div>
                <DuButton text="حفظ" onTap="{save}"/>
            </div>
            <textarea class="content" placeholder="تستطيع استعمال وسوم HTML" bind:value="{data['content']}"></textarea>
        {/if}
    {/if}
</div>


<style>
    .FixedPagesEditor{
        width: available;
        display: flex;
        padding: 20px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0 0 10px #ccc;
        flex-direction: column;
    }

    .loading{
        display: flex;
        width: fit-content;
        place-self: center;
        margin: 50px 0;
    }

    .titleBar{
        padding-top: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        font-size: 18px;
        color: #555;
        display:flex;
        flex-direction: row;
        align-items: center;
    }

    .titleBar .title{
        flex: 1;
    }

    .content{
        min-height: 400px;
        font-size: 16px;
        color: #000;
        background: transparent;
        border: 0;
    }

    .disable{
        opacity: 0.5;
        pointer-events: none;
    }
</style>