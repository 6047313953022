<script lang="ts">

import MainHeader from "../Components/MainHeader.svelte";
import DuTextEditor, {postId, tags} from "../Components/DuTextEditor/DuTextEditor.svelte";
import DuButton from "../Components/DuButton.svelte";
import ConnectionWorker from "../Utils/ConnectionWorker";
import {useParams} from "svelte-navigator";
import {onMount} from "svelte";
import FixedNotifications from "../Components/DuNotifications/FixedNotifications";

let textBlocks = [];
let title: '';
let subTitle: '';
let postImage: '';
let postId: '';
let tags: '';

const params = useParams();

const sendContentToServer = () => {
    const postParams = {
        content: JSON.stringify(textBlocks),
        image: postImage,
        title: title,
        sub_title: subTitle,
        tags: tags,
    };

    if(postId !== '') {
        postParams.id = postId;
    }

    new ConnectionWorker().request(<any>{
        url: '/content/addNew',
        postParams: postParams,
        onLoad: (response) => {
            console.log(response);
            if(response.status) {
                postId = response.id;
                new FixedNotifications().create({
                    title: 'تم حفظ المقال',
                    icon: 'checkRoundedOut',
                    color: 'green',
                    removeAfter: 2000,
                });
            } else {
                new FixedNotifications().create({
                    title: 'حدث خطأء غير معروف',
                    subTitle: 'حاول مجددًا',
                    icon: 'error',
                    color: 'red',
                    removeAfter: 2000,
                });
            }
        },
        onFail: (code) => {
            new FixedNotifications().create({
                title: 'فشل في الإتصال بالخادم',
                subTitle: 'حاول مجددًا',
                icon: 'error',
                color: 'red',
                removeAfter: 2000,
            });
        }
    });
}

onMount(() => {
    if($params.id !== undefined) {
        postId = $params.id;
        getData();
    }
});

const getData = () => {
    new ConnectionWorker().request(<any>{
        url: '/content/get',
        getParams: {
            id: postId,
        },
        onLoad: (response) => {
            console.log(response);
            if(response.status) {
                title = response.data.title;
                subTitle = response.data.sub_title;
                postImage = response.data.image;
                textBlocks = response.data.content;
                tags = response.data.tags_as_string;
            } else {

            }
        }
    });
}



</script>

    <MainHeader>
        <div slot="startComponent" class="actionsButtons">
            <DuButton text="حفظ" background="#555" icon="saveF"/>
            <div style="display: block;width: 10px;"></div>
            <DuButton text="نشر" icon="checkF" onTap="{() => {
                sendContentToServer();
            }}"/>
        </div>
    </MainHeader>
    <div class="mainPage mainContainer">
        <DuTextEditor
                bind:textBlocks="{textBlocks}"
                bind:subTitle="{subTitle}"
                bind:title="{title}"
                bind:postImage="{postImage}"
                bind:postId="{postId}"
                bind:tags="{tags}"
        />
    </div>

<style>
    .actionsButtons{
        display: flex;
        flex-direction: row;
    }
</style>