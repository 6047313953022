<script lang="ts">
    import IconsWorker from "../Components/IconsWorker.svelte";
    import DuButton from "../Components/DuButton.svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import FixedNotifications from "../Components/DuNotifications/FixedNotifications";
    import DuConfirmDialog from "../Components/DuConfirmDialog/DuConfirmDialog";
    import {onMount} from "svelte";
    export let data;

    let isDeleted = false;
    let isDeleting = false;

    let isMarkAsSolving = false;

    let isOpen = false;

    const getTopic = () => {
        if(data.topic === 'suggest')
            return 'اقتراح';
        if(data.topic === 'issue')
            return 'مشكلة';
        if(data.topic === 'other')
            return 'سبب اّخر';
    }

    $:getCreateDate = () => {
        const _date = new Date(data.created_at);
        return _date.getHours() + ':' + _date.getMinutes().toString().padStart(2, '0') + ' ' + _date.getDate() + '/' + (_date.getMonth() + 1) + '/' + _date.getFullYear();
    }

    const sendDeleteMessageRequest = () => {
        isDeleting = true;
        new ConnectionWorker().request(<any>{
            url: '/support/deleteMessage',
            postParams: {
              id: data['id'],
            },
            onLoad: (response) => {
                if(response['status']) {
                    new FixedNotifications().create({
                        title: 'تم حذف الرسالة بنجاح',
                        icon: 'checkRoundedOut',
                        color: 'green',
                        removeAfter: 2000,
                    });
                    isDeleted = true;
                } else {
                    new FixedNotifications().create({
                        title: 'حدث خطأء غير معروف',
                        subTitle: 'حاول مجددًا',
                        icon: 'error',
                        color: 'red',
                        removeAfter: 2000,
                    });
                }
                isDeleting = false;
            },
            onFail: (code) => {
                new FixedNotifications().create({
                    title: 'حدث خطأء غير معروف',
                    subTitle: 'حاول مجددًا',
                    icon: 'error',
                    color: 'red',
                    removeAfter: 2000,
                });
                isDeleting = false;
            }
        });
    }


    const markAsSolved = () => {
        isMarkAsSolving = true;
        new ConnectionWorker().request(<any>{
            url: '/support/markAsSolved',
            postParams: {
              id: data['id'],
            },
            onLoad: (response) => {
                if(response['status']) {
                    new FixedNotifications().create({
                        title: 'تم تعليم الرسالة كمحلولة',
                        icon: 'checkRoundedOut',
                        color: 'green',
                        removeAfter: 2000,
                    });
                    data = response['data'];
                } else {
                    new FixedNotifications().create({
                        title: 'حدث خطأء غير معروف',
                        subTitle: 'حاول مجددًا',
                        icon: 'error',
                        color: 'red',
                        removeAfter: 2000,
                    });
                }
                isMarkAsSolving = false;
                toggleMessageOpen();
            },
            onFail: (code) => {
                new FixedNotifications().create({
                    title: 'حدث خطأء غير معروف',
                    subTitle: 'حاول مجددًا',
                    icon: 'error',
                    color: 'red',
                    removeAfter: 2000,
                });
                isMarkAsSolving = false;
            }
        });
    }


    const markAsRead = () => {
        new ConnectionWorker().request(<any>{
            url: '/support/markAsRead',
            postParams: {
              id: data['id'],
            },
            onLoad: (response) => {
                if(response['status']) {
                    data['is_read'] = true;
                }
            },
            onFail: (code) => {}
        });
    }

    const deleteMessage = () => {
        new DuConfirmDialog().create({
            title: 'هل تريد إزالة الرسالة ؟',
            titleColor: 'red',
            subTitle: 'لا يمكن التراجع عن هذا الإجراء',
            options: [
                {title: 'إزالة', onSelect: () => sendDeleteMessageRequest(), color: 'red'},
                {title: 'تراجع', },
            ],
        })
    }

    const toggleMessageOpen = () => {
        isOpen = !isOpen;
        if(isOpen && !data['is_read']) {
            markAsRead();
        }
    }

    const getName = () => {
        if(data['sender_info'] !== null)
            return data['sender_info']['name'];
        return data['name'];
    }
</script>


{#if !isDeleted}
<div class="supportMessage {isDeleting || isMarkAsSolving ? 'disabled' : ''}">
    <div class="interface" on:click={toggleMessageOpen}>
        <div class="infoSide">
            <div class="title">
                <span>{getName()}</span>
                {#if !data['is_read']}
                    <div class="budg"></div>
                {/if}
                {#if data['is_solved']}
                    <div class="solvedTag">
                        <div>محلول</div>
                        <IconsWorker color="#555" icon="checkF" width="13px"/>
                    </div>
                {/if}
            </div>
            <div class="messageType">{getTopic()}</div>
            <div class="serial">{data.serial}#</div>
        </div>
        <div class="sendDate">{getCreateDate()}</div>
        <IconsWorker icon="{ isOpen? 'arrowDownF' : 'arrowUpF' }" width="20px" color="#555"/>
    </div>
    {#if isOpen}
        <div class="message">
            <div class="buttonsHolder">
                {#if !data['is_solved']}
                    <DuButton text="تعيين كمحلول" icon="checkF" background="green" onTap="{markAsSolved}"/>
                    <div class="gapper"></div>
                {/if}
                <DuButton text="حذف" icon="deleteF" background="red" onTap="{deleteMessage}"/>
            </div>
            <div class="messageContent">{data.message}</div>
            {#if data['sender_info'] === null}
                <div class="messageContactChannel">{data.contact_channel}</div>
            {/if}
            {#if data['is_solved']}
                <div class="solveByInfo">
                    تم حل المشكلة بواسطة
                     <b class="solverName"> {data['solver_info']['name']} </b>
                </div>
            {/if}
        </div>
    {/if}
</div>
{/if}

<style>
.supportMessage{
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
}

.supportMessage .interface{
   display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.supportMessage .interface .infoSide{
   display: flex;
    flex-direction: column;
    flex: 1;
}
.supportMessage .interface .title{
   font-size: 16px;
    color: #555;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.supportMessage .interface .title .solvedTag{
    padding: 7px 12px;
    border-radius: 100px;
    background: rgba(95, 198, 80, 0.2);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline-start: 5px;
}
.supportMessage .interface .title .solvedTag div{margin-inline-end: 5px;}


.supportMessage .interface .title .budg{
   width: 10px;
   height: 10px;
    border-radius: 100px;
    background: red;
    margin-inline-start: 10px;
}
.supportMessage .interface .messageType{
    margin: 5px 0;
    font-size: 12px;
    font-weight: bold;
    background: rgba(240, 144, 53, 0.2);
    width: fit-content;
    padding: 7px 12px;
    border-radius: 100px;
}

.supportMessage .interface .serial{
    margin-top: 5px;
   font-size: 14px;
    color: #666;
}
.supportMessage .interface .sendDate{
    font-size: 12px;
    color: #555;
    margin: auto 10px;
}
.supportMessage .message{
    display: flex;
    flex-direction: column;
    width: available;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    padding-inline-start: 50px;
    border-inline-start: 1px solid #ccc;
}
.supportMessage .message .buttonsHolder{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: end;
    justify-items: end;
}
.supportMessage .message .gapper{
    width: 10px;
    height: 10px;
}

.supportMessage .message .messageContactChannel{
    font-size: 13px;
    margin: 7px 5px;
    border-inline-start: 2px solid #ccc;
    padding-inline-start: 5px;
}

.disabled{
    opacity: 0.5;
    pointer-events: none;
}

.solveByInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    color: #7EC46A;
}

.solveByInfo .solverName{
    margin-inline-start: 5px;
}
</style>