<script lang="ts">

import MainHeader from "../Components/MainHeader.svelte";
import DuInput, { DuInputType } from "../Components/DuInput/DuInput.svelte";
import DuInputPhone from "../Components/DuInput/DuInputPhone.svelte";
import DuButton from "../Components/DuButton.svelte";
import { initializeApp } from "firebase/app";
import { ConfirmationResult, getAuth, IdTokenResult, RecaptchaVerifier, signInWithPhoneNumber, UserCredential} from "firebase/auth";
import {onMount} from "svelte";
import DuPinCode from "../Components/DuPinCode.svelte";
import MyAccountStore from '../stores/MyAccountStore'
import ConnectionWorker from "../Utils/ConnectionWorker";
import SwitchTabs from "../Components/Controllers/SwitchTabs.svelte";


let phone;
let phoneVal:string = '';
let phoneCode:string = '';
let phoneValid:boolean = false;

let name;
let nameVal:string = '';
let nameValid:boolean = false;

let organizationNumber;
let organizationNumberVal:string = '';
let organizationNumberValid:boolean = false;

let accountTypeIndex:number = 0;

let step:number = 0;

declare global {
    interface Window {
        recaptchaVerifier?: any,
        recaptchaWidgetId?: any
    }
}

let confirmationResult: ConfirmationResult;
let verificationId = '';

let app:any;
let auth:any;

const myAccountState = MyAccountStore.state;


onMount(async () => {

    if($myAccountState.isLogin) {
        document.location.href="/";
    }

    //await new Promise(r => setTimeout(r, 2000));
    app = await  initializeApp({
        apiKey: "AIzaSyADYYwshmwDWTyNEM6odfGwHVvGALfK7Ik",
        authDomain: "inkedoo.firebaseapp.com",
        projectId: "inkedoo",
        storageBucket: "inkedoo.appspot.com",
        messagingSenderId: "1043563157087",
        appId: "1:1043563157087:web:7fca1bba4743d9e513adea",
        measurementId: "G-HFETJMTKWL"
    });
    auth = await getAuth();
    auth.languageCode = 'ar';
});

const onSignInButtonClick = async () => {

        if(step === 0) {
            phone.validate();

            let phoneWithCode = phoneCode + phoneVal;

            if(phoneValid) {
                step++;
                await new Promise(r => setTimeout(r, 100));
                window.recaptchaVerifier = new RecaptchaVerifier('recaptchaContainer', {
                    'size': 'normal',
                    'callback': (response: any) => {
                        signInWithPhoneNumber(auth, phoneWithCode, window.recaptchaVerifier)
                            .then((_confirmationResult: ConfirmationResult) => {
                                confirmationResult = _confirmationResult;
                                verificationId = _confirmationResult.verificationId;
                                step++;
                            }).catch((error) => {
                            // Error; SMS not sent
                            // ...
                        });
                    },
                    'expired-callback': () => {
                        alert('expired-callback');
                    }
                }, auth);

                await window.recaptchaVerifier.render().then((widgetId: any) => {
                    window.recaptchaWidgetId = widgetId;
                });
            }
    } else if(step === 3) {
            if(accountTypeIndex === 0) {
                name.validate();
            } else if(accountTypeIndex === 1) {
                name.validate();
                organizationNumber.validate();
            }

            if(nameValid && organizationNumberValid) {
                sendAccountInfoData();
            }
    }

}

const onCodeDone = (code) => {
    console.log('codeAdd');
    console.log(code);

    confirmationResult.confirm(code.toString()).then((result:UserCredential) => {
        const idToken = result.user.getIdTokenResult().then((userInfo:IdTokenResult) => {
            verificationId = userInfo.token;
            console.log("firebase res is :");
            console.log(verificationId);
            sendData();
        });
    }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
    });

}

const sendData = () => {
    new ConnectionWorker().request(<any>{
        url: '/user/joinOrLoginUsingPhone',
        postParams: {
            firebaseKey: verificationId,
        },
        onLoad: (response) => {
            if(response.status) {
                const id = response.id;
                const token = response.token;
                new MyAccountStore().login(token, id);

                if(response['isExist']) {
                    document.location.href="/";
                } else {
                    step = 3;
                }
            } else {
                alert('loginError');
            }
        },
        onFail: (code) => {
            console.log(code);
        }
    });
}


const sendAccountInfoData = () => {

    const params = { 'name': nameVal };
    if(accountTypeIndex === 1) {
        params['organization_number'] = organizationNumberVal;
    }

    new ConnectionWorker().request(<any>{
        url: '/user/updateData',
        postParams: params,
        onLoad: (response) => {
            if(response.status) {
                document.location.href="/";
            } else {
                // show error
            }
        }
    });
}

</script>

<MainHeader />
<div class="mainPage mainContainer">
    <div class="loginBackground">

        <div class="loginBox">
            <div class="boxTitle">تسجيل الدخول</div>
            <div class="container">
                    {#if step === 0}
                    <DuInputPhone
                            label="رقم الهاتف"
                            bind:value="{phoneVal}"
                            placeHolder=""
                            isRequired="{true}"
                            isRequiredMessage="نحتاج إلى رقم هاتفك لتفعيل حسابك"
                            typeErrorMessage="استعمل الأرقام العربية فقط 0-9"
                            minErrorMessage="رقم الهاتف قصير جدً"
                            countryCode="sd"
                            bind:phoneCode="{phoneCode}"
                            bind:this={phone}
                            bind:isValid={phoneValid}
                    />
                {:else if step === 1}
                    <div id="recaptchaContainer"></div>
                {:else if step === 2}
                    <div class="codeLabel">
                        <span> لقد تم إرسال رمز التحقق إلى رقم هاتفك </span>
                        <span class="phoneHighlight"> {phoneCode + phoneVal} </span>
                        <span> قم بكتابة الرمز بالاسفل </span>
                    </div>
                  <DuPinCode length="6" style="margin-bottom: 20px;" size="45px" onDone="{onCodeDone}"/>

                {:else if step === 3}
                        <div class="accountType">
                            <SwitchTabs cells={[
                                { title: 'حساب فردي', onTap: () => {} },
                                { title: 'حساب مؤسسة', onTap: () => {} },
                            ]} bind:selectedIndex={accountTypeIndex}/>
                        </div>

                        <DuInput
                                inputType="{DuInputType.name}"
                                label="الاسم"
                                bind:value="{nameVal}"
                                placeHolder=""
                                isRequired="{true}"
                                isRequiredMessage="رجاء قم بكتابة اسمك"
                                typeErrorMessage="استعمل الأحرف العربية أو الاتينية فقط"
                                minLength="{2}"
                                minLengthMessage="رجاء قم بكتابة اسمك الحقيقيً"
                                maxLength="{20}"
                                maxLengthMessage="الاسم طويل بعض الشيءً"
                                bind:this={name}
                                bind:isValid={nameValid}
                        />

                        {#if accountTypeIndex === 1}

                            <div class="gapper"></div>

                            <DuInput
                                    inputType="{DuInputType.number}"
                                    label="رقم السجل التجاري"
                                    bind:value="{organizationNumberVal}"
                                    placeHolder=""
                                    isRequired="{true}"
                                    isRequiredMessage="رجاء إداخال رقم المؤسسة"
                                    typeErrorMessage="استعمل أرقام إنجليزية فقط"
                                    minLength="{10}"
                                    minLengthMessage="رجاء إداخال رقم السجل التجاريً"
                                    maxLength="{12}"
                                    maxLengthMessage="تأكد من رقم السجل التجاري"
                                    bind:this={organizationNumber}
                                    bind:isValid={organizationNumberValid}
                            />

                        {/if}
                {/if}

            </div>
            {#if [0].includes(step)}
                <div class="buttonHolder">
                    <DuButton text="التالي" onTap="{onSignInButtonClick}"  fontSize="16" padding="10px 25px"/>
                </div>
            {:else if [3].includes(step)}
                <div class="buttonHolder">
                    <DuButton text="حفظ" onTap="{onSignInButtonClick}" fontSize="16" padding="10px 25px"/>
                </div>
            {/if}
        </div>

        <img src="/assets/images/loginLock.png" class="backGroundLock" />
    </div>
</div>

<style>
    .loginBackground{
        width: 60%;
        margin-top: 100px;
        place-self: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .loginBox{
        place-self: center;
        width: 80%;
        height: max-content;
        background: #fff;
        margin: 70px auto;
        border-radius: 10px;
        box-shadow: 0 0 10px #ccc;
    }


    .loginBox .container{
        margin: 20px 8%;
    }

    .loginBox .container .divider{
        margin-top: 20px;
    }

    .loginBox .buttonHolder{
        width: available;
        display: flex;
        flex-direction: row;
        margin: 20px 8%;
        place-content: end;
    }

    .backGroundLock{
        position: absolute;
        place-self: start;
        width: 150px;
        height: 150px;
        z-index: -1;
    }

    .boxTitle{
        width: available;
        border-bottom: 2px solid #eee;
        padding: 20px 8%;
    }

    .codeLabel{
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #666;
    }

    .codeLabel .phoneHighlight{
        color: #F09035;
        direction: ltr;
        text-align: left;
    }

    .accountType{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
    }

    .gapper{
        width: 20px;
        height: 20px;
    }

</style>