<script lang="ts">
    import {onMount} from "svelte";

    export let itemId;
    export let itemType;
    let isLoadingComponent = true;
    let ProblemReporterContainer;

    const loadComponent = async () => {
        ProblemReporterContainer = (await import('./ProblemReporterContainer.svelte')).default;
        isLoadingComponent = false;
    }


    onMount(() => {
        loadComponent();
    });
</script>

<div class="ProblemReporterDialog">
    {#if !isLoadingComponent}
        <svelte:component this={ProblemReporterContainer} {...$$props}/>
    {/if}
</div>

<style>
    .ProblemReporterDialog{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: fixed;
        background: rgba(0,0,0, 0.5);
        top: 0;
        left: 0;
        z-index: 100;
        place-content: center;
        place-items: center;
    }
</style>