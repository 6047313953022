<script lang="ts" context="module">
     type StepperProgressOption = {
        icon: '',
        label: '',
    }
</script>

<script lang="ts">
    import IconsWorker from "./IconsWorker.svelte";
    export let options:StepperProgressOption[];
    export let step:number = 0;
    export let background:string = '#F09035';
</script>

<div class="stepperProgressLine">
    <div class="upperSide">
    {#each options as option, index}
            <div class="spot">
                <div class="indicator {step >= index ? 'selected' : ''}" style="border: 2px solid {background}; background: {step >= index ? background: 'transparent'}">
                    {#if option.icon !== ''}
                        <IconsWorker icon="{option.icon}{step >= index ? 'F' : ''}" width="17px" color="{step >= index ? '#fff' : '#888'}"/>
                    {:else}
                        {index + 1}
                    {/if}
                </div>
            </div>
            {#if (index !== options.length - 1)}
                <div class="line" style="background: {step > index ? background : '#ccc'}"></div>
            {/if}
    {/each}
    </div>
    <div class="bottomSide">
        {#each options as option, index}
            {#if (option.label !== '')}
                <div class="label {step === index ? 'selectedLabel' : ''}">{option.label}</div>
            {/if}
        {/each}
    </div>
</div>

<style>
    .stepperProgressLine{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .upperSide{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .line{
        flex: 1;
        height: 5px;
        background-color: #000;
    }
    .spot{
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .indicator{
        display: flex;
        flex-direction: column;
        place-items: center;
        place-content: center;
        width: 38px;
        height: 38px;
        border-radius: 100px;
        font-size: 16px;
        font-weight: bold;
        color: #555;
    }

    .selected{
        color: #fff !important;
    }

    .bottomSide{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }

    .label{
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #777;
    }
    .selectedLabel{
        color: #222;
    }
</style>