<script lang="ts">
    import IconsWorker from "../Components/IconsWorker.svelte";

    export let data;
    let isOpen = false;

    const toggle = () => {
        isOpen = !isOpen;
    }
</script>

<div class="faqCell">
    <div class="questionArea" on:click={toggle}>
        <div class="question">{data.question}</div>
        <IconsWorker icon="{!isOpen ? 'arrowDownF' : 'arrowUpF'}" color="#555" width="15px"/>
    </div>
    {#if isOpen}
        <div class="answer">{data.answer}</div>
    {/if}
</div>

<style>
    .faqCell{
        border-top: 1px solid #eee;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .questionArea{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
    }

    .questionArea:hover{ background: rgba(240, 144, 53, 0.05); }
    .questionArea:active{ background: rgba(240, 144, 53, 0.1); }

    .questionArea .question{
        flex: 1;
        color: #555;
    }

    .answer{
        padding-inline-start: 25px;
        padding-inline-end: 20px;
        padding-bottom: 15px;
        padding-top: 10px;
        font-size: 18px;
        color: #222;
    }
</style>