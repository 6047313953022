<script lang="ts">
    import DuCountryDialog from './DuCountryDialog.svelte';
    import DuInputInterface from './DuInputInterface.svelte';
    import IconsWorker from '../IconsWorker.svelte';

    export let label:string = '';
    export let value:string = '';
    export let placeHolder:string = '';

    export let isValid:boolean = null;
    export let errorMessage:string = '';
    export let showErrorMessage:boolean = false;

    export let countryCode:string = 'sd';
    export let countryName:string = '';

    let lang = 'ar';
    let isDialogOpen:boolean = false;
    let isLoading:boolean = true;

    const openSelectCountryDialog = () => {
        isDialogOpen = true;
    }

    export const validate = () => {
        isValid  = true;
    }
</script>


<DuInputInterface errorMessage={showErrorMessage ? errorMessage : ''} {...$$props}>
    <div class="duCountry" slot="input" on:click={openSelectCountryDialog}>
        <img class="countryIcon" src="/assets/countrysFlags/{countryCode}.png" />
        <span class="countryName">{countryName}</span>
        <div class="rowDown" on:click={openSelectCountryDialog}>
            <IconsWorker icon="arrowDownF" color="#555" width="17px"/>
        </div>
    </div>
</DuInputInterface>



<DuCountryDialog bind:isDialogOpen bind:isLoading bind:countryCode bind:countryName />


<style>
    .duCountry{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        text-align: start;
        font-size: 15px;
    }


    .countryIcon{
        height: 22px;
        margin-inline-end: 10px;
    }

    .countryName{
        flex: 1;
        margin-inline-end: 10px;
        padding: 0;
    }

    .rowDown{
        width: 17px;
    }
</style>