<script lang="ts">
    import DuInputInterface from "./DuInputInterface.svelte";
    import IconsWorker from "../IconsWorker.svelte";
    import DuSelectMenuDialog from "./DuSelectMenuDialog.svelte";

    export let label:string = '';
    export let value:string = '';
    export let placeHolder:string = '';

    export let isValid:boolean = null;
    export let errorMessage:string = '';
    export let showErrorMessage:boolean = false

    export let isRequired:boolean = false;
    export let isRequiredMessage:string = 'required';


    export let options:{text: 'noText', value: 'noValue', icon: undefined}[] = [];

    let isDialogOpen:boolean = false;
    let isLoading:boolean = true;

    let event;
    const openSelectMenu = (_event) => {
        event = _event;
        isDialogOpen = true;
        if(showErrorMessage) {
            validate();
        }
    }

    export const validate = () => {
        isValid  = true;
        if(isRequired && value === '') {
            isValid = false;
            errorMessage = isRequiredMessage;
        }
        if(!isValid) {
            showErrorMessage = true;
        } else {
            showErrorMessage = false;
        }
    }
</script>

<DuInputInterface errorMessage={showErrorMessage ? errorMessage : ''} {...$$props}>
    <div class="duSelectMenu" slot="input">
        <div class="selectMenuInterface" on:click={openSelectMenu}>
            {#if value === ''}
                {placeHolder}
            {:else}
                {#each options as option}
                    {#if option['value'] === value}
                        {option['text']}
                    {/if}
                {/each}
            {/if}
        </div>
        <div class="rowDown" on:click={openSelectMenu}>
            <IconsWorker icon="arrowDownF" color="#555" width="17px"/>
        </div>

        <DuSelectMenuDialog bind:isDialogOpen bind:value={value} bind:event bind:options={options} bind:placeHolder={placeHolder} onChange="{() => validate()}"/>

    </div>
</DuInputInterface>


<style>
    .duSelectMenu{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        text-align: start;
        font-size: 15px;
        position: relative;
        overflow: visible;
    }

    .selectMenuInterface{
        width: max-content;
        flex: 1;
        height: 100%;
    }
</style>