import FixedNotificationsComponent from "./FixedNotificationsComponent.svelte";
export default class FixedNotifications {
    constructor() {
        this.create = (params) => {
            const container = document.getElementById('notificationsContainer');
            if (!document.body.contains(container)) {
                const containerElement = document.createElement('div');
                containerElement.className = 'notificationsContainer';
                containerElement.id = 'notificationsContainer';
                document.body.appendChild(containerElement);
            }
            new FixedNotificationsComponent({
                target: document.getElementById('notificationsContainer'),
                props: {
                    options: params,
                },
            });
        };
    }
}
/*
example:

new FixedNotifications().create({
            title: 'hello',
            subTitle: 'hello',
            icon: 'userF',
            removeAfter: 2000,
            onClick: () => { alert('tap'); },
        });

* */ 
