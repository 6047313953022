<script lang="ts">

    import MainHeader from "../../Components/MainHeader.svelte";
    import RatingBar from "../../Components/RatingBar.svelte";
    import IconsWorker from "../../Components/IconsWorker.svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import {useParams, link, useNavigate, navigate, Link} from "svelte-navigator";
    import {onMount} from "svelte";
    import DuListView from "../../Components/DuListView.svelte";
    import ServiceFragment from "../../Fragments/ServiceFragment.svelte";
    import DuOptionMenu from "../../Components/DuOptionsMenu/DuOptionsMenu";
    import MyAccountStore from "../../stores/MyAccountStore";
    import DuButton from "../../Components/DuButton.svelte";

    const _userParams = useParams();
    let data = {};
    let isLoading = true;
    let isDataFetched = false;
    let userNotFound = false;

    let scrollContainer;
    scrollContainer = document.body;
    const myAccountState = MyAccountStore.state;

    const getData = () => {
        isLoading = true;
        new ConnectionWorker().request(<any>{
            url: '/user/get',
            postParams: {
                serial: $_userParams.serial
            },
            onLoad: (response) => {
                console.log(response);
                if(response['status']) {
                    data = response['data'];
                    isDataFetched = true;
                } else {
                   if(response['reason'] === 'user_not_found') userNotFound = true;
                }
                isLoading = false;
            },
            onFail: (code) => {
                isLoading = false;
            }
        });
    }

    const menu = [
        { icon: 'shop', text: 'العروض', page: 'services'},
        { icon: 'star', text: 'التقييمات', page: 'ratings'},
    ];

    $: isCatSelected = (cell) => {
        const tab = $_userParams.page;
        if(!tab && cell.page === 'services') {
            return true;
        } else {
            if(tab === cell.page) {
                return true;
            }
        }
        return false;
    }

    onMount(() => {
        getData();
    });


    const share = (method:string) => {
        const url = window.location.href;
        const message = "الصفحة الشخصية لـ " + data['name'] + '\n' + url + '\n';
        let shareUrl = '';

        if(method === 'facebook') {
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u==${encodeURIComponent(url)}`;
        } else if(method === 'whatsapp') {
            shareUrl = `https://wa.me?text=${encodeURIComponent(message)}`;
        } else if(method === 'twitter') {
            shareUrl = `https://wa.me?text=${encodeURIComponent(message)}`;
            shareUrl = `https://twitter.com/share?url=${url}`;
        }

        if(method === 'copy') {
            navigator.clipboard.writeText(shareUrl);
            // todo show notification here
        } else {
            window.open(shareUrl, '_blank').focus();
        }
    }

    const openShareBox = (e) => {
        new DuOptionMenu().create({
            event: e,
            options: [
                {title: 'مشاركة على فيسبوك', icon: 'faceBookF', color: '#555', onSelect: () => share('facebook')},
                {title: ' مشاركة على واتساب', icon: 'whatsappF', color: '#555', onSelect: () => share('whatsapp')},
                {title: 'مشاركة على تويتر', icon: 'twitterF', color: '#555', onSelect: () => share('twitter')},
                {title: 'نسخ الرابط', icon: 'copy2F', color: '#555', onSelect: () => share('copy')},
            ]
        });
    }

    const openOptionsMenu = (e) => {

        const _options = [
            { title: 'مشاركة', icon: 'share', onSelect: () => {
                setTimeout(() => {
                    openShareBox(e);
                }, 20);
            }},
        ];

        if(isDataFetched) {
            if (data['id'].toString() === $myAccountState.userId) {
                _options.push({ title: 'تعديل البيانات', icon: 'settings', onSelect: () => {
                    navigate('/myAccount/settings');
                }});
            }
        }

        new DuOptionMenu().create({
            event: e,
            onCancel: () => {

            },
            options: _options
        });
    }

</script>

<div class="profile">
    <MainHeader />
    <div class="profileContainer">

        {#if !userNotFound}
            <div class="profileHeader">
                <div class="topArea">
                    <button class="optinosButton" on:click={openOptionsMenu}><IconsWorker icon="xDotMenuF" width="17px"/></button>
                </div>
                <div class="userThumb">
                    <div class="AvailabilityStatus"></div>
                    <IconsWorker icon="user" width="45px"/>
                    {#if isLoading}

                    {/if}
                </div>
                <div class="name">
                    <span>
                        {#if isLoading}
                            .........
                        {:else}
                            {data.name}
                        {/if}
                    </span>
                    {#if isDataFetched && data['organization_number'] !== null}
                        <div class="isOrganization">مؤسسة</div>
                    {/if}
                </div>
                <div class="rating">
                    <RatingBar rating="{isDataFetched ? 5 : 0}"/>
                </div>
            </div>

            <div class="topMenu">
                {#each menu as cell}
                    <a href="/profile/{$_userParams.serial}/{cell.page}" class="cell {isCatSelected(cell) ? 'selected' : ''}" use:link>
                        <IconsWorker color="#555" width="17px" icon="{cell.icon}"/>
                        <span>{cell.text}</span>
                    </a>
                {/each}
            </div>
        {:else}

            <div class="userNotFoundArea">
                <img src="/assets/images/userNotFound.png" />
                <span>لم يتم العثور على صفحة المستخدم</span>
                <Link to="/">
                    <DuButton text="الذهاب للصفحة الرئيسية" icon="home"/>
                </Link>
            </div>

        {/if}

        {#if !isLoading && isDataFetched}
            <div class="contentArea">
                {#if !$_userParams.page || $_userParams.page === 'services'}
                    <DuListView class="list" fragmentName="service" getParams="{{ user: data.id }}" fragment="{ServiceFragment}" scrollContainer={scrollContainer} />
                {:else}

                {/if}
            </div>
        {/if}

    </div>
</div>

<style>
    .profile{
        display: flex;
        flex-direction: column;
    }

    .profileContainer{
        margin-top: 50px;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    .profileHeader{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: available;
        padding: 20px 20px;
        background: #fff;
        border-radius: 12px 12px 0 0;
        align-items: center;
    }

    .profileHeader .topArea{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
    }

    .profileHeader .topArea button{
        padding: 10px;
        border: 0;
        border-radius: 100px;
        background: rgba(240, 144, 53, 0.3);
        cursor: pointer;
    }

    .profileHeader .topArea button:hover{ background: rgba(240, 144, 53, 0.5);}
    .profileHeader .topArea button:active{ background: rgba(240, 144, 53, 0.1);}

    .profileHeader .userThumb{
        width: 70px;
        height: 70px;
        background-color: #eee;
        border-radius: 100px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .profileHeader .userThumb .AvailabilityStatus{
        width: 15px;
        height: 15px;
        border-radius: 100px;
        background: #7EC46A;
        position: absolute;
        left: 2px;
        top: 10px;
    }

    .profileHeader .name{
        font-size: 18px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        position: relative;
    }
    .profileHeader .name .isOrganization{
        font-size: 13px;
        font-weight: bold;
        position: absolute;
        top: 0;
        width: 60px;
        left: -70px;
        text-align: center;
        background: #eee;
        padding: 5px 0;
        border-radius: 100px;
    }
    .profileHeader .rating{
        margin-top: 10px;
    }
    .topMenu{
        margin-top: 0px;
        width: available;
        background: #fff;
        display: flex;
        flex-direction: row;
    }

    .topMenu .cell{
        padding: 20px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
    .topMenu .cell span{
        margin-inline-start: 5px;
        font-size: 15px;
    }
    .topMenu .selected{
        border-bottom: 2px solid #F09035;
    }

    .contentArea{
        display: flex;
        flex-direction: column;
        margin-top: 7px;
        background: #fff;
    }

    .userNotFoundArea {
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }
    .userNotFoundArea img{
        width: 250px;
    }
    .userNotFoundArea span{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
    }
</style>