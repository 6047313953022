<script lang="ts">


    import IconsWorker from "../IconsWorker.svelte";
    import DuButton from "../DuButton.svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";

    export let isTakeImagesDialogOpen = false;
    export let orderId;
    export let takingImageAction;
    export let images = [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
    ];
    let viewedNow = '';

    let step = 0;
    let selectedPhotoIndex = 0;
    let fileInput;

    const onFileSelected = (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        upload(image, selectedPhotoIndex);
    }

    const upload = (file, index) => {
        images[index] = 'uploading';
        new ConnectionWorker().request(<any>{
            url: '/upload',
            filesParams: {
                'file': file,
            },
            onLoad: (response) => {
                console.log(response);
                if(response['status']) {
                    images[index] = response['serial'];
                } else {
                    images[index] = 'fail';
                }
            },
            onFail: (code) => {
                images[index] = 'fail';
            }
        });
    }

    const getTips = () => {
        if(takingImageAction === 'pickup') {
            return [
                {
                    title: 'صور المنتج',
                    info: 'حاول تصوير المنتج من جميع الإتجاهات , لضمان حقوقك في حال نشوب نزاع , نقوم بالرجوع لهذه الصور وللتأكد من حالة المنتج لحظة الاستلام , صور أي عيوب تلاحظها بالمنتج بصورة واضحة',
                    icon: 'cameraF'
                },
                {
                    title: 'افحص المنتج جيدًا',
                    info: 'تأكد من سلامة المنتج و خلوه من الأعطال , قم بتجربة المنتج قبل الاستلام',
                    icon: 'fragileGlassF'
                },
            ];
        } else {
            return [
                {
                    title: 'صور المنتج',
                    info: 'قم بتصوير المنتج كما تم إعادته لك',
                    icon: 'cameraF'
                },
                {
                    title: 'افحص المنتج جيدًا',
                    info: 'تأكد من سلامة المنتج و خلوه من الأعطال , قم بتجربة المنتج قبل الاستلام وتفاهم مع المستأجر حول أي أعطال بطريقة ودية إن وجد',
                    icon: 'fragileGlassF'
                },
            ];
        }
    }

    const removeImage = (index) => {
        images[index] = undefined;
    }

    const closeImageView = () => {
        viewedNow = '';
    }

    const openImage = (index) => {
        viewedNow = images[index];
    }

    $: isThereAnyImageUploading = () => {
        let val = false;
        images.forEach((image, index) => {
            if(!val) {
                if (image === 'uploading') {
                    val = true;
                }
            }
        });
        return val;
    }

    $: isAllowToSend = () => {
        let uploadedCount = 0;
        images.forEach((image, index) => {
            if(image && !isNaN(image)) {
                uploadedCount++;
            }
        });

        if(uploadedCount >= 3)
            return true;

        return false;
    }

    const closeMainDialog = () => {
        isTakeImagesDialogOpen = false;
    }

    const sendData = () => {
        if(isAllowToSend()) {

            let url = '/order/askForStart';
            if(takingImageAction === 'return')
                url = '/order/end';

            const _images = images.filter(image => !isNaN(image)).join();
            new ConnectionWorker().request(<any>{
                url: url,
                postParams: {
                    images: _images,
                    id: orderId,
                },
                onLoad: (response) => {

                    if(response['status']) {
                        isTakeImagesDialogOpen = false;
                    } else {
                        // todo: handdle this
                    }
                },
                onFail: (code) => {
                    // todo: handle errors
                }
            });
        }
    }

    $: gatEndButtonText = () => {
        if(takingImageAction === 'return')
            return 'تأكيد';
        return 'بدأ الحجز';
    };

</script>

{#if isTakeImagesDialogOpen}
    <input style="display:none" type="file" accept=".jpg, .jpeg, .png" on:change={(e) => onFileSelected(e)} bind:this={fileInput} />
    <div class="takeImagesDialog">
        <div class="dialogContainer">

            {#if step === 0}
                <div class="overlay">
                    <div class="closeButton brightnessGlobalHover" on:click={closeMainDialog}>
                        <IconsWorker icon="closeF" width="20px" color="#fff"/>
                    </div>
                    <div class="tips">
                        {#each getTips() as tip}
                            <div class="cell">
                                <div class="icon">
                                    <IconsWorker icon="{tip.icon}" width="20px" color="#fff"/>
                                </div>
                                <div class="infoSide">
                                    <span class="title">{tip.title}</span>
                                    <span class="info">{tip.info}</span>
                                </div>
                            </div>
                        {/each}
                    </div>
                    <div class="confirmMainButton" on:click={() => {
                        step++;
                    }}>
                        <DuButton text="إضافة الصور" fullWidth="{true}" icon="cameraF"/>
                    </div>
                </div>
            {:else if step === 1}
                <div class="overlay">
                    <div class="closeButton brightnessGlobalHover" on:click={closeMainDialog}>
                        <IconsWorker icon="closeF" width="20px" color="#fff"/>
                    </div>
                    <div class="imagesManager">
                        <div class="imagesGrid">
                            {#each images as image, index}
                                <div class="cell">

                                    <div class="selectImage" on:click={()=>{
                                        selectedPhotoIndex = index;
                                        fileInput.click();
                                    }}>
                                        {#if !image}
                                            <IconsWorker width="30px" color="#555" icon="plusF"/>
                                        {:else if image === 'uploading'}
                                            <IconsWorker width="30px" color="#555" icon="loading"/>
                                        {/if}
                                    </div>

                                    {#if image && !isNaN(image)}
                                        <div class="removeImage" on:click={() => removeImage(index)}><IconsWorker icon="minusF" color="#fff" width="20px"/></div>
                                        <div class="imageView" on:click={() => openImage(index)} style="background-image: url({ ConnectionWorker.rootUrl + '/file/' + image})"></div>
                                    {/if}

                                </div>
                            {/each}
                        </div>
                    </div>
                    <div class="confirmMainButton" on:click={() => {
                        sendData();
                    }}>
                        <DuButton text="{gatEndButtonText()}" fullWidth="{true}" icon="checkF" isLoading="{isThereAnyImageUploading()}" isDisabled="{!isAllowToSend()}"/>
                    </div>
                </div>
            {:else}
            {/if}


        </div>
    </div>

    {#if viewedNow !== ''}
        <div class="viewImageDialog">
            <div class="backdrop" on:click={closeImageView}></div>
            <div class="imageViewer" style="background-image: url({ ConnectionWorker.rootUrl + '/file/' + viewedNow })">
                <img src="{ ConnectionWorker.rootUrl + '/file/' + viewedNow }"/>
                <div class="closeImageView" on:click={closeImageView}><IconsWorker icon="closeF" width="17px" color="#fff"/></div>
            </div>
        </div>
    {/if}
{/if}

<style>
    .takeImagesDialog{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 0, 0, 0.5);
        display:flex;
        flex-direction: column;
        justify-content: center;
    }

    .dialogContainer{
        width: 30%;
        padding: 20px;
        border-radius: 12px;
        background: #fff;
        place-self: center;
        height: 400px;
        position: relative;
    }

    .overlay{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 12px;
    }

    .closeButton{
        width: 40px;
        height: 40px;
        background: #555;
        border-radius: 100px;
        margin: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        place-self: end;
    }

    .tips{
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .tips .cell{
        width: available;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: start;
    }

    .tips .cell .icon{
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #F09035;
        border-radius: 100%;
    }

    .tips .cell .infoSide{
        width: available;
        margin-inline-start: 10px;
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .tips .cell .infoSide .title{
        font-size: 18px;
        color: #222;
    }
    .tips .cell .infoSide .info{
        font-size: 15px;
        margin-top: 5px;
        color: #666;
    }

    .confirmMainButton{
        width: available;
        padding: 20px;
    }

    .imagesManager{
        display: flex;
        flex-direction: column;
        width: available;
        flex: 1;
        margin: 20px;
    }

    .imagesManager .imagesGrid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .imagesManager .imagesGrid .cell{
        height: 100px;
        background-color: #eee;
        margin: 2px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        position: relative;
        cursor: pointer;
        background-position: center;
        background-size: cover;
    }

    .imagesManager .imagesGrid .cell .selectImage{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
    }

    .imagesManager .imagesGrid .cell .removeImage{
        height: 30px;
        width: 30px;
        position: absolute;
        background-color: red;
        border-radius: 100px;
        margin: 10px;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        z-index: 5;
    }

    .imagesManager .imagesGrid .cell .removeImage:hover{
        opacity: 0.5;
    }


    .imagesManager .imageView{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-size: cover;
        background-position: center;
    }


    .viewImageDialog{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        display:flex;
        flex-direction: column;
        justify-content: center;
    }

    .viewImageDialog .backdrop{background: rgba(0, 0, 0, 0.5);width: 100%;height: 100%;position: fixed;}

    .viewImageDialog .imageViewer{
        place-self: center;
        align-self: center;
        min-width: 100px;
        min-height: 100px;
        border-radius: 10px;
        background: #000;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .viewImageDialog .imageViewer .closeImageView{
        width: 40px;
        height: 40px;
        border-radius: 100px;
        margin: 20px;
        background: red;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        place-items: center;
        place-content: center;
        cursor: pointer;
    }

    .viewImageDialog .imageViewer .closeImageView:hover{ opacity: 0.9; }

    .viewImageDialog .imageViewer img{
        max-width: 100%;
        max-height: 100%;
        padding: 0;
        margin: 0;
        visibility:hidden;
    }
</style>