import Cookies from 'js-cookie';
import ConnectionWorker from "./ConnectionWorker";
export default class default_1 {
    constructor() {
        this.buildPostParams = (postParams, filesParams) => {
            const formData = new FormData();
            let isAddItem = false;
            if (postParams !== undefined) {
                isAddItem = true;
                Object.keys(postParams).forEach((key) => {
                    formData.append(key, postParams[key]);
                });
            }
            if (filesParams !== undefined) {
                isAddItem = true;
                Object.keys(filesParams).forEach((key) => {
                    formData.append(key, filesParams[key]);
                });
            }
            if (isAddItem)
                return formData;
            return '';
        };
        this.request = ({ url = '/', jsonDecodeResponse = true, onLoad = undefined, onFail = undefined, postParams = undefined, getParams = undefined, filesParams = undefined }) => {
            const getParamsAsString = getParams !== undefined ? '?' + new URLSearchParams(getParams).toString() : '';
            const postParamsAsString = postParams !== undefined || filesParams !== undefined ? this.buildPostParams(postParams, filesParams) : '';
            const headers = {};
            const authKey = Cookies.get('authKey');
            const userId = Cookies.get('userId');
            if (authKey !== undefined && userId !== undefined) {
                headers['Authorization'] = 'Bearer ' + authKey;
            }
            const fetchRes = fetch(ConnectionWorker.rootUrl + url + getParamsAsString, {
                method: postParamsAsString === '' ? 'GET' : 'POST',
                body: postParamsAsString === '' ? null : postParamsAsString,
                headers: headers,
            });
            fetchRes.then((res) => {
                if (res.status === 200) {
                    if (onLoad !== undefined) {
                        if (jsonDecodeResponse) {
                            res.json().then(jsonRes => {
                                onLoad(jsonRes);
                            });
                        }
                        else {
                            onLoad(res);
                        }
                    }
                }
                else {
                    if (onFail !== undefined) {
                        onFail(res.status);
                    }
                }
            });
        };
        if (window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1")) {
            ConnectionWorker.rootUrl = 'https://inkedoo.com/api';
        }
        else {
            ConnectionWorker.rootUrl = 'https://inkedoo.com/api';
        }
    }
}
default_1.rootUrl = '';
//static rootUrl = 'https://inkedoo.com/api';
default_1.websocketRootUrl = 'ws://localhost:8090';
