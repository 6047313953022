import { bubble, listen } from 'svelte/internal';
export function forwardEventsBuilder(component, additionalEvents = []) {
    const events = [
        'focus', 'blur',
        'fullscreenchange', 'fullscreenerror', 'scroll',
        'cut', 'copy', 'paste',
        'keydown', 'keypress', 'keyup',
        'auxclick', 'click', 'contextmenu', 'dblclick', 'mousedown', 'mouseenter', 'mouseleave', 'mousemove', 'mouseover', 'mouseout', 'mouseup', 'pointerlockchange', 'pointerlockerror', 'select', 'wheel',
        'drag', 'dragend', 'dragenter', 'dragstart', 'dragleave', 'dragover', 'drop',
        'touchcancel', 'touchend', 'touchmove', 'touchstart',
        'pointerover', 'pointerenter', 'pointerdown', 'pointermove', 'pointerup', 'pointercancel', 'pointerout', 'pointerleave', 'gotpointercapture', 'lostpointercapture',
        ...additionalEvents
    ];
    function forward(e) {
        bubble(component, e);
    }
    return node => {
        const destructors = [];
        for (let i = 0; i < events.length; i++) {
            destructors.push(listen(node, events[i], forward));
        }
        return {
            destroy: () => {
                for (let i = 0; i < destructors.length; i++) {
                    destructors[i]();
                }
            }
        };
    };
}
