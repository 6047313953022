<script lang="ts">

    import SwitchTabs from "../../Components/Controllers/SwitchTabs.svelte";
    import DuListView from "../../Components/DuListView.svelte";
    import DashboardFixedPage from "../../Fragments/DashboardFixedPageFragment.svelte";

    let langIndex = 0;
    const supportedLanguages = ['ar', 'en'];
    const getLangsCells = () => {
        return [
            { title: 'عربي', onTap: () => { langIndex = 0; } },
            { title: 'English', onTap: () => { langIndex = 1; } },
        ];
    }

    $:getGetParams = () => {
        return { 'language': supportedLanguages[langIndex], };
    }

    let scrollContainer;
    scrollContainer = document.body;

    //privacyPolicy

</script>

<div class="fixedPages">
    {#if supportedLanguages.length > 0}
        <div class="languageSelector">
            <div class="langsCellsContainer">
                <SwitchTabs  foreground="#555" cells={getLangsCells()} bind:selectedIndex={langIndex}/>
            </div>
        </div>
    {/if}


    {#key getGetParams()}
        <DuListView class="pagesList" fragmentName="fixedPages" getParams="{getGetParams()}" scrollContainer={scrollContainer} let:data>
            <DashboardFixedPage slot="fragment" data="{data}"/>
        </DuListView>
    {/key}
</div>

<style>
    .fixedPages{
        display: flex;
        flex-direction: column;
        padding: 20px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px #ccc;
    }

    :global(.pagesList){
        margin-top: 50px;
        display: grid;
        gap: 1px;
        grid-template-columns: repeat(3, 1fr);
    }
</style>