<script lang="ts">
import { dndzone, SOURCES, TRIGGERS	 } from 'svelte-dnd-action';
import { flip } from 'svelte/animate';
import IconsWorker from "../IconsWorker.svelte";
import DuButton from "../DuButton.svelte";
import ConnectionWorker from "../../Utils/ConnectionWorker";
import {onMount} from "svelte";
import TagsGrapper from "../TagsGrapper.svelte";
import DuCodeSnipper from "./DuCodeSnipper.svelte";
import TwitterThreadImporter from "./TwitterThreadImporter.svelte";
import DuOptionMenu from "../DuOptionsMenu/DuOptionsMenu";
import EmpededItem from "./EmpededItem.svelte";
import ContentSoundRecorder from "./ContentSoundRecorder.svelte";

const formatBlocks = [
    { title: 'فقرة', icon: 'paragraphF', tag: 'p', placeHolder: 'قم بالكتابة هنا', attrs: {}},
    { title: 'عنوان كبير', icon: 'h1F', tag: 'h1', placeHolder: 'قم بالكتابة هنا', attrs: {}},
    { title: 'عنوان صغير', icon: 'h2F', tag: 'h2', placeHolder: 'قم بالكتابة هنا', attrs: {}},
    { title: 'قائمة غير مرقمة', icon: 'bulletedListF', tag: 'ul', placeHolder: 'قم بالكتابة هنا', attrs: {}},
    { title: 'قائمة مرقمة', icon: 'numberedListF', tag: 'ol', placeHolder: 'قم بالكتابة هنا', attrs: {}},
    { title: 'كود', icon: 'codeF', tag: 'code', placeHolder: 'قم بالكتابة هنا', attrs: {}},
    { title: 'صورة', icon: 'cameraF', tag: 'imageFile', placeHolder: 'انقر هنا لإختيار صورة', attrs: { src: '', state: 'uploading', alt: '', fileInput: null, }},
    { title: 'فيديو', icon: 'videoCallF', tag: 'videoFile', placeHolder: 'انقر هنا لإختيار صورة', attrs: { src: '', state: 'uploading', alt: '', fileInput: null, }},
    { title: 'إقتباس', icon: 'commaF', tag: 'q', placeHolder: 'قم بالكتابة هنا', attrs: {}},
    { title: 'فيديو يوتيوب', icon: 'youtubeFullWordF', tag: 'youtubeVideo', placeHolder: 'قم بكتابة رابط الفيديو هنا', attrs: {}},
]

let postImageInput;
export let postId = '';
export let postImage = '';
export let title:string = '';
export let subTitle:string = '';
export let tags:string = '';
export let tagsValue:string = '';
export let textBlocks:Array<any> = [];
let lastFocusTagIndex = null;

$: getPostImageToShow = () => {
    if(Number(postImage)) {
        return ConnectionWorker.rootUrl + '/file/' + postImage;
    }
    return postImage;
}

const merge = (a, b, i=0) => [...a.slice(0, i), ...b, ...a.slice(i)];

const createTextBlock = (formatBlock) => {

    let newId = Math.floor(Math.random() * 100000);
    let newBlock = {
        tag: formatBlock.tag,
        attrs: formatBlock.attrs,
        value: '',
        id: newId,
    };

    if(lastFocusTagIndex === null) {
        textBlocks = [...textBlocks, newBlock];
    } else {
        textBlocks = merge(textBlocks, [newBlock], lastFocusTagIndex+1)
    }

    if(formatBlock.tag === 'imageFile' || formatBlock.tag === 'videoFile') {
        uploadImage(newId);
    }


    setTimeout(() => {
        if(lastFocusTagIndex !== null) {
            document.getElementById('mainTextArea'+(lastFocusTagIndex+1)).focus();
        } else {
            document.getElementById('mainTextArea'+0).focus();
        }
        reInitAutoTextAreaResize();
    }, 100);
}

const uploadImage = (blockId) => {

    let block = {};
    textBlocks.forEach(async (_block, index) => {
        if(_block.id === blockId && _block.attrs.state !== 'uploaded') {
            await new ConnectionWorker().request(<any>{
                url: '/upload',
                filesParams: {
                    'file': _block.attrs.file,
                },
                onLoad: (response) => {

                    textBlocks[index].attrs = {
                        ...{ src: response.serial, file: '', state: 'uploaded'}
                    };
                },
                onFail: (errorCode) => {
                    setTimeout(() => {
                        uploadImage(blockId);
                    }, 3000);
                }
            });
        }
    });
}

const getPlaceHolder = (tag:string) => {
    let placeHolder = '';
    formatBlocks.forEach((block) => {
        if(block.tag == tag)
            placeHolder = block.placeHolder + '(' + block.title + ')';
    });
    return placeHolder;
}

const reInitAutoTextAreaResize = () => {
    const tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
        tx[i].removeEventListener("input", onTextAreaChange, false);
        tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
        tx[i].addEventListener("input", onTextAreaChange, false);
    }
}

function onTextAreaChange() {
    this.style.height = "auto";
    this.style.height = (this.scrollHeight) + "px";
}

const onFocus = (index:number) => {
    lastFocusTagIndex = index;
}

const getLinesCount = (text:string) => {
    let lines = text.split(/\r|\r\n|\n/);
    return  lines.length;
}


const flipDurationMs = 200;
let dragDisabled = true;

const handleConsider = evt => {
    textBlocks = evt.detail.items;
};
const handleFinalize = evt => {
    textBlocks = evt.detail.items;
    // Ensure dragging is stopped on drag finish
    dragDisabled = true;
};

const startDrag = () => {
    dragDisabled = false;
};
const stopDrag = () => {
    dragDisabled = true;
};

const remove = (item) => {
    const index = textBlocks.indexOf(item);
    textBlocks = textBlocks.filter((c,i) => c.id !== item.id)
}

let imagesFileInput;
let videosFileInput;

const onVideosFileSelected = async (e) => {

}

const onFileSelected = async (ev, blockType) => {
    let image = ev.target.files[0];
    let reader = new FileReader();
    await reader.readAsDataURL(image);
    reader.onload = e => {
        const result = e.target.result
        let imageFormatBlock;
        let videoFormatBlock;
        formatBlocks.forEach((block) => {

            if(blockType == 'imageFile' && block.tag === 'imageFile') {
                imageFormatBlock = block;
                    imageFormatBlock.attrs.src = result;
                    imageFormatBlock.attrs.file = image;

            } else if(blockType == 'videoFile' && block.tag === 'videoFile') {
                videoFormatBlock = block;
                    videoFormatBlock.attrs.src = result;
                    videoFormatBlock.attrs.file = image;


            }
        });

        if(imageFormatBlock != undefined) {
            createTextBlock(imageFormatBlock);
        }
        if(videoFormatBlock != undefined) {
            createTextBlock(videoFormatBlock);
        }
    }
}


const onPostImageFileSelected = (e) => {
    let image = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = e => {
        postImage = e.target.result
        console.log(e.target);
        new ConnectionWorker().request(<any>{
            url: '/upload',
            filesParams: {
                'file': image,
            },
            onLoad: (response) => {
                if(response.status) {
                    postImage = response.serial;
                } else {

                }
            }
        });
    };
}

//
$: {
    setTimeout(() => {
        reInitAutoTextAreaResize();
    }, 200);
}

let tagInput:Element;

const removeTag = (tag) => {
    const tagsAsArray = tags.split(',').filter(element => element);
    const index = tagsAsArray.indexOf(tag);
    if (index !== -1) {
        tagsAsArray.splice(index, 1);
    }
    tags = tagsAsArray.toString();
}


let isStudioModeOn = false;
const startStudioMode = () => {
    isStudioModeOn = !isStudioModeOn;
}

const changeBlock = (blockId, newTag) => {
    const _options = [];
    formatBlocks.forEach((item) => {
        if (['p', 'h1', 'h2', 'ul', 'ol', 'q'].includes(item.tag)) {
            _options.push({ title: item.title, icon: item.icon, color: '#000', onSelect: () => {
                textBlocks.forEach((textBlock, index) => {
                    if(textBlocks[index].id == blockId) {
                        textBlocks[index].tag = item.tag;
                    }
                });
            }});
        }
    });
    new DuOptionMenu().create({
        event: event,
        onCancel: () => {

        },
        options: _options
    });
}

</script>

<div class="DuTextEditor">

    <div class="changeToSoundStudioMode" on:click={startStudioMode}>
        <DuButton text={isStudioModeOn ? "وضع الإستديو الصوتي" : "إنهاء وضع الإستديو الصوتي"} icon="studioModeF" background="#555"/>
    </div>

    <input style="display:none" type="file" accept=".jpg, .jpeg, .png" on:change={(e)=>onFileSelected(e, 'imageFile')} bind:this={imagesFileInput} />
    <input style="display:none" type="file" accept=".mp4" on:change={(e)=>onFileSelected(e, 'videoFile')} bind:this={videosFileInput} />


    <div class="postInfo">
        <div class="postThumb" on:click={postImageInput.click()} style="{postImage ? 'background-image: url(' + getPostImageToShow() + ');' : ''}">
            <div class="placeHolder {postImage? 'hoverImagePlaceHolder' : ''}">
                <IconsWorker icon="camera" width="20px" color="#000"/>
                <span>{postImage? 'تغيير الصورة' : 'إضافة صورة'}</span>
                <input bind:this={postImageInput} type="file" accept=".jpg, .jpeg, .png" on:change={(e)=>onPostImageFileSelected(e)}/>
            </div>
        </div>
        <div class="postInfoSide">
            <input placeholder="عنوان المقال (قم بكتابة العنوان هنا)" class="title" bind:value={title}/>
            <input placeholder="عنوان جانبي أو وصف (اختياري)" class="subTitle" bind:value={subTitle}/>
            <input placeholder="الوسوم, مثال : (تاريخ , برمجة ... )" class="tagsInput" bind:value={tagsValue} bind:this={tagInput} />
            <div class="tags">
                <TagsGrapper bind:keyWord="{tagsValue}" tagInput={tagInput} onSelect="{ (tag) => {
                    tags = tags + ',' + (tag.title);
                }}"/>
            </div>
            <div class="selectedTags">
                {#each tags.split(',').filter(element => element) as tag}
                    <div class="tag">
                        <span>{tag}</span>
                        <div class="removeArea" on:click={() => removeTag(tag)}><IconsWorker icon="closeF" color="#fff" width="13px"/></div>
                    </div>
                {/each}
            </div>
        </div>
    </div>

    <div class="DuTextEditorSectionsSelectors">
        {#each formatBlocks as formatBlock}
            <div class="cell" on:click={(e) => {
                if(formatBlock.tag === 'imageFile') {
                    imagesFileInput.click();
                } else if(formatBlock.tag === 'videoFile') {
                    videosFileInput.click();
                } else {
                    createTextBlock(formatBlock);
                }

            }}>
                <IconsWorker icon="{formatBlock.icon}" width="50%;" color="#555"/>
            </div>
        {/each}
    </div>


    <div class="selectedTextBlocksContainer"
         use:dndzone="{{ items:textBlocks, dragDisabled, flipDurationMs }}"
         on:consider="{handleConsider}"
         on:finalize="{handleFinalize}">
        {#each textBlocks as textBlock,i (textBlock.id)}

            <div class="cell" animate:flip="{{ duration: flipDurationMs }}">
                <div class="cellContentSection">
                    {#if !isStudioModeOn}
                        <div class="cellTypeIcon" on:click={() => changeBlock(textBlock.id, textBlock.tag)}>
                            {#each formatBlocks as formatBlock}
                                {#if formatBlock.tag === textBlock.tag}
                                    <IconsWorker icon="{formatBlock.icon}" width="50%;" color="#555"/>
                                {/if}
                            {/each}
                        </div>
                    {/if}

                    <svelte:element this="{textBlock.tag}">
                        {#if ['p', 'h1', 'h2', 'ul', 'ol', 'q'].includes(textBlock.tag)}
                            {#if ['ul', 'ol'].includes(textBlock.tag)}
                                <div class="listCursors">
                                    {#each {length: getLinesCount(textBlock.value)} as _, i}
                                        <div class="cursorCell">{textBlock.tag == 'ol' ? i+1 : '•'}</div><br>
                                    {/each}
                                </div>
                            {/if}
                            <textarea rows="1" id="mainTextArea{i}" placeholder="{getPlaceHolder(textBlock.tag)}" bind:value={textBlock.value} on:focus={() => onFocus(i) }></textarea>
                        {:else if ['code'].includes(textBlock.tag)}

                            <DuCodeSnipper bind:value={textBlock.value} blockProps={textBlock}/>

                        {:else if ['youtubeVideo'].includes(textBlock.tag)}

                            <EmpededItem bind:value={textBlock.value} blockProps={textBlock} service="{textBlock.tag}"/>

                        {:else if ['imageFile'].includes(textBlock.tag)}

                            <div class="imageFile">
                                {#if textBlock.attrs.state === 'uploading'}
                                    <div class="imageOverlay">
                                        <IconsWorker color="#999" width="30px" icon="loading"/>
                                    </div>
                                {/if}
                                {#if (textBlock.attrs.src)}
                                    <img src={textBlock.attrs.state === 'uploaded' ?  ConnectionWorker.rootUrl + '/file/' + textBlock.attrs.src: textBlock.attrs.src} alt="{textBlock.attrs.alt}"/>
                                {/if}
                            </div>

                        {:else if ['videoFile'].includes(textBlock.tag)}

                            <div class="imageFile">
                                {#if textBlock.attrs.state === 'uploading'}
                                    <div class="imageOverlay">
                                        <IconsWorker color="#999" width="30px" icon="loading"/>
                                    </div>
                                {/if}
                                {#if (textBlock.attrs.src)}
                                    <video class="videoPlayer" src={textBlock.attrs.state === 'uploaded' ?  ConnectionWorker.rootUrl + '/file/' + textBlock.attrs.src: textBlock.attrs.src} alt="{textBlock.attrs.alt}" controls/>
                                {/if}
                            </div>

                        {/if}
                        <div class="blockControls">
                            <div class="controlItem brightnessGlobalHover" style="background: #FF3C52; " on:click={(e) => { remove(textBlock) }}>
                                <IconsWorker icon="deleteF" width="15px" color="#fff"/>
                            </div>
                            <div class="controlItem brightnessGlobalHover handle" style="background: #fff; " on:mousedown={startDrag} on:touchstart={startDrag} on:mouseup={stopDrag} on:touchend={stopDrag}>
                                <IconsWorker icon="moveTopBottomF" width="15px" color="#555"/>
                            </div>
                        </div>
                    </svelte:element>
                </div>
                {#if isStudioModeOn}
                    <div class="studioModeSectionControllers">
                        <ContentSoundRecorder bind:serial={textBlock.audioDescription} />
                    </div>
                {/if}
            </div>
        {/each}
    </div>
</div>


<style>
    .DuTextEditor{
        width: 100%;
        height: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
    }

    .postInfo{
        width: 100%;
        margin: 20px 0;
        display: flex;
        flex-direction: row;
        place-items: center;
    }
    .postInfo .postInfoSide{
        width: available;
        display: flex;
        flex-direction: column;
        margin-inline-start: 15px;
        flex: 1;
    }
    .postInfo .postInfoSide input{
        width: 100%;
        padding: 0;
        background-color: transparent;
        border: 0;
    }
    .postInfo .postInfoSide .title{
        font-size: 17px;
        color: #222;
        font-weight: bold;
    }
    .postInfo .postInfoSide .subTitle{
        font-size: 16px;
        color: #333;
    }
    .postInfo .postInfoSide .tagsInput{
        font-size: 16px;
        color: #F09035;
    }

    .postInfo .postInfoSide .tags{
        width: available;
        min-height: 5px;
        display: flex;
        position: relative;
    }

    .postInfo .postInfoSide .selectedTags{
        width: available;
        display: flex;
        flex-direction: row;
    }

    .postInfo .postInfoSide .selectedTags .tag{
        padding: 2px;
        background: #000;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        border-radius: 100px;
        margin-inline-end: 7px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .postInfo .postInfoSide .selectedTags .tag span{
        margin-inline-start: 15px;
    }
    .postInfo .postInfoSide .selectedTags .tag .removeArea{
        width: 30px;
        height: 30px;
        border-radius: 100px;
        background: transparent;
        margin-inline-start: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .postInfo .postInfoSide .selectedTags .tag:hover .removeArea{
        background: #FF3C52;
        cursor: pointer;
    }

    .postInfo .postInfoSide .selectedTags .removeArea:hover{
        opacity: 0.9;
    }
    .postInfo .postInfoSide .selectedTags .removeArea:active{
        opacity: 0.5;
    }

    .postInfo .postThumb{
        width: 90px;
        height: 90px;
        background-color: #ccc;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        place-items: center;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        background-size: cover;
        background-position: center;
    }

    .postInfo .postThumb .placeHolder{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        place-items: center;
        justify-content: center;
    }

    .postInfo .postThumb span{
        font-size: 13px;
        font-weight: bold;
        margin: 5px;
        text-align: center;
    }

    .postInfo .postThumb input{
        display: none;
    }


    .postInfo .postThumb .hoverImagePlaceHolder{
        display: none;
    }

    .postInfo .postThumb:hover .hoverImagePlaceHolder{
        display: flex;
    }

    .DuTextEditorSectionsSelectors{
        top: 70px;
        height: 50px;
        width: 100%;
        margin-top: 10px;
        position: sticky;
        background: #fff;
        border-radius: 100px;
        z-index: 120;
        box-shadow: 0 0 10px #ccc;
        display: flex;
        flex-direction: row;
    }

    .DuTextEditorSectionsSelectors .cell{
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        border-radius: 100px;
        margin: 5px;
        place-items: center;
        place-content: center;
        cursor: pointer;
    }

    .DuTextEditorSectionsSelectors .cell:hover{
        background: #eee;
    }
    .DuTextEditorSectionsSelectors .cell:active{
        background: #ccc;
    }

    .selectedTextBlocksContainer{
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .selectedTextBlocksContainer .cell{
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .selectedTextBlocksContainer .cell .cellContentSection{
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .selectedTextBlocksContainer *{
        flex: 1;
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
    }

    textarea{
        background: transparent;
        flex: 1;
        border: 0;
        padding: 0;
        margin: 0;
        resize: none;
        line-height:1.4;
    }

    .selectedTextBlocksContainer ul{
        line-height: 50px;
    }

    .selectedTextBlocksContainer .cell .studioModeSectionControllers{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }


    .listCursors{
        width: 20px;
        display: flex;
        flex-direction: column;
        flex: 0;
        margin-inline-end: 5px;
        color: #999;
    }

    .listCursors .cursorCell{
        width: 20px;
        height: 20px;
        display: flex;
        place-content: center;
        place-items: center;
    }

    .blockControls{
        display: flex;
        flex-direction: row;
        place-items: center;
        flex: 0;
    }

    .blockControls .controlItem{
        display: flex;
        flex-direction: row;
        place-items: center;
        place-content: center;
        width: 35px;
        height: 35px;
        background: #fff;
        border-radius: 100px;
        box-shadow: 0 0 5px #ccc;
        margin-inline-start: 10px;
        cursor: grab;
    }

    .imageFile{
        display: flex;
        flex-direction: column;
        place-items: center;
        place-content: center;
        min-width: 400px;
        min-height: 250px;
        position: relative;
        text-align: center;
    }

    .imageFile img{
        max-height: 500px;
        width: auto;
    }

    .imageFile .imageOverlay{
        background: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        box-shadow: 0 0 10px #ccc;
        place-self: center;
        place-content: center;
        place-items: center;
        padding: 20px;
        display: flex;
        flex-direction: column;
        place-content: center;
        position: absolute;
        width: auto;
    }

    .imageFile .hoverImagePlaceHolder{
        display: none;
    }

    .imageFile:hover .hoverImagePlaceHolder{
        display: flex;
    }

    .changeToSoundStudioMode{
        position: fixed;
        bottom: 50px;
        left: 15%;
    }

    .cellTypeIcon{
        width: 30px;
        height: 30px;
        background: #fff;
        flex: initial;
        margin-inline-end: 5px;
        border-radius: 100px;
        display: flex;
        place-items: center;
        place-content: center;
        cursor: pointer;
    }
    .cellTypeIcon:hover{ opacity: 0.9; }
    .cellTypeIcon:active{ opacity: 0.7; }

    .videoPlayer{
        max-width: 100%;
        width: auto;
    }

</style>