import 'animate.css';
export default class DuAnimation {
    static animate(options) {
        let removeClassAfter = options.duration;
        let elementDisplay = options.elementDisplay === undefined ? 'inherit' : options.elementDisplay;
        document.getElementById(options.id).style.animationDuration = options.duration.toString() + 'ms';
        if (options.delay !== undefined) {
            document.getElementById(options.id).style.animationDelay = options.delay.toString() + 'ms';
            removeClassAfter = removeClassAfter + options.delay;
        }
        document.getElementById(options.id).classList.add('animate__' + options.animation);
        if (options.looping !== undefined && options.looping) {
            document.getElementById(options.id).classList.add('animate__animated');
        }
        setTimeout(() => {
            if (document.getElementById(options.id) !== undefined && document.getElementById(options.id) !== null) {
                if (options.animation.includes('out') || options.animation.includes('Out')) {
                    document.getElementById(options.id).style.display = 'none';
                }
                else {
                    //document.getElementById(options.id).style.display = elementDisplay;
                }
                document.getElementById(options.id).classList.remove('animate__' + options.animation, 'animate__animated');
            }
        }, removeClassAfter);
    }
}
/*

example:
DuAnimation.animate({
    id: exampleIdHere,
    animation: 'zoomOut',
    duration: 500,
});

* */ 
