<script lang="ts">

import MainHeader from "../../Components/MainHeader.svelte";
import {Route, Router} from "svelte-navigator";
import DashboardSideBar from "../../Components/DashboardSideBar.svelte";
import Users from "./Users.svelte";
import Tags from "./Tags.svelte";
import {onMount} from "svelte";
import Faq from "./SupportCenter/Faq.svelte";
import SocialMedia from "./SupportCenter/SocialMedia.svelte";
import Messages from "./SupportCenter/Messages.svelte";
import FixedPages from "./FixedPages.svelte";
import FixedPagesEditor from "./FixedPagesEditor.svelte";

let url;
let sideBarLinks = [];
onMount(() => {
    sideBarLinks = [
        {title: 'المستخدمين', link: '/dashboard/users', icon: 'user' },
        {title: 'المنشورات', link: '/dashboard/posts', icon: 'openBox' },
        {title: 'الطلبات', link: '/dashboard/orders', icon: 'truck' },
        {title: 'المدفوعات و المعاملات المالية', link: '/dashboard/payments', icon: 'wallet2', isOpen: true, children: [
                {title: 'سجل المعاملات', link: '/dashboard/payments/history', icon: 'time'},
                {title: 'طلبات السحب و الاسترداد', link: '/dashboard/payments/withdrawAndRefunds', icon: 'withdrawal_limit'},
            ]},
        {title: 'الدعم الفني', link: '/dashboard/supportCenter', icon: 'lifebuoyFloat', isOpen: false, children: [
                {title: 'البلاغات', link: '/dashboard/supportCenter/issuesReports', icon: 'law'},
                {title: 'البريد الوارد', link: '/dashboard/supportCenter/messages', icon: 'message'},
                {title: 'الأسئلة الشائعة', link: '/dashboard/supportCenter/faq', icon: 'faq'},
                {title: 'بيانات التواصل', link: '/dashboard/supportCenter/socialMedia', icon: 'link'},
            ] },
        {title: 'الشؤون القانونية', link: '/dashboard/lawsCenter', icon: 'fingerPrint', isOpen: false, children: [
                {title: 'شروط الإستخدام', link: '/dashboard/lawsCenter/privacyPolicy', icon: 'terms'},
                {title: 'سياسة الخصوية', link: '/dashboard/lawsCenter/termsOfUsage', icon: 'eye'},
            ] },
        {title: 'الوسوم', link: '/dashboard/tags', icon: 'hashtag' },
        {title: 'الإعدادات', link: '/dashboard/settings', icon: 'settings' },
    ];
});
</script>

<MainHeader />
<div class="mainPage mainContainer">
    <div class="dashboard">
        <DashboardSideBar bind:sideBarLinks="{sideBarLinks}"/>
        <div class="contentSide">
            <Router url="{url}">

                <Route path="/users" component="{Users}" />
                <Route path="/tags" component="{Tags}" />

                <Route path="/supportCenter/faq" component="{Faq}" />
                <Route path="/supportCenter/socialMedia" component="{SocialMedia}" />
                <Route path="/supportCenter/messages" component="{Messages}" />

                <Route path="/lawsCenter" component="{FixedPages}" />
                <Route path="/lawsCenter/:key" component="{FixedPagesEditor}" />

            </Router>
        </div>
    </div>
</div>

<style>
    .dashboard{
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 50px;
    }
    .contentSide{
        flex: 1;
        width: available;
        display: flex;
        flex-direction: column;
    }
</style>