<script lang="ts">
    import DuCountryDialog from './DuCountryDialog.svelte';
    import DuInputInterface from './DuInputInterface.svelte';
    import IconsWorker from '../IconsWorker.svelte';
    import { onMount } from 'svelte';
    import {forwardEventsBuilder} from '../../Utils/forwardEvents';
    import {current_component} from 'svelte/internal';

    export let label:string = '';
    export let value:string = '';
    export let placeHolder:string = '';
    export let isValid:boolean = null;
    export let errorMessage:string = '';
    export let showErrorMessage:boolean = false;

    export let isRequired:boolean = false;
    export let isRequiredMessage:string = 'required';

    export let typeErrorMessage:string = 'invalid numeric value';

    export let minLength:number = null;
    export let minLengthMessage:string = 'minimum than ' + minLength;

    export let maxLength:number = null;
    export let maxLengthMessage:string = 'maximum than ' + maxLength;

    export let minValue:number = null;
    export let minValueMessage:string = 'minimum than ' + minValue;

    export let maxValue:number = null;
    export let maxValueMessage:string = 'maximum than ' + maxValue;

    export let stepFactory:number = 1;
    export let fixed:number = 1;
    export let allowToFloat:boolean = false;

    export let magnifier:number = 0;


    let isFocusedOut:boolean = false;

    const forwardEvents = forwardEventsBuilder(current_component);


    const onlyNumberKey = (evt) => {
        // Only ASCII character in that range allowed
        let ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;
    }

    const onKeyUp = () => {
        validate();
    }

    const onFocusOut = () => {

    }

    const isNumericOrFloat = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }


    export const validate = () => {
        let _isValid = true;

        if(isRequired) {
            if(value.length < 1) {
                _isValid = false;
                errorMessage = isRequiredMessage;
            }
        }

        if(_isValid && value.length > 0) {
            if(allowToFloat) {
                if (!isNumericOrFloat(value)) {
                    _isValid = false;
                    errorMessage = typeErrorMessage;
                }
            } else {
                if (!/^\d+$/.test(value)) {
                    _isValid = false;
                    errorMessage = typeErrorMessage;
                }
            }
        }

        if(_isValid && minLength !== null && value.length < minLength) {
            _isValid = false;
            errorMessage = minLengthMessage;
        }

        if(_isValid && maxLength !== null && value.length > maxLength) {
            _isValid = false;
            errorMessage = maxLengthMessage;
        }

        if(_isValid && minValue !== null && value < minValue) {
            _isValid = false;
            errorMessage = minValueMessage;
        }

        if(_isValid && maxValue !== null && value > maxValue) {
            _isValid = false;
            errorMessage = maxValueMessage;
        }

        isValid = _isValid;

        if (!isValid) {
            showErrorMessage = true;
        } else {
            showErrorMessage = false;
            errorMessage = '';
        }
    }

    const onChange = (e) => {
        setTimeout(() => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
        }, 100);
    }

    const increment = () => {
        if(value < maxValue) {
            if(allowToFloat)
                value = ((value*1) + stepFactory).toFixed(fixed);
            else
                value = ((value*1) + stepFactory)
            if(value > maxValue) {
                value = maxValue;
            }
            validate();
        }
    }

    const decrement = () => {
        if(value > minValue) {
            if(allowToFloat)
                value = ((value*1) - stepFactory).toFixed(fixed);
            else
                value = ((value*1) - stepFactory)
            if(value < minValue) {
                value = minValue;
            }
            validate();
        }
    }

</script>


<DuInputInterface errorMessage={showErrorMessage ? errorMessage : ''} {...$$props}>
    <div class="inputAndSteppers" slot="input">
        <input class="numberInput" bind:value placeholder="{placeHolder}" on:keyup={onKeyUp} on:input={onChange} inputmode="numeric" use:forwardEvents/>
        <div class="steppers">
            <button on:click={increment} class="{(value >= maxValue) ? 'disabled' : '' }"><IconsWorker icon="plusF" color="#fff" width="20px"/></button>
            <div class="stepperSplitter"></div>
            <button on:click={decrement} class="{(value <= minValue) ? 'disabled' : '' }"><IconsWorker icon="minusF" color="#fff" width="20px"/></button>
        </div>
    </div>
</DuInputInterface>



<style>
.inputAndSteppers{
    display: flex;
    flex-direction: row;
    width: available;
    flex: 1;
}

.numberInput{
    flex: 1;
    width: inherit;
    background: transparent;
    border: 0;
    place-self: center;
    padding: 0;
    margin: 0;
}

.steppers{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    width: min-content;
    height: min-content;
}
.steppers button{
    background: #F09035;
    width: min-content;
    height: min-content;
    margin: 0;
    padding: 7px;
    border-radius: 100px;
    border: 0;
    cursor: pointer;
}
.steppers button:hover{ background: #000; }
.steppers button:active{ background: #555; }
.stepperSplitter{ width: 10px; height: 10px;}
.disabled{ opacity: 0.6; pointer-events: none; }
</style>