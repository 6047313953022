<script lang="ts">

    import IconsWorker from "../../Components/IconsWorker.svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";
    import FixedNotifications from "../../Components/DuNotifications/FixedNotifications";
    import {onMount} from "svelte";
    import DuListView from "../../Components/DuListView.svelte";
    import QaQFragment from "../../Fragments/QaQFragment.svelte";

    const typesArray =  [
        {'key': 'providers', 'ar': 'أسئلة البائعين', 'en': 'Providers Questions'},
        {'key': 'buyers', 'ar': 'أسئلة المشترين', 'en': 'Buyer Questions'},
    ];
    let catIndex = typesArray[0]['key'];

    let scrollContainer;
    scrollContainer = document.body;

    $: getGetParams = () => {
        return {'language': 'ar', 'topic': catIndex};
    }

    const changeTap = (cat) => {
        catIndex = cat.key;
    }

</script>


<div class="faqBody">
    <div class="cats noTextSelect">
        {#each typesArray as cat}
            <div class="cell {catIndex === cat.key ? 'selectedCat' : ''}" on:click={() => changeTap(cat)}>{cat['ar']}</div>
        {/each}
    </div>
    <div class="questionsArea">
        {#key getGetParams()}
            <DuListView class="list" fragmentName="QaA" getParams="{getGetParams()}" scrollContainer={scrollContainer} let:data>
                <QaQFragment slot="fragment" data="{data}"/>
            </DuListView>
        {/key}
    </div>
</div>


<style>
    .faqBody{
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        background: #fff;
        width: 70%;
        margin: 0 15%;
        box-shadow: 0 0 10px #ccc;
    }

    .cats{
        width: available;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        border-radius: 12px 12px 0 0;
    }

    .cats .cell{
        flex: 1;
        border-bottom: 2px solid transparent;
        padding: 20px 20px;
        text-align: center;
        cursor: pointer;
        background: rgba(240, 144, 53, 0.06);
        color: #000;
        font-weight: bold;
        font-size: 16px;
    }

    .cats .cell:hover{ background: rgba(240, 144, 53, 0.1); }
    .cats .cell:active{ background: rgba(240, 144, 53, 0.2); }

    .cats .selectedCat{
        flex: 1;
        border-bottom: 2px solid rgba(240, 144, 53, 0.8);
        padding: 20px;
        text-align: center;
    }

    .questionsArea{
        display: flex;
        flex-direction: column;
    }

    .questionsArea .loading{
        place-self: center;
        align-self: center;
        padding: 100px;
    }

</style>