<script lang="ts">
    import IconsWorker from "../Components/IconsWorker.svelte";
    import {link} from 'svelte-navigator';

    export let data;

    let getIcon = () => {
        if(data.key === 'privacyPolicy')
            return 'eye';
        if(data.key === 'termsOfUsage')
            return 'termsF';
        return 'pageDown';
    }
</script>

<a href="/dashboard/lawsCenter/{data.key}?lang={data.language}" class="DashboardFixedPage noTextSelect" use:link>
    <div class="icon">
        <IconsWorker icon="{getIcon()}" width="50px" color="#555"/>
    </div>
    <div class="title">{data.title}</div>
</a>

<style>
    .DashboardFixedPage{
        background: #fff;
        display: flex;
        aspect-ratio: 1;
        margin: 5px;
        border-radius: 12px;
        box-shadow: 0 0 5px #ccc;
        padding: 20px;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .DashboardFixedPage:hover{ opacity: 0.8; }
    .DashboardFixedPage:active{ opacity: 0.5; }

    .DashboardFixedPage .icon{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .DashboardFixedPage .title{
        text-align: center;
        border-top: 1px solid #eee;
        width: 100%;
        padding-top: 20px;
        font-size: 15px;
        color: #555;
    }
</style>