<script lang="ts">
    import IconsWorker from '../IconsWorker.svelte';
    import { onMount } from 'svelte';

    export let countryCode:string = 'sd';
    export let phoneCode:string = '';
    export let countryName:string = '';
    export let lang:string = 'ar';
    export let isLoading:boolean = false;
    export let isDialogOpen:boolean = false


    let  countryPhoneCodesList:Array<any> = [];
    let  countryNamesCodesList:Array<any> = [];


    onMount(() => {
        fetch(`/assets/CountryPhoneCodes.json`).then(async (res) => {
            countryPhoneCodesList = await res.json();
            fetch(`/assets/countrysNames/${lang}.json`).then(async (res) => {
                countryNamesCodesList = await res.json();
                isLoading = false;
                initInfo();
                selectCountry(countryCode);
            });
        });
    });


    const getCountryName = (countryCode:string) => {
        let data:string = '';
        countryNamesCodesList.forEach((singleCountryData) => {
            if(singleCountryData.alpha2.toString().toLowerCase() === countryCode.toLowerCase()) {
                data = singleCountryData.name;
            }
        });
        if(data === '') {

        }
        return data;
    }


    const initInfo = () => {
        countryPhoneCodesList.forEach((item, key) => {
            if(item.code.toString().toLowerCase() === countryCode.toLowerCase()) {
                countryCode = item.code.toString();
                phoneCode = item.dial_code.toString();
            }
        });
    }

    const selectCountry = (_countryCode:string) => {
        countryCode = _countryCode;
        countryName = getCountryName(_countryCode);
        initInfo();
        closeSelectCountryDialog();
    }


    const closeSelectCountryDialog = () => {
        isDialogOpen = false;
    }


    const openSelectCountryDialog = () => {
        isDialogOpen = true;
    }


</script>

{#if isDialogOpen && !isLoading}
    <div class="selectCountryDialog">
        <div class="dialogBox">
            <div class="titleArea">
                <div class="title">اختر الدولة</div>
                <div class="closeDialogButton" on:click={() => closeSelectCountryDialog()}>
                    <IconsWorker icon="dismiss" color="#EA386C" width="30px"/>
                </div>
            </div>
            <div class="contentArea">
                <div style="margin-top: 10px;"></div>
                {#each countryPhoneCodesList as countryData}
                    {#if getCountryName(countryData['code'])}
                        <div class="cell" on:click={(e) => {
                            selectCountry(countryData['code']);
                        }}>
                            <img src="/assets/countrysFlags/{countryData['code'].toLowerCase()}.png" />
                            <span class="countryName">{getCountryName(countryData['code'])}</span>
                        </div>
                    {/if}
                {/each}
            </div>
        </div>
    </div>
{/if}

<style>

    .selectCountryDialog{
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 200;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        place-items: center;
        place-content: center;
        text-align: start;
    }

    .selectCountryDialog .dialogBox{
        width: 30%;
        height: 450px;
        background-color: #fff;
        box-shadow: 0 0 10px #ccc;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .selectCountryDialog .titleArea{
        width: 90%;
        padding: 15px 5%;
        border-bottom: 1px solid #ccc;
        display: flex;
        flex-direction: row;
        place-items: center;
    }

    .selectCountryDialog .titleArea .title{
        font-size: 15px;
        color: #555;
        flex: 1;
    }

    .selectCountryDialog .contentArea{
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }


    .selectCountryDialog .contentArea .cell{
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #eee;
        padding: 8px 5%;
        margin: 7px 5% 0 5%;
        border-radius: 5px;
        cursor: pointer;
        opacity: 0.7;
        color: #000;
    }

    .selectCountryDialog .contentArea .cell img{
        width: 25px;
    }

    .selectCountryDialog .contentArea .cell .countryName{
        flex: 1;
        margin-inline-start: 10px;
        font-size: 15px;
    }

    .selectCountryDialog .contentArea .cell:hover{
        opacity: 1;
    }

    .selectCountryDialog .contentArea .cell:active{
        opacity: 0.4;
    }

    :global(.closeDialogButton){
        cursor: pointer;
    }
    :global(.closeDialogButton):hover{
        opacity: 0.7;
    }
    :global(.closeDialogButton):active{
        opacity: 0.3;
    }
</style>