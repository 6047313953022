import DuOptionsMenuComponent from "./DuOptionsMenuComponent.svelte";
export const DuOptionMenuItem = class {
};
export default class DuOptionMenu {
    constructor() {
        this.create = (params) => {
            new DuOptionsMenuComponent({
                target: document.body,
                props: {
                    options: params,
                },
            });
        };
    }
}
/*
* example:
new DuOptionMenu().create({
    event: event,
    onCancel: () => {

    },
    options: [
        { title: 'الصفحة الشخصية', icon: 'close', color: '#ccc'},
        { title: 'إدارة تدويناتي', icon: 'user', color: '#000'},
        { title: 'تسجيل الخروج', onSelect: () => {
            alert('logout');
        }},
    ]
});
* */ 
