<script lang="ts">
    import DuListView from "../../Components/DuListView.svelte";
    import UserContentFragment from "../../Fragments/UserContentFragment.svelte";
    import MyAccountStore from "../../stores/MyAccountStore";
    import {onMount} from "svelte";

    $: myAccountState = MyAccountStore.state;
    let scrollContainer;
    scrollContainer = document.body;

    onMount(() => {
        setTimeout(() => {

        }, 200);
    });

</script>


<div class="myContent">
    <DuListView class="list" fragmentName="userContent" fragment="{UserContentFragment}" getParams="{{'user_id': $myAccountState.userId}}" scrollContainer={scrollContainer} />
</div>

<style>
    .myContent{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    :global(.list){
        display: flex;
        flex-direction: column;
        width: 100%;
    }
</style>