<script lang="ts">
    import IconsWorker from "../../Components/IconsWorker.svelte";
    import EditAccountDialog from "../../Components/EditAccountDialog.svelte";
    import FixedNotifications from "../../Components/DuNotifications/FixedNotifications";
    import myAccountStore from "../../stores/MyAccountStore";
    import MyAccountStore from "../../stores/MyAccountStore";
    import {onDestroy, onMount} from "svelte";
    import ConnectionWorker from "../../Utils/ConnectionWorker";

    const myAccountState = MyAccountStore.state;

    let isDialogOpen:boolean = false;
    let selectedCellItem = {};

    let cells = [];
    let  thumb, thumbInput, isUploadingThumb:boolean = false;

    const createNotification = (nOptions) => {
        new FixedNotifications().create({
            title: nOptions.title,
            subTitle: nOptions.subTitle,
            icon: nOptions.icon,
            color: nOptions.color,
            removeAfter: 4000,
        });
    }

    const initCells = () => {
        if($myAccountState.data !== null) {
            cells = [{
                label: 'الاسم',
                icon: 'user',
                key: 'name',
                currentValueString: $myAccountState.data.name,
                cellPlaceHolder: '',
                currentValue: 'fdddd',
                title: 'تغيير الاسم',
                onDone: (response) => {
                    if (response.status) {
                        createNotification({ title: 'تم تغيير الاسم بنجاح', icon: 'checkF', subTitle: '', color: 'green'});
                        new MyAccountStore().fetchData();
                    }
                }
            },
            {
                label: 'البايو',
                icon: 'info',
                key: 'bio',
                currentValueString: $myAccountState.data.bio === null ? '' : $myAccountState.data.bio,
                cellPlaceHolder: $myAccountState.data.bio === null || $myAccountState.data.bio === '' ? 'انقر لإضافة لمحة عنك' : $myAccountState.data.bio,
                currentValue: 'fdddd',
                title: 'تحديث البايو',
                onDone: (response) => {
                    if (response.status) {
                        createNotification({ title: 'تم تحديث البايو بنجاح', icon: 'checkF', subTitle: '', color: 'green'});
                        new MyAccountStore().fetchData();
                    }
                }
            }];
        }
    }

    const onFileSelected = (e:Event, type:string) => {
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        console.log(reader)
        reader.onload = e => {
            const result = e.target.result
            thumb = result;
            upload(image);
        }
    }

    const upload = (file) => {
        isUploadingThumb = true;
        new ConnectionWorker().request(<any>{
            url: '/user/updateThumb',
            filesParams: {
                'file': file,
            },
            onLoad: (response) => {
                if(response['status']) {
                    new MyAccountStore().fetchData();
                } else {
                    createNotification({ title: 'تم تغيير الصورة الشخصية بنجاح', icon: 'checkF', subTitle: '', color: 'green'});
                }
                isUploadingThumb = false;
            }
        });
    }

    $: getThumbImageToShow = () => {
        if(isUploadingThumb)
            return '';
        if(thumb !== undefined)
            return thumb;
        if($myAccountState.data !== null) {
            if (Number($myAccountState.data.thumb)) {
                return ConnectionWorker.rootUrl + '/file/' + $myAccountState.data.thumb;
            }
        }
        return "/assets/images/thumb.png";
    }


    myAccountState.subscribe(() => {
        initCells();
    });



    onMount(() => {});

</script>

{#if isDialogOpen}
    <EditAccountDialog options="{selectedCellItem}" onClose="{() => isDialogOpen = false }" onDone="{ selectedCellItem.onDone }"/>
{/if}

<div class="Settings">
    <div class="thumbSide brightnessGlobalHover" on:click={()=>{thumbInput.click();}}>
        <div class="thumb" style="{'background-image: url(' + getThumbImageToShow() + ');'}">
            {#if (!isUploadingThumb)}
                <div class="overlay">
                    <IconsWorker icon="camera" width="30px"/>
                </div>
            {:else}
                <div class="uploadingThumb">
                    <IconsWorker icon="loading" width="30px"/>
                </div>
            {/if}
        </div>
        <div class="placeHolder">{isUploadingThumb ? 'جار رفع الصورة' : 'انقر لتغيير الصورة'}</div>
        <input style="display:none" type="file" accept=".jpg, .jpeg, .png" on:change={(e)=>onFileSelected(e, 'thumb')} bind:this={thumbInput} >
    </div>
    <div class="settingsSide">
        {#each cells as cell}
            <div class="cell" on:click={() => {
                selectedCellItem = cell;
                isDialogOpen = true;
            }}>
                <div class="keyAndIcon">
                    <IconsWorker icon="{cell.icon}" width="17px" color="#555"/>
                    <div class="key">{cell.label}</div>
                </div>
                <div class="currentValue">
                    {#if cell.cellPlaceHolder !== ''}
                        <div class="value placeHolder">{cell.cellPlaceHolder}</div>
                    {:else}
                        <div class="value">{cell.currentValueString}</div>
                    {/if}
                    <div class="editButton">
                        <IconsWorker icon="pen" width="17px" color="#111"/>
                    </div>
                </div>
            </div>
        {/each}
    </div>
</div>

<style>
.Settings{
    width: available;
    display: flex;
    flex-direction: row;
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 10px #ccc;
}

.thumbSide{
    width: auto;
    height: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.thumbSide .uploadingThumb{
    width: 100%;
    height: 100%;
    display: flex;
    place-items: center;
    place-content: center;
}

.thumbSide .placeHolder{
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #777;
}

.thumbSide .thumb{
    width: 80px;
    height: 80px;
    background-color: #eee;
    border-radius: 100px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.thumbSide .thumb .overlay{
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: none;
    flex-direction: column;
    place-content: center;
    place-items: center;
}

.thumbSide:hover .overlay{
    display: flex;
}

.settingsSide{
    flex: 1;
    width: available;
    display: flex;
    flex-direction: column;
    border-inline-start: 1px solid #eee;
    margin-inline-start: 20px;
    padding-inline-start: 20px;
}

.cell{
    display: flex;
    width: available;
    border-bottom: 1px solid #eee;
    flex-direction: column;
    padding: 10px 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.cell:hover{
    background: rgba(240, 144, 53, 0.1);
    border-radius: 12px;
    border: 0;
}

.cell .keyAndIcon{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}
.cell .keyAndIcon .key{
    margin-inline-start: 5px;
    color: #555;
    font-size: 16px;
}
.cell .currentValue{
    font-size: 17px;
    color: #222;
    margin-top: 5px;
    margin-inline-start: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cell .currentValue .value{
    flex: 1;
}
.cell .placeHolder{
    color: #F09035;
    font-size: 12px;
    font-weight: bold;
}
.cell .currentValue .editButton{

}
</style>