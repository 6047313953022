<script lang="ts">

    import DuListView from "../../../Components/DuListView.svelte";
    import DashboardSupportMessageFragment from "../../../Fragments/DashboardSupportMessageFragment.svelte";
    let scrollContainer;
    scrollContainer = document.body;

</script>

<div class="messages">
    <DuListView class="list" fragment="{DashboardSupportMessageFragment}" fragmentName="supportMessage" getParams="{{}}" scrollContainer={scrollContainer} />
</div>

<style>
    .messages{
        display: flex;
        flex-direction: column;
        padding: 20px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px #ccc;
    }
</style>