<script lang="ts">
    import IconsWorker from "../../../Components/IconsWorker.svelte";
    import ConnectionWorker from "../../../Utils/ConnectionWorker";
    import FixedNotifications from "../../../Components/DuNotifications/FixedNotifications";
    import {onMount} from "svelte";

    let items = [
        {icon: 'facebookF', key: 'facebook', color: 'blue', isSaving: false, value: '', },
        {icon: 'twitterF', key: 'twitter', color: '#62CDF7', isSaving: false, value: '', },
        {icon: 'instagramF', key: 'instagram', color: '#EC61A9', isSaving: false, value: '', },
        {icon: 'snapchatF', key: 'snapchat', color: '#FBE864', isSaving: false, value: '', },
        {icon: 'phoneF', key: 'phone', color: '#5FC650', isSaving: false, value: '', },
    ];

    let isLoadingData = true;

    function sendData(index) {
        items[index].isSaving = true;
        new ConnectionWorker().request(<any>{
            url: '/socialMedia/save',
            postParams: {
                key: items[index].key,
                value: items[index].value,
            },
            onLoad: (respone) => {
                if(respone['status']) {
                    new FixedNotifications().create({
                        title: 'تم الحفظ',
                        icon: items[index].icon,
                        color: 'green',
                        removeAfter: 2000,
                    });
                } else {
                    new FixedNotifications().create({
                        title: 'حدث خطأء غير معروف',
                        subTitle: 'حاول مجددًا',
                        icon: 'error',
                        color: 'red',
                        removeAfter: 2000,
                    });
                }
                items[index].isSaving = false;
            },
            onFail: (code) => {
                new FixedNotifications().create({
                    title: 'حدث خطأء غير معروف',
                    subTitle: 'حاول مجددًا',
                    icon: 'error',
                    color: 'red',
                    removeAfter: 2000,
                });
                items[index].isSaving = false;
            }
        });
    }


    const getData = () => {
        isLoadingData = true;
        new ConnectionWorker().request(<any>{
            url: '/socialMedia/getAll',
            onLoad: (respone) => {
                console.log(respone);
                if(respone['status']) {
                    respone['data'].forEach((cell) => {
                        items.forEach((existCells, index) => {
                            if(cell.key === existCells.key) {
                                items[index].value = cell['value'];
                            }
                        });
                    });
                } else {
                    new FixedNotifications().create({
                        title: 'حدث خطأء غير معروف',
                        subTitle: 'حاول مجددًا',
                        icon: 'error',
                        color: 'red',
                        removeAfter: 2000,
                    });
                }
                isLoadingData = false;
            },
            onFail: (code) => {
                setTimeout(() => {
                    getData();
                }, 3000);
            }
        });
    }

    onMount(() => {
        getData();
    });
</script>

    <div class="SocialMedia">

        <div class="cellsContainer">
            {#each items as cell, index}
                <div class="cell {cell.isSaving || isLoadingData ? 'disabled' : ''}">
                    <div class="icon" style="background: #F09035;">
                        <IconsWorker icon="{cell.icon}" width="30px" color="#fff"/>
                    </div>
                    <div class="infoSide">
                        <input placeholder="قم كتابة الرابط هنا" bind:value={cell.value}/>
                    </div>
                    <button on:click={() => sendData(index)} class="brightnessGlobalHover"><IconsWorker icon="saveF" width="20px" color="#fff"/></button>
                </div>
            {/each}
        </div>

    </div>

<style>
    .SocialMedia{
        display: flex;
        padding: 20px;
        background: #fff;
        border-radius: 12px;
    }

    .cellsContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .cellsContainer .cell{
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        margin-top: 5px;
    }

    .cellsContainer .icon{
        padding: 15px;
        border-radius: 12px;
    }

    .cellsContainer .infoSide{
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-inline-start: 10px;
    }

    .cellsContainer input{
        font-size: 16px;
        border: 0;
        padding: 0;
        margin: 0;
    }

    .cellsContainer button{
        padding: 8px;
        margin: 0 15px;
        background: #F09035;
        border:0;
        border-radius: 100px;
    }

    .disabled{
        opacity: 0.5;
        pointer-events: none;
    }
</style>