<script lang="ts">
  import ConnectionWorker from "../Utils/ConnectionWorker";
  export let data;
  $: isDataReady = data !== undefined;

  $: getThumbImageToShow = () => {
   if(data !== undefined) {
    if (Number(data.thumb)) {
     return ConnectionWorker.rootUrl + '/file/' + data.thumb;
    }
   }
   return "/assets/images/thumb.png";
  }
</script>

 <div class="contentSideBar">
  {#if (isDataReady)}
  <div class="userInfo" itemprop="author" itemscope itemtype="https://schema.org/Person">
   <div class="thumb" style="{'background-image: url(' + getThumbImageToShow() + ');'}"></div>
   <div class="infoSide">
    <div class="name" itemprop="name">{data.name}</div>
    <div class="username">@{data.username}</div>
    {#if (data.bio !== null && data.bio !== '')}
     <div class="bio">{data.bio}</div>
    {/if}
   </div>
  </div>
  {/if}
 </div>


<style>
    .contentSideBar{
      width: 100%;
      background: #fff;
      border-radius: 12px;
      min-height: 200px;
      display: flex;
      flex-direction: column;
    }

    .contentSideBar .userInfo{
      width: available;
      padding: 25px;
      display: flex;
      flex-direction: row;
    }

    .contentSideBar .userInfo .thumb{
     width: 45px;
     height: 45px;
     border-radius: 100px;
     background-color: #eee;
     background-position: center;
     background-size: cover;
    }

    .contentSideBar .userInfo .infoSide{
     flex: 1;
     width: available;
     display: flex;
     flex-direction: column;
     margin-inline-start: 10px;
    }

    .contentSideBar .userInfo .infoSide .name{
     font-size: 15px;
     width: available;
     color: #222;
    }

    .contentSideBar .userInfo .infoSide .username{
     font-size: 13px;
     width: available;
     color: #444;
    }

    .contentSideBar .userInfo .infoSide .bio{
     font-size: 16px;
     width: available;
     color: #666;
     margin-top: 5px;
    }
</style>