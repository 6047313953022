<script lang="ts">
    import {onMount} from "svelte";
    import IconsWorker from "../Components/IconsWorker.svelte";
    import DuOptionMenu, {DuOptionMenuItem} from "../Components/DuOptionsMenu/DuOptionsMenu";
    import DuConfirmDialog from "../Components/DuConfirmDialog/DuConfirmDialog";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import DuAnimation from "../Utils/DuAnimation";

    export let data:Object;
    let isDataReady:boolean = false;
    let isDeleted:boolean = false;


    $: () => {
        if(data !== undefined) {
            isDataReady = true;
        }
    }

    onMount(() => {

    });


    const deleteIt = () => {
        DuAnimation.animate({
            id: 'DashBoardTagFragment_' + data.id,
            duration: 500,
            animation: 'bounceOut',
        });
        setTimeout(() => {
            isDeleted = true;
        }, 2000);
        new ConnectionWorker().request(<any>{
            url: '/hashtag/delete',
            postParams: { id: data.id },
            onLoad: (response) => {
                console.log(response);
                if(response.status) {
                    isDeleted = true;
                } else {

                }
            }
        });
    }

    const openOptionsMenu = (e) => {
        new DuOptionMenu().create({
            event: e,
            options: [
                {title: 'حذف', icon: 'delete', onSelect: () => {
                        new DuConfirmDialog().create({
                            title: 'هل تود حذف هذا الوسم ؟',
                            subTitle: 'لا يمكن التراجع عن هذا الإجراء , سيظل المحتوى المشار إليه بهذا الوسم في الظهور بصورة طبيعية',
                            titleColor: 'red',
                            options: [
                                {title: 'حذف', isBold: true, onSelect: () => {
                                    deleteIt();
                                }, color: 'red'},
                                {title: 'تراجع',}
                            ]
                        });
                    }, color: 'red'},
            ]
        });
    }

</script>


{#if !isDeleted}
    <div class="tag" id="DashBoardTagFragment_{data.id}">
        <div class="id">{data.id}</div>
        <div class="infoSide">
            <div class="title">{data.title}</div>
        </div>
        <button class="optionsButton brightnessGlobalHover" on:click={openOptionsMenu}><IconsWorker icon="xDotMenuF" width="17px" color="#fff"/></button>
    </div>
{/if}

<style>
    .tag{
        width: available;
        height: auto;
        padding: 20px;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        box-shadow: 0 0 5px #eee;
        align-items: center;
    }

    .tag:first-child{
        border-radius: 12px 12px 0 0;
    }

    .tag:last-child{
        border-radius: 0 0 12px 12px;
    }

    .tag .id{
        font-size: 15px;
        width: min-content;
        height: min-content;
        border-radius: 100%;
        color: #999;
        font-weight: bold;
        display: block;
    }

    .tag .infoSide{
        display: flex;
        flex-direction: column;
        width: available;
        flex: 1;
    }

    .tag .infoSide .title{
        font-size: 17px;
        color: #444;
        margin-inline-start: 10px;
    }

    .optionsButton{
        border-radius: 100px;
        background-color: #555;
        box-shadow: 0 0 5px #ccc;
        display: flex;
        flex-direction: column;
        place-items: center;
        place-content: center;
        padding: 7px;
    }
</style>