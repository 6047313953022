<script lang="ts">
    import {link, useLocation, useParams} from 'svelte-navigator';
    import {onMount} from "svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";
    import IconsWorker from "./IconsWorker.svelte";

    let data = [];
    let searchQueryValue = '';
    let isLoadingData = true;
    export let onSearchSubmit: (string) => string;
    export let showSearch:boolean = true;

    let _useLocation = useLocation();
    let _useParams = useParams();

    $: getCurrentPath = () => {
        return $_useLocation.pathname;
    }

    $: getCurrentTagIdIfThere = () => {
       return $_useParams.id || -1;
    }

    const getData = () => {
        isLoadingData = true;
        new ConnectionWorker().request(<any>{
            url: '/hashtag/get?onlyMain=true',
            onLoad: (response) => {
                isLoadingData = false;
                if(response.status) {
                    data = response.data.data;
                }
            },
            onFail: (code) => {
                setTimeout(() => { getData(); }, 2000);
            }
        });
    }

    onMount(() => {
        getData();
        const urlSearchParams = new URLSearchParams($_useLocation.search);
        if(urlSearchParams.get('q')) {
            searchQueryValue = decodeURIComponent(urlSearchParams.get('q'));
        }
    });

    const onSearch = (e) => {
        if(searchQueryValue.replace(/\s/g, '').length) {
            if (onSearchSubmit) {
                onSearchSubmit(searchQueryValue);
            }
        }
        e.preventDefault();
    }

    const clearSearchKeyWord = (e) => {
        searchQueryValue = '';
        if(onSearchSubmit) {
            onSearchSubmit('');
        }
    }
</script>

<div class="mainHorizontalMainTagMenuAndSearch">
    {#if showSearch}
        <div class="searchBar">
            <form class="inputHolder" on:submit={onSearch}>
                <input placeholder="البحث عن مقال" bind:value={searchQueryValue} />
                {#if searchQueryValue.replace(/\s/g, '').length}
                    <div class="clearTextButton" on:click={clearSearchKeyWord}><IconsWorker icon="closeF" color="#000" width="13px"/></div>
                {/if}
                <button style="background: {searchQueryValue.replace(/\s/g, '').length ? '#F09035' : '#000'}"><IconsWorker icon="searchF" color="#fff" width="20px"/></button>
            </form>
        </div>
    {/if}
    <div class="mainHorizontalMainTagMenu">
        <a class="cell {-1 === getCurrentTagIdIfThere() ? 'selectedCell' : ''}" href="/" use:link>الرئيسية</a>
        {#each data as tag}
            <a class="cell {tag.id.toString() === getCurrentTagIdIfThere().toString() ? 'selectedCell' : ''}" href="/tag/{tag.id}/{tag.title}" use:link>{tag.title}</a>
        {/each}
    </div>
</div>


<style>
.mainHorizontalMainTagMenuAndSearch{
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 10px #ccc;
}

.searchBar{
    width: available;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
}

.searchBar .inputHolder{
    width: available;
    flex: 1;
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    border-radius: 100px;
    padding: 5px 0px;
    align-items: center;
}

.searchBar .inputHolder .clearTextButton{
    padding: 5px;
    background: #ccc;
    border-radius: 100px;
    margin: 0 7px;
    cursor: pointer;
}

.searchBar .inputHolder .clearTextButton:hover{ background: #999; }
.searchBar .inputHolder .clearTextButton:active{ background: #ccc; }

.searchBar .inputHolder input{
    width: available;
    flex: 1;
    border: 0;
    padding: 0;
    font-size: 15px;
    margin-inline-start: 20px;
    margin-inline-end: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.searchBar .inputHolder button{
    display: flex;
    flex-direction: row;
    place-items: center;
    place-content: center;
    margin-inline-end: 5px;
    margin-bottom: 0px;
    border-radius: 100px;
    padding: 8px;
    border: 0;
    background: #333;
    cursor: pointer;
}

.searchBar .inputHolder button:hover{ background: #555; }
.searchBar .inputHolder button:active{ background: #000; }

.mainHorizontalMainTagMenu{
    display: flex;
    flex-direction: row;
}

.mainHorizontalMainTagMenu .cell{
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: #111;
    padding: 13px 20px;
    font-size: 17px;
}

.mainHorizontalMainTagMenu .cell:hover{
    border-bottom: 2px solid rgba(240, 144, 53, 0.5);
}

.mainHorizontalMainTagMenu .cell:active{
    border-bottom: 2px solid rgba(240, 144, 53, 0.3);
}

.mainHorizontalMainTagMenu .selectedCell{
    font-weight: bold;
    border-bottom: 2px solid #F09035;
}

@media only screen and (max-width: 600px) {
    .mainHorizontalMainTagMenu{
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .mainHorizontalMainTagMenu .cell{
        display: inline-block;
        font-size: 15px;
    }
}

</style>