<script lang="ts">
    import DuListView from "../../Components/DuListView.svelte";
    import ServiceFragment from "../../Fragments/ServiceFragment.svelte";

    let scrollContainer;
    scrollContainer = document.body;

</script>

<div class="favorites">
    <DuListView class="list" fragmentName="service" getParams="{{onlyInFavorites: true}}" fragment="{ServiceFragment}" scrollContainer={scrollContainer} />
</div>

<style>
.favorites{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.list{
    display: flex;
    flex-direction: column;
}
</style>