<script lang="ts">
    import {onMount} from "svelte";
    import DuButton from "../DuButton.svelte";

    export let value = '';
    export let blockProps = {};
    export let readOnly = false;
    let isEditing = true;
    let htmlValue = '';
    export  let service = ''; // ex : youtubeVideo

    let mainItem;
    let width = 0;
    let height = 0;

    const onChange = (e) => {
        if(getYoutubeId(value)) {
            isEditing = false;
            value = 'https://youtube.com/embed/' + getYoutubeId(value);
        } else {
            isEditing = true;
        }
    }

    const edit = () => {
        isEditing = true;
    }

    onMount(() => {
        width = mainItem.offsetWidth
        height = width / 1.5;
        if(readOnly) {
            onChange(null);
        }
        // Prism.plugins.autoloader.languages_path = '/assets/prism-components';
    });


    const getYoutubeId = (url) => {
        var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        return (url.match(p)) ? RegExp.$1 : false;
    }

</script>

<div class="EmpededItem" bind:this={mainItem}>
    {#if !readOnly}
        <div class="options">
            {#if !isEditing}
                <DuButton text="تعديل" icon="penF" fontSize="{12}" background="#000" onTap="{edit}"/>
            {/if}
        </div>
    {/if}
    {#if isEditing}
        <textarea
                contenteditable="true"
                on:focusout={onChange}
                bind:value={value}
                placeholder="ضع الرابط هنا"
        ></textarea>
    {:else}
        {#if width > 0 && height > 0}
            {#if service === 'youtubeVideo'}
                <iframe width="{width}" height="{height}" src="{value}">
                </iframe>
            {:else}
                [خدمة غير معروفة]
            {/if}
        {/if}
    {/if}
</div>

<style>
    .EmpededItem{
        color: #fff;
        width: available;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .options{
        width: available;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
    }

</style>