<script lang="ts">
    import ContentImage from "../ContentImage.svelte";
    import DuCodeSnipper from "../DuTextEditor/DuCodeSnipper.svelte";
    import {onMount} from "svelte";
    import {link} from "svelte-navigator";
    import EmpededItem from "../DuTextEditor/EmpededItem.svelte";
    import VideoViewer from "./VideoViewer.svelte";
    import ContentAudioDescriptionPlayer from "./ContentAudioDescriptionPlayer.svelte";

    export let data = []; // list of content blocks
    let currentPlayingTagId;

    const decodeMarkDown = (str) => {
        return str.replace(/\((.*)\)\[(.*)\]/g, '<a href="$1" class="contentLink" target="_blank" use:link>$2</a>');
    }
</script>

<ContentAudioDescriptionPlayer data="{data}" bind:currentPlayingTagId="{currentPlayingTagId}"/>

{#each data as section}
    <div class="contentBlockSection {currentPlayingTagId == section.id ? 'playingFocus' : ''}" id="contentBlockSection{section['id']}">
        {#if ['h1', 'h2', 'p', 'q'].includes(section.tag)}
            <svelte:element this={section.tag}>
                {@html decodeMarkDown(section.value)}
            </svelte:element>
        {:else if ['imageFile'].includes(section.tag)}
            <ContentImage serial="{section.attrs.src}"/>
        {:else if ['videoFile'].includes(section.tag)}
            <VideoViewer serial="{section.attrs.src}" />
        {:else if ['youtubeVideo'].includes(section.tag)}
            <EmpededItem bind:value={section.value} readOnly={true} service="{section.tag}"/>
        {:else if ['code'].includes(section.tag)}
            <DuCodeSnipper value="{section.value}" readOnly="{true}"/>
        {:else if ['ul', 'ol'].includes(section.tag)}
            <svelte:element this={section.tag}>
                {#each section.value.trim('').split('\n') as text }
                    <li>{@html decodeMarkDown(text)}</li>
                {/each}
            </svelte:element>
        {/if}
    </div>
{/each}

<style>
    p{
        white-space: break-spaces;
    }

    :global(.contentLink){
        color: #E2954A;
        font-weight: bold;
        text-decoration: underline;
    }

    :global(.contentLink):visited {
        color: #7F5E3C;
        text-decoration: none;
    }

    /* mouse over link */
    :global(.contentLink):hover {
        color: #635E56;
    }

    /* selected link */
    :global(.contentLink):active {
        color: #373532;
    }

    .playingFocus{
        background: rgba(241, 191, 66, 0.5);
        border: 2px solid rgba(241, 191, 66, 1);
        border-style: dotted;
        margin: 10px;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        scroll-margin-top: 100px;
    }
</style>