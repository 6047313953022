<script lang="ts">
    import IconsWorker from "./IconsWorker.svelte";
    import DuButton from "./DuButton.svelte";
    import {onMount} from "svelte";
    import DuInputPhone from "./DuInput/DuInputPhone.svelte";
    import DuInput, {DuInputType} from "./DuInput/DuInput.svelte";
    import ConnectionWorker from "../Utils/ConnectionWorker";

    export let options:Object;
    export let onDone:(any) => any;
    export let onClose:() => void;

    let isInputValid:boolean = true;
    let value;
    let props = {};
    let input;
    let params: {};
    let isSending = false;

    const onSaveButtonTap = () => {
        isSending = true;
        new ConnectionWorker().request(<any>{
            url: '/user/updateData',
            postParams: params,
            onLoad: (response) => {
                isSending = false;
                if(onDone !== undefined) {
                    onDone(response);
                }
                if(response.status) {
                    if(onClose !== undefined) {
                        onClose();
                    }
                }
            }
        });
    }

    onMount(() => {
        value = options['currentValueString'];
        if(options['key'] === 'name') {
            props = { label: 'الاسم', isRequired: true, isRequiredMessage: 'قم بكتابة الاسم رجاء', maxLength: 20, maxLengthMessage: "هذا الاسم طويل بعض الشيء , أقصى عدد لحروف الاسم هو ٢٠ حرف", inputType: DuInputType.text };
        } else if(options['key'] === 'bio') {
            props = { label: 'نص البايو', isRequired: true, isRequiredMessage: 'قم بكتابة وصف قصير عن نفسك', maxLength: 20, maxLengthMessage: "نص البايو طويل جدًا , يمكنك إضافة حتى ٢٠٠ حرف للبايو الخاص بك", inputType: DuInputType.textArea };
        }
    });

    $: {
        console.log('ff');
        if(options['key'] === 'name') {
            params = {name: value};
        }else if(options['key'] === 'bio') {
            params = {bio: value};
        }
    }
</script>

<div class="lightBox">
    <div class="lightBoxBody">
        <div class="lightBoxHeader">
            <div class="title">{options.title}</div>
            <div class="closeButton brightnessGlobalHover" on:click={() => {
                if(onClose !== null) {
                    onClose();
                }
            }}><IconsWorker icon="dismiss" color="red" width="30px"/></div>
        </div>
        <div class="lightBoxContentArea">
            {#if ['name', 'bio'].includes(options.key)}
                <DuInput
                        bind:value="{value}"
                        bind:isValid={isInputValid}
                        bind:this={input}
                        {...props}
                />
            {/if}
        </div>
        <div class="lightBoxFooter">
            <DuButton text="حفظ" icon="checkF" onTap="{onSaveButtonTap}" isDisabled={!isInputValid && isSending} isLoading="{isSending}"/>
        </div>
    </div>
</div>

<style>
.lightBox{
    width: 100%;
    height: 100%;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
}

.lightBox .lightBoxBody{
    width: 400px;
    height: min-content;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 20px #444;
    place-self: center;
    align-self: center;
    justify-self: center;
    margin: auto;
    overflow: hidden;
}

.lightBoxHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: available;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 5px #999;
}

.lightBoxHeader .title{
    flex: 1;
    width: available;
}

.lightBoxContentArea{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    min-height: 100px;
}

.lightBoxFooter{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: available;
    border-top: 1px solid #eee;
    padding: 10px 20px;
    justify-content: end;
}
</style>